import React, { useState, useEffect, useRef } from 'react';
import useImportInfo from './useImportInfo';
import callApi from 'helpers/callapi';
import useMyUniversity from 'hooks/useMyUniversity';
import { message } from 'antd';

const ISUPDATE = true;
const useUpload = (round, _university, index, config = { type: 'import', onUploadDone: () => {} }) => {
  const [university, setUniversity] = useState(_university);
  useEffect(() => setUniversity(_university), [_university]);
  const { fetchUniversityInfo } = useMyUniversity();

  const uploadRef = useRef();
  const info = useImportInfo(round, university, index, config);

  const [importErrors, setImportErrors] = useState([]);

  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState();
  const onUpload = async (done) => {
    if (uploading || !selectedFile) return;
    try {
      const data = new FormData();
      data.append('enrollments', selectedFile);

      setUploading(true);
      const result = await callApi({
        method: 'POST',
        url: info.uploadURL,
        body: data,
        uploadFile: true,
        onUploadProgress: (data) => setUploadProgress(Math.round((100 * data.loaded) / data.total)),
      });

      let universityUploadFiles = {};
      if (!ISUPDATE) {
        universityUploadFiles = {
          condition_file: index < 2 ? null : _university.condition_file,
          program_condition_updated_at: index < 2 ? null : _university.program_condition_updated_at,
          scoring_file: index < 3 ? null : _university.scoring_file,
          program_scoring_updated_at: index < 3 ? null : _university.program_scoring_updated_at,
        };
      }
      fetchUniversityInfo();
      // setUniversity,((_university) => ({
      //   ..._university,
      //   ...universityUploadFiles,

      //   [info.fileType]: result.data[info.fileType],
      //   [info.updatedAtField]: result.data[info.updatedAtField],
      // }));
      setUploadProgress();
      setUploading(false);
      setSelectedFile();
      uploadRef.current.value = '';
      setImportErrors([]);
      config.onUploadDone(result);
      message.success(`อัพโหลด${info.title}สำเร็จ`);
      done();
    } catch (error) {
      const importErrors = _.get(error, 'reason.errors') || [];
      if (importErrors.length) {
        setImportErrors(
          importErrors.reduce((rows, { index, messages }) => {
            return rows.concat(messages.map((message) => ({ index, message })));
          }, [])
        );
        setUploadProgress();
        setUploading(false);
      } else {
        setUploadProgress();
        setUploading(false);
      }
    }
  };

  return {
    ...info,
    currentUniversity: university,
    uploadRef,
    selectedFile,
    setSelectedFile,
    onUpload,
    uploading,
    uploadProgress,
    importErrors,
  };
};

export default useUpload;
