import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';

import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';
import UpdateModal from './UpdateModal';

const SEVERITIES = {
  infected: 'เป็นผู้ติดเชื้อ',
  high: 'เป็นผู้มีความเสี่ยงสูง',
};
const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const schools = useSelector((state) => {
    return state.schools || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);
  const universities = useSelector((state) => state.universities || []);
  const [university_id, setUniverysityId] = useState('');
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [covidSites, setCovidSites] = useState();
  const [students, setStudents] = useState({});
  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedData, setSelectedData] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [filterQuery, setFilterQuery] = useState({
    q: '',

    place: 'all',
  });

  // const [creditCount, setCreditCount] = useState()
  const fetchApplication = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const results = await callApi({
        url: '/students/covid',
        query,
      });

      const studentByIds = _.keyBy(results.students, 'citizen_id');

      setStudents(studentByIds);
      setApplications(_.groupBy(results.data, 'citizen_id') || []);
      setStudentCount(results.count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setApplications([]);
      setLoading(false);
    }
  };

  const fetchCovidSites = async () => {
    try {
      setLoading(true);
      const results = await callApi({
        url: '/students/covid/sites',
      });
      setLoading(false);
      setCovidSites(results);
    } catch (err) {
      console.log(err);
      setCovidSites({});
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCovidSites();
  }, []);

  useEffect(() => {
    if (me) {
      let university = me.university_id || '999';
      if (me.role === 'super') {
        university = 'all';
      }

      setFilterQuery({ ...filterQuery });

      fetchApplication(1, { university: university });
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchApplication(_page);
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchApplication(1);
  };

  if (_.isEmpty(covidSites)) {
    return <div></div>;
  }

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            รายชื่อผู้ขอย้ายสนามสอบ
            <div style={{ float: 'right' }}>
              {/* <Button
                type="primary"
                onClick={() => {
                  setAddModalVisible(true);
                }}
              >
                เพิ่มการรับสมัครใหม่
              </Button> */}
            </div>
          </h1>

          <div className="sec-box">
            <div className="sec-form">
              <div className="col-50">
                <label>ค้นจากรหัสประจำตัวประชาชน</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>

              <div className="col-25">
                <label>
                  สนามสอบ
                  <div>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        setFilterQuery({
                          ...filterQuery,
                          place: value,
                        });
                      }}
                      value={get(filterQuery, 'place')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value="all" label="ทั้งหมด">
                        ทั้งหมด
                      </Option>
                      {_.map(covidSites, (site, i) => {
                        return (
                          <Option key={i} value={site.site_code} label={site.site_name}>
                            {site.site_code}: {site.site_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchApplication(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {_.isEmpty(applications) && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลการย้ายสนามสอบ</th>
                </tr>
              </tbody>
            </table>
          )}
          {!_.isEmpty(applications) && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr>
                    <th>รหัสประจำตัวประชาชน</th>
                    {/* <th>ชื่อหลักสูตร</th> */}
                    <th>ชื่อ - นามสกุล</th>

                    <th>อาการ</th>
                    <th>สถานที่</th>

                    <th>วิชา</th>
                    <th>เบอร์ติดต่อ</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(applications, (_applications, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: application.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });
                    const application = _applications[0];
                    const subjects = _applications.map((app) => app.subject_name).join(', ');

                    const student = students[application.citizen_id] || {};

                    return (
                      <tr key={i}>
                        <td>
                          <Link to={`/students/${student._id}`}>{student.citizen_id}</Link>{' '}
                          {(me.role === 'super' || me.role === 'senior') && (
                            <a
                              onClick={() => {
                                setSelectedData({
                                  applications: _applications,
                                  student: student,
                                });
                                setEditModalVisible(true);
                              }}
                            >
                              <EditOutlined />
                            </a>
                          )}
                        </td>

                        {/* 
                        <td>
                          {program && (
                            <>
                              <div>{program && program.program_name_th}</div>
                              <div>{program && dispalyId(program.major_name_th)}</div>
                              <div>{dispalyId(site.project_name_th)}</div>
                            </>
                          )}
                        </td> */}

                        <td>
                          {student.first_name} {student.last_name}
                        </td>

                        <td>{SEVERITIES[application.severity]}</td>
                        <td>{covidSites[application.selected_covid_place].site_name}</td>
                        <td>{subjects}</td>
                        <td>{student.telephone}</td>

                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={studentCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>

      {editModalVisible && (
        <UpdateModal
          setIsVisible={setEditModalVisible}
          isVisible={editModalVisible}
          data={selectedData}
          covidSites={covidSites}
          onClose={() => {
            setEditModalVisible(false);
            fetchApplication();
          }}
        ></UpdateModal>
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
