import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, map, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';
import ProcessModal from './ProcessModal';

import EXAM_SUBJECTS from 'constants/exam-subjects';

let EXAM_SUBJECT_LABELS = _.map(EXAM_SUBJECTS.labels, (value, key) => ({
  key,
  label: value,
  index: EXAM_SUBJECTS.index[key],
}));
EXAM_SUBJECT_LABELS = _.orderBy(EXAM_SUBJECT_LABELS, 'index');

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

const STATUSES = [
  { label: 'ทั้งหมด', value: 'all' },
  { label: 'ยังไม่อ่าน', value: 'pending' },
  { label: 'อ่านแล้ว', value: 'processed' },
];

moment.locale('th');

const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [selectedApp, setSelectedApp] = useState({});
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [examApplications, setExamApplications] = useState([]);
  const [appCount, setappCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    status: 'pending',
    subject: 'all',
  });

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchapps = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: examApplications } = await callApi({
        url: '/exams/enquiries',
        query,
      });
      setExamApplications(examApplications || []);
      setappCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setExamApplications([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      fetchSubjects();
    }
  }, [me]);
  useEffect(() => {
    fetchapps(1);
  }, []);

  const reportsByDate = [];

  for (let i = 18; i <= 30; i++) {
    if (i === 18) {
      reportsByDate.push({
        date: `${i} เมษายน 2565`,
        title: `รายงานประจำวันที่ ${i} เมษายน 2565`,
        link: 'https://tcas65.s3.ap-southeast-1.amazonaws.com/examples/exam-report-18-04.csv',
      });
    }
  }
  const downloadFile = async (subject) => {
    try {
      setLoading(true);

      const { filePath } = await callApi({
        url: '/exams/reports',
        body: { subjectName: subject.key },
      });
      if (filePath) {
        download(filePath);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const downloadAll = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < EXAM_SUBJECT_LABELS.length; i++) {
        const subject = EXAM_SUBJECT_LABELS[i];
        const { filePath } = await callApi({
          url: '/exams/reports',
          body: { subjectName: subject.key },
        });
        if (filePath) {
          download(filePath);
        }
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>รายงานคำร้อง</h1>
          <>
            <table className="t-table -examApplications">
              <thead>
                <tr>
                  <th>วิชา</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {EXAM_SUBJECT_LABELS.map((subject, i) => {
                  return (
                    <tr key={i}>
                      <td>{subject.label}</td>
                      <td>
                        <a
                          className="btn-main"
                          onClick={() => {
                            downloadFile(subject);
                          }}
                        >
                          <i className="i-download"></i> ดาวน์โหลดข้อมูล
                        </a>
                      </td>
                    </tr>
                  );
                })}
                <tr key={'all'}>
                  <td>ดึงข้อมูลทั้งหมด</td>
                  <td>
                    <a
                      className="btn-main"
                      onClick={() => {
                        downloadAll();
                      }}
                    >
                      <i className="i-download"></i> ดาวน์โหลดข้อมูล
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
      </Spin>
    </div>
  );
};
