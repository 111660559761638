import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';

const STATUSES = [
  { label: 'ทั้งหมด', value: 'all' },
  { label: 'รอดำเนินการ', value: 'pending' },
  { label: 'จ่ายเงินแล้ว', value: 'paid' },
  { label: 'ยกเลิก', value: 'canceled' },
];

const { Panel } = Collapse;

const Exam = ({ student }) => {
  // let { studentId } = useParams();
  const [admissions, setAdmissions] = useState([]);
  const [orders, setOrders] = useState([]);
  const subjects = useSelector((state) => state.subjects || []);

  const fetchApplication = async () => {
    const result = await callApi({
      url: `/students/${student.citizen_id}/admissions`,
    });
    const orderResult = await callApi({
      url: `/students/${student.citizen_id}/orders`,
    });
    // result.forEach((app) => (app.index = EXAM_SUBJECTS.index[app.subject_name]));
    setAdmissions(result);
    setOrders(orderResult);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchApplication();
    }
  }, [student]);

  if (admissions.length === 0 || orders.length === 0) {
    return <div></div>;
  }

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>
      <h2>การสมัครเรียน</h2>
      {admissions.map((admission, i) => {
        const order = _.find(orders, { order_id: admission.order_id }) || {};
        const ic = 'i-' + order.status;
        return (
          <Collapse ghost defaultActiveKey={['1']}>
            <Panel
              header={
                <div>
                  <div style={{ float: 'right' }}>
                    สถานะ <i className={ic}></i>{' '}
                    {_.get(_.find(STATUSES, { value: order.status }), 'label') || order.status}
                  </div>
                  <span>
                    การสมัครครั้งที่ <b>{admission.apply_id}</b> / ยืนยันครั้งที่ <b>{admission.confirm_id}</b> เมื่อ{' '}
                    <b>{moment(admission.created_at).add(543, 'years').format('D MMM YY HH:mm น.')}</b>
                  </span>
                </div>
              }
              key={i + 1}
            >
              <table className="t-table -pticket">
                <thead>
                  <th>อันดับ</th>
                  <th>มหาวิทยาลัย</th>
                  <th>หลักสูตร</th>
                  <th>คะแนนต่ำสุด</th>
                  <th>ผลการคัดเลือก</th>
                </thead>
                <tbody>
                  {admission.program_projects.map((_project, j) => {
                    const project = _project.program_project;
                    return (
                      <tr>
                        <td>
                          <b>{j + 1}</b>
                        </td>
                        <td>
                          <b>
                            {project.program.university_name_th} {project.program.campus_name_th}
                            <br />
                            {project.program.faculty_name_th}
                          </b>
                        </td>
                        <td>
                          <div>สาขา {project.program.field_name_th}</div>
                          {project.program.major_name_th && project.program.major_name_th !== '0' && (
                            <div>วิชาเอก{project.program.major_name_th}</div>
                          )}
                          <div>{project.program.program_name_th}</div>
                          {project.program.project_name_th && project.program.project_name_th !== '0' && (
                            <div>โครงการ{project.program.project_name_th}</div>
                          )}
                          {project.program.program_type_name_th && <div>({project.program.program_type_name_th})</div>}
                        </td>
                      </tr>
                    );
                  })}

                  {/* {fullOrder.admissions.map((app) => {
              return (
                <tr>
                  <td>{EXAM_SUBJECTS.labels[app.subject_name]}</td>
                </tr>
              );
            })} */}
                </tbody>
              </table>
            </Panel>
          </Collapse>
        );
      })}
      <hr></hr>

      <h2>การจ่ายเงิน</h2>
      <>
        <table className="t-table -orders">
          <thead>
            <tr>
              <th>เลขที่</th>
              <th>เลขบัตรประชาชน</th>
              <th>ชื่อ - นามสกุล</th>
              {/* <th>ชนิดรายการ</th> */}
              <th>ราคา</th>
              <th>วิธีจ่ายเงิน</th>
              <th>สถานะ</th>

              <th>เวลาสร้าง</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, i) => {
              const ic = 'i-' + order.status;

              return (
                <tr key={i}>
                  <td>{order.order_id}</td>
                  <td>{order.citizen_id}</td>
                  <td>
                    {student.first_name} {student.last_name}
                  </td>

                  {/* <td>{TICKET_LABELS[order.category] || TICKET_LABELS[order.ticket_type]}</td> */}

                  <td className="text-right">{numeral(order.amount).format('0,0.00')}</td>
                  <td>
                    {order.payment_method === 'cs' && 'เคาท์เตอร์เซอร์วิส'}
                    {order.payment_method === 'qr' && 'ธนาคาร'}
                    {order.payment_method !== 'qr' && order.payment_method !== 'cs' && '-'}
                  </td>
                  <td>{moment(order.created_at).add('543', 'year').format('D MMM HH:mm น.')}</td>
                  <td>
                    <div className="s-flex">
                      <i className={ic}></i> {_.get(_.find(STATUSES, { value: order.status }), 'label') || order.status}
                      {order.status === 'canceled' && (
                        <i>
                          &nbsp;
                          <small>
                            {order.canceled_at && moment(order.canceled_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                            {order.canceled_by && `โดย ${_.get(order, 'canceled_by.name')}`}
                          </small>
                        </i>
                      )}
                      {order.status === 'paid' && (
                        <i>
                          &nbsp;
                          <small>
                            {order.paid_at && moment(order.paid_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                            {order.paid_by && `โดย ${_.get(order, 'paid_by.name')}`}
                          </small>
                        </i>
                      )}
                    </div>
                  </td>

                  {/* <td> */}
                  {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                  {/* </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    </div>
  );
};
export default Exam;
