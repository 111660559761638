import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components

import { Button, Table, Pagination, Modal, Row, Col, Spin, Select, Form, message, Input, Radio } from 'antd';

const Option = Select.Option;

moment.locale('th');

export default ({ onClose, setIsVisible, isVisible, enrollmentOffer }) => {
  const [loading, setLoading] = useState(false);
  const [citizenId, setCitizen] = useState(enrollmentOffer.citizen_id);

  const updaetEnrollmentOffer = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        citizen_id: citizenId,
      };

      await callApi({
        url: `/enrollment-offers/${enrollmentOffer._id}/citizen-id`,
        method: 'put',
        body: requestBody,
      });
      setLoading(false);
      if (onClose) {
        onClose();
      }
      message.success('แก้ไขข้อมูลการรับสมัครสำเร็จ');
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={`แก้ไขผลการรับสมัคร`}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        width={800}
        footer={[
          <Button key="back" onClick={() => setIsVisible(false)}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => updaetEnrollmentOffer()}>
            ยืนยัน
          </Button>,
        ]}
      >
        <Spin spinning={loading} delay={0}>
          <div className="t-main">
            <div>
              <div className="sec-form">
                <div className="col-25">สถานะการรับสมัคร (applicant_status)</div>
                <div className="col-75">
                  <input onChange={(e) => setCitizen(e.target.value)} value={citizenId}></input>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
