import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, orderBy, chunk } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  Checkbox,
  message,
  Input,
  Tooltip,
  Radio,
} from 'antd';

const Option = Select.Option;

moment.locale('th');

export default ({ programs, setIsVisible, isVisible, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [changePassword, setChangePassword] = useState(false);

  const me = useSelector((state) => state.user);
  const universities = useSelector((state) => {
    return state.universities || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);

  const [currentPage, setCurrentPage] = useState(1);

  const currentCourses = get(courses, get(me, 'university_id'));

  const allPrograms = chunk(programs, 20);
  const displayPrograms =
    allPrograms.length > 0 ? allPrograms[currentPage - 1] : [];

  const importProgram = async () => {
    const postBody = programs.reduce((result, program) => {
      if (program.valid) {
        result.push(program);
      }
      return result;
    }, []);

    setLoading(true);

    const _result = await callApi({
      url: `/programs`,
      method: 'post',
      body: { programs: postBody },
    });
    setLoading(false);
    onClose(_result);
    setIsVisible(false);
  };

  return (
    <Modal
      title={`เงื่อนไขการรับสมัครที่จะนำเข้าระบบ`}
      visible={isVisible}
      width={1000}
      // okButtonProps={{ loading: loading }}

      onCancel={() => setIsVisible(false)}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => importProgram()}
        >
          อัปโหลด
        </Button>,
      ]}
    >
      <table>
        <thead>
          <tr>
            <th>รหัสหลักสูตร</th>
            <th>ชื่อหลักสูตร</th>
            <th>สาขา</th>
            <th>Project Id</th>
            <th>เงื่อนไขการคำนวณคะแนน</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {displayPrograms.map((program, i) => {
            if (!program.program_id) {
              return <tr />;
            }

            const currentCourse = find(currentCourses, {
              program_id: program.program_id,
            });

            return (
              <tr key={i}>
                <td>{program.program_id}</td>
                <td>{currentCourse && currentCourse.program_name_th}</td>
                <td>{program.major_id}</td>
                <td>{program.project_id}</td>
                <td>
                  {program.cal_type === 'sum' && <span>ผลรวมของคะแนน</span>}
                  {_.map(program.score, (score, key) => {
                    const subject = find(subjects, { key });

                    if (program.cal_type === 'sum') {
                      return (
                        <span key={key}>{subject && subject.label}, </span>
                      );
                    }
                    if (score === 0) {
                      return <span></span>;
                    }

                    return (
                      <div key={key}>
                        {subject && subject.label} {score}%
                      </div>
                    );
                  })}
                </td>
                <td>
                  {program.valid ? (
                    <div>
                      <CheckCircleOutlined
                        style={{ fontSize: 24, color: '#52c41a' }}
                        twoToneColor="#52c41a"
                      />
                    </div>
                  ) : (
                    <div>
                      <Tooltip placement="top" title={program.errorMessage}>
                        <CloseCircleOutlined
                          style={{ fontSize: 24, color: '#f5222d' }}
                          twoToneColor="#f5222d"
                        />
                      </Tooltip>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        current={currentPage}
        onChange={(_page) => {
          setCurrentPage(_page);
          document.getElementsByClassName('ant-modal-wrap')[0].scrollTo(0, 50);
        }}
        defaultPageSize={20}
        total={programs.length}
        showSizeChanger={false}
      />
    </Modal>
  );
};
