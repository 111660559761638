module.exports = {
  1002: {
    site_code: '1002',
    site_name: 'อำมาตย์พานิชนุกูล',
    province: 'กระบี่',
    center_code: '010',
    seats: 1440,
    special_seats: 0,
  },
  1003: {
    site_code: '1003',
    site_name: 'ตรังคริสเตียนศึกษา',
    province: 'ตรัง',
    center_code: '010',
    seats: 1200,
    special_seats: 0,
  },
  1004: {
    site_code: '1004',
    site_name: 'วิเชียรมาตุ',
    province: 'ตรัง',
    center_code: '010',
    seats: 1890,
    special_seats: 0,
  },
  1005: {
    site_code: '1005',
    site_name: 'สภาราชินี จังหวัดตรัง',
    province: 'ตรัง',
    center_code: '010',
    seats: 1440,
    special_seats: 0,
  },
  1006: {
    site_code: '1006',
    site_name: 'เดชะปัตตนยานุกูล',
    province: 'ปัตตานี',
    center_code: '010',
    seats: 1920,
    special_seats: 0,
  },
  1007: {
    site_code: '1007',
    site_name: 'เบญจมราชูทิศ จังหวัดปัตตานี',
    province: 'ปัตตานี',
    center_code: '010',
    seats: 1500,
    special_seats: 0,
  },
  1008: {
    site_code: '1008',
    site_name: 'สาธิตมหาวิทยาลัยสงขลานครินทร์',
    province: 'ปัตตานี',
    center_code: '010',
    seats: 810,
    special_seats: 0,
  },
  1009: {
    site_code: '1009',
    site_name: 'ดีบุกพังงาวิทยายน',
    province: 'พังงา',
    center_code: '010',
    seats: 990,
    special_seats: 0,
  },
  1010: {
    site_code: '1010',
    site_name: 'พัทลุง',
    province: 'พัทลุง',
    center_code: '010',
    seats: 1800,
    special_seats: 0,
  },
  1011: {
    site_code: '1011',
    site_name: 'สตรีพัทลุง',
    province: 'พัทลุง',
    center_code: '010',
    seats: 1410,
    special_seats: 0,
  },
  1012: {
    site_code: '1012',
    site_name: 'ภูเก็ตวิทยาลัย',
    province: 'ภูเก็ต',
    center_code: '010',
    seats: 2550,
    special_seats: 0,
  },
  1013: {
    site_code: '1013',
    site_name: 'สตรีภูเก็ต',
    province: 'ภูเก็ต',
    center_code: '010',
    seats: 1200,
    special_seats: 0,
  },
  1014: {
    site_code: '1014',
    site_name: 'มหาวชิราวุธ จังหวัดสงขลา',
    province: 'สงขลา',
    center_code: '010',
    seats: 1380,
    special_seats: 0,
  },
  1015: {
    site_code: '1015',
    site_name: 'วรนารีเฉลิม จังหวัดสงขลา',
    province: 'สงขลา',
    center_code: '010',
    seats: 1530,
    special_seats: 0,
  },
  1016: {
    site_code: '1016',
    site_name: 'รร.เทศบาล 1 เอ็งเสียงสามัคคี',
    province: 'สงขลา',
    center_code: '010',
    seats: 1200,
    special_seats: 0,
  },
  1017: {
    site_code: '1017',
    site_name: 'ศรีนครมูลนิธิ',
    province: 'สงขลา',
    center_code: '010',
    seats: 990,
    special_seats: 0,
  },
  1018: {
    site_code: '1018',
    site_name: 'หาดใหญ่วิทยาลัย',
    province: 'สงขลา',
    center_code: '010',
    seats: 1800,
    special_seats: 0,
  },
  1019: {
    site_code: '1019',
    site_name: 'หาดใหญ่วิทยาลัย ๒',
    province: 'สงขลา',
    center_code: '010',
    seats: 1080,
    special_seats: 0,
  },
  1020: {
    site_code: '1020',
    site_name: 'หาดใหญ่วิทยาลัยสมบูรณ์กุลกันยา',
    province: 'สงขลา',
    center_code: '010',
    seats: 900,
    special_seats: 0,
  },
  1021: {
    site_code: '1021',
    site_name: 'พิมานพิทยาสรรค์',
    province: 'สตูล',
    center_code: '010',
    seats: 1350,
    special_seats: 0,
  },
  1022: {
    site_code: '1022',
    site_name: 'สตูลวิทยา',
    province: 'สตูล',
    center_code: '010',
    seats: 990,
    special_seats: 0,
  },
  1051: {
    site_code: '1051',
    site_name: 'เมืองกระบี่',
    province: 'กระบี่',
    center_code: '010',
    seats: 1170,
    special_seats: 0,
  },
  1052: {
    site_code: '1052',
    site_name: 'อำมาตย์พานิชนุกูล',
    province: 'กระบี่',
    center_code: '010',
    seats: 1440,
    special_seats: 0,
  },
  1053: {
    site_code: '1053',
    site_name: 'วิเชียรมาตุ',
    province: 'ตรัง',
    center_code: '010',
    seats: 1890,
    special_seats: 0,
  },
  1054: {
    site_code: '1054',
    site_name: 'สภาราชินี จังหวัดตรัง',
    province: 'ตรัง',
    center_code: '010',
    seats: 1440,
    special_seats: 0,
  },
  1055: {
    site_code: '1055',
    site_name: 'เดชะปัตตนยานุกูล',
    province: 'ปัตตานี',
    center_code: '010',
    seats: 1920,
    special_seats: 0,
  },
  1056: {
    site_code: '1056',
    site_name: 'เบญจมราชูทิศ จังหวัดปัตตานี',
    province: 'ปัตตานี',
    center_code: '010',
    seats: 1500,
    special_seats: 0,
  },
  1057: {
    site_code: '1057',
    site_name: 'สาธิตมหาวิทยาลัยสงขลานครินทร์',
    province: 'ปัตตานี',
    center_code: '010',
    seats: 810,
    special_seats: 0,
  },
  1058: {
    site_code: '1058',
    site_name: 'ดีบุกพังงาวิทยายน',
    province: 'พังงา',
    center_code: '010',
    seats: 990,
    special_seats: 0,
  },
  1059: {
    site_code: '1059',
    site_name: 'พัทลุง',
    province: 'พัทลุง',
    center_code: '010',
    seats: 1800,
    special_seats: 0,
  },
  1060: {
    site_code: '1060',
    site_name: 'สตรีพัทลุง',
    province: 'พัทลุง',
    center_code: '010',
    seats: 1410,
    special_seats: 0,
  },
  1061: {
    site_code: '1061',
    site_name: 'ภูเก็ตวิทยาลัย',
    province: 'ภูเก็ต',
    center_code: '010',
    seats: 2550,
    special_seats: 0,
  },
  1062: {
    site_code: '1062',
    site_name: 'มหาวชิราวุธ จังหวัดสงขลา',
    province: 'สงขลา',
    center_code: '010',
    seats: 1380,
    special_seats: 0,
  },
  1063: {
    site_code: '1063',
    site_name: 'วรนารีเฉลิม จังหวัดสงขลา',
    province: 'สงขลา',
    center_code: '010',
    seats: 1530,
    special_seats: 0,
  },
  1064: {
    site_code: '1064',
    site_name: 'รร.เทศบาล 1 เอ็งเสียงสามัคคี',
    province: 'สงขลา',
    center_code: '010',
    seats: 1200,
    special_seats: 0,
  },
  1065: {
    site_code: '1065',
    site_name: 'ศรีนครมูลนิธิ',
    province: 'สงขลา',
    center_code: '010',
    seats: 990,
    special_seats: 0,
  },
  1066: {
    site_code: '1066',
    site_name: 'หาดใหญ่วิทยาลัย',
    province: 'สงขลา',
    center_code: '010',
    seats: 1800,
    special_seats: 0,
  },
  1067: {
    site_code: '1067',
    site_name: 'หาดใหญ่วิทยาลัย ๒',
    province: 'สงขลา',
    center_code: '010',
    seats: 1080,
    special_seats: 0,
  },
  1068: {
    site_code: '1068',
    site_name: 'หาดใหญ่วิทยาลัยสมบูรณ์กุลกันยา',
    province: 'สงขลา',
    center_code: '010',
    seats: 900,
    special_seats: 0,
  },
  1069: {
    site_code: '1069',
    site_name: 'พิมานพิทยาสรรค์',
    province: 'สตูล',
    center_code: '010',
    seats: 1350,
    special_seats: 0,
  },
  1401: {
    site_code: '1401',
    site_name: 'มัธยมวัดสิงห์',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1590,
    special_seats: 0,
  },
  1402: {
    site_code: '1402',
    site_name: 'วัดราชโอรส',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1320,
    special_seats: 0,
  },
  1403: {
    site_code: '1403',
    site_name: 'โพธิสารพิทยากร',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1860,
    special_seats: 0,
  },
  1404: {
    site_code: '1404',
    site_name: 'ศึกษานารี',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1260,
    special_seats: 0,
  },
  1405: {
    site_code: '1405',
    site_name: 'ชิโนรสวิทยาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 965,
    special_seats: 0,
  },
  1406: {
    site_code: '1406',
    site_name: 'รัตนโกสินทร์สมโภชบางขุนเทียน',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1590,
    special_seats: 0,
  },
  1407: {
    site_code: '1407',
    site_name: 'ปัญญาวรคุณ',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 900,
    special_seats: 0,
  },
  1408: {
    site_code: '1408',
    site_name: 'วัดนวลนรดิศ',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 900,
    special_seats: 0,
  },
  1409: {
    site_code: '1409',
    site_name: 'บางปะกอกวิทยาคม',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1050,
    special_seats: 0,
  },
  1410: {
    site_code: '1410',
    site_name: 'วิทยาลัยพณิชยการเชตุพน',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 750,
    special_seats: 0,
  },
  1411: {
    site_code: '1411',
    site_name: 'เทพศิรินทร์สมุทรปราการ',
    province: 'สมุทรปราการ',
    center_code: '014',
    seats: 1500,
    special_seats: 0,
  },
  1412: {
    site_code: '1412',
    site_name: 'สตรีสมุทรปราการ',
    province: 'สมุทรปราการ',
    center_code: '014',
    seats: 1800,
    special_seats: 0,
  },
  1413: {
    site_code: '1413',
    site_name: 'สมุทรปราการ',
    province: 'สมุทรปราการ',
    center_code: '014',
    seats: 1680,
    special_seats: 0,
  },
  1451: {
    site_code: '1451',
    site_name: 'มัธยมวัดสิงห์',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1590,
    special_seats: 0,
  },
  1452: {
    site_code: '1452',
    site_name: 'วัดราชโอรส',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1320,
    special_seats: 0,
  },
  1453: {
    site_code: '1453',
    site_name: 'โพธิสารพิทยากร',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1860,
    special_seats: 0,
  },
  1454: {
    site_code: '1454',
    site_name: 'ศึกษานารี',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1260,
    special_seats: 0,
  },
  1455: {
    site_code: '1455',
    site_name: 'ชิโนรสวิทยาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 965,
    special_seats: 0,
  },
  1456: {
    site_code: '1456',
    site_name: 'รัตนโกสินทร์สมโภชบางขุนเทียน',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1590,
    special_seats: 0,
  },
  1457: {
    site_code: '1457',
    site_name: 'ปัญญาวรคุณ',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 900,
    special_seats: 0,
  },
  1458: {
    site_code: '1458',
    site_name: 'วัดนวลนรดิศ',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 900,
    special_seats: 0,
  },
  1459: {
    site_code: '1459',
    site_name: 'บางปะกอกวิทยาคม',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 1050,
    special_seats: 0,
  },
  1460: {
    site_code: '1460',
    site_name: 'วิทยาลัยพณิชยการเชตุพน',
    province: 'กรุงเทพมหานคร',
    center_code: '014',
    seats: 750,
    special_seats: 0,
  },
  1461: {
    site_code: '1461',
    site_name: 'เทพศิรินทร์สมุทรปราการ',
    province: 'สมุทรปราการ',
    center_code: '014',
    seats: 1500,
    special_seats: 0,
  },
  1462: {
    site_code: '1462',
    site_name: 'สตรีสมุทรปราการ',
    province: 'สมุทรปราการ',
    center_code: '014',
    seats: 1800,
    special_seats: 0,
  },
  1463: {
    site_code: '1463',
    site_name: 'สมุทรปราการ',
    province: 'สมุทรปราการ',
    center_code: '014',
    seats: 1680,
    special_seats: 0,
  },
  1601: {
    site_code: '1601',
    site_name: 'บางกะปิ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1260,
    special_seats: 0,
  },
  1602: {
    site_code: '1602',
    site_name: 'มัธยมวัดบึงทองหลาง',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 900,
    special_seats: 0,
  },
  1603: {
    site_code: '1603',
    site_name: 'บดินทรเดชา (สิงห์ สิงหเสนี) ๒',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1350,
    special_seats: 0,
  },
  1604: {
    site_code: '1604',
    site_name: 'เศรษฐบุตรบำเพ็ญ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 2190,
    special_seats: 0,
  },
  1605: {
    site_code: '1605',
    site_name: 'สตรีเศรษฐบุตรบำเพ็ญ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1080,
    special_seats: 0,
  },
  1606: {
    site_code: '1606',
    site_name: 'เตรียมอุดมศึกษาพัฒนาการ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 2070,
    special_seats: 0,
  },
  1607: {
    site_code: '1607',
    site_name: 'เตรียมอุดมศึกษาน้อมเกล้า',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1140,
    special_seats: 0,
  },
  1608: {
    site_code: '1608',
    site_name: 'มัธยมวัดหนองจอก',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1290,
    special_seats: 0,
  },
  1651: {
    site_code: '1651',
    site_name: 'บางกะปิ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1260,
    special_seats: 0,
  },
  1652: {
    site_code: '1652',
    site_name: 'บดินทรเดชา (สิงห์ สิงหเสนี) ๒',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1350,
    special_seats: 0,
  },
  1653: {
    site_code: '1653',
    site_name: 'เศรษฐบุตรบำเพ็ญ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 2190,
    special_seats: 0,
  },
  1654: {
    site_code: '1654',
    site_name: 'สตรีเศรษฐบุตรบำเพ็ญ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 1080,
    special_seats: 0,
  },
  1655: {
    site_code: '1655',
    site_name: 'เตรียมอุดมศึกษาพัฒนาการ',
    province: 'กรุงเทพมหานคร',
    center_code: '016',
    seats: 2070,
    special_seats: 0,
  },
  1701: {
    site_code: '1701',
    site_name: 'ชัยภูมิภักดีชุมพล',
    province: 'ชัยภูมิ',
    center_code: '017',
    seats: 2010,
    special_seats: 0,
  },
  1702: {
    site_code: '1702',
    site_name: 'สตรีชัยภูมิ',
    province: 'ชัยภูมิ',
    center_code: '017',
    seats: 1710,
    special_seats: 0,
  },
  1703: {
    site_code: '1703',
    site_name: 'บุญวัฒนา',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 1950,
    special_seats: 0,
  },
  1704: {
    site_code: '1704',
    site_name: 'ราชสีมาวิทยาลัย',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 1800,
    special_seats: 0,
  },
  1705: {
    site_code: '1705',
    site_name: 'สุรธรรมพิทักษ์',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 1260,
    special_seats: 0,
  },
  1706: {
    site_code: '1706',
    site_name: 'สุรนารีวิทยา',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 2070,
    special_seats: 0,
  },
  1707: {
    site_code: '1707',
    site_name: 'อุบลรัตนราชกัญญาราชวิทยาลัย นครราชสีมา',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 930,
    special_seats: 0,
  },
  1708: {
    site_code: '1708',
    site_name: 'บัวหลวงวิทยาคม',
    province: 'บุรีรัมย์',
    center_code: '017',
    seats: 750,
    special_seats: 0,
  },
  1709: {
    site_code: '1709',
    site_name: 'บุรีรัมย์พิทยาคม',
    province: 'บุรีรัมย์',
    center_code: '017',
    seats: 1980,
    special_seats: 0,
  },
  1710: {
    site_code: '1710',
    site_name: 'ภัทรบพิตร',
    province: 'บุรีรัมย์',
    center_code: '017',
    seats: 810,
    special_seats: 0,
  },
  1711: {
    site_code: '1711',
    site_name: 'สิรินธร',
    province: 'สุรินทร์',
    center_code: '017',
    seats: 2340,
    special_seats: 0,
  },
  1712: {
    site_code: '1712',
    site_name: 'สุรวิทยาคาร',
    province: 'สุรินทร์',
    center_code: '017',
    seats: 1320,
    special_seats: 0,
  },
  1751: {
    site_code: '1751',
    site_name: 'ชัยภูมิภักดีชุมพล',
    province: 'ชัยภูมิ',
    center_code: '017',
    seats: 2010,
    special_seats: 0,
  },
  1752: {
    site_code: '1752',
    site_name: 'บุญวัฒนา',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 1950,
    special_seats: 0,
  },
  1753: {
    site_code: '1753',
    site_name: 'ราชสีมาวิทยาลัย',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 1650,
    special_seats: 0,
  },
  1754: {
    site_code: '1754',
    site_name: 'สุรนารีวิทยา',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 2070,
    special_seats: 0,
  },
  1755: {
    site_code: '1755',
    site_name: 'อุบลรัตนราชกัญญาราชวิทยาลัย นครราชสีมา',
    province: 'นครราชสีมา',
    center_code: '017',
    seats: 930,
    special_seats: 0,
  },
  1756: {
    site_code: '1756',
    site_name: 'บุรีรัมย์พิทยาคม',
    province: 'บุรีรัมย์',
    center_code: '017',
    seats: 1980,
    special_seats: 0,
  },
  1757: {
    site_code: '1757',
    site_name: 'สิรินธร',
    province: 'สุรินทร์',
    center_code: '017',
    seats: 1650,
    special_seats: 0,
  },
  1758: {
    site_code: '1758',
    site_name: 'สุรวิทยาคาร',
    province: 'สุรินทร์',
    center_code: '017',
    seats: 1320,
    special_seats: 0,
  },
  1801: {
    site_code: '1801',
    site_name: 'ยโสธรพิทยาคม',
    province: 'ยโสธร',
    center_code: '018',
    seats: 1770,
    special_seats: 0,
  },
  1802: {
    site_code: '1802',
    site_name: 'กันทรลักษ์วิทยา',
    province: 'ศรีสะเกษ',
    center_code: '018',
    seats: 1200,
    special_seats: 0,
  },
  1803: {
    site_code: '1803',
    site_name: 'ขุขันธ์',
    province: 'ศรีสะเกษ',
    center_code: '018',
    seats: 1350,
    special_seats: 0,
  },
  1804: {
    site_code: '1804',
    site_name: 'ศรีสะเกษวิทยาลัย',
    province: 'ศรีสะเกษ',
    center_code: '018',
    seats: 1320,
    special_seats: 0,
  },
  1805: {
    site_code: '1805',
    site_name: 'สตรีสิริเกศ',
    province: 'ศรีสะเกษ',
    center_code: '018',
    seats: 1715,
    special_seats: 0,
  },
  1806: {
    site_code: '1806',
    site_name: 'อำนาจเจริญ',
    province: 'อำนาจเจริญ',
    center_code: '018',
    seats: 1770,
    special_seats: 0,
  },
  1807: {
    site_code: '1807',
    site_name: 'เดชอุดม',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1470,
    special_seats: 0,
  },
  1808: {
    site_code: '1808',
    site_name: 'มัธยมตระการพืชผล',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1140,
    special_seats: 0,
  },
  1809: {
    site_code: '1809',
    site_name: 'พิบูลมังสาหาร',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1110,
    special_seats: 0,
  },
  1810: {
    site_code: '1810',
    site_name: 'นารีนุกูล',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1380,
    special_seats: 0,
  },
  1811: {
    site_code: '1811',
    site_name: 'เตรียมอุดมศึกษาพัฒนาการ อุบลราชธานี',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 900,
    special_seats: 0,
  },
  1812: {
    site_code: '1812',
    site_name: 'ลือคำหาญวารินชำราบ',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1380,
    special_seats: 0,
  },
  1851: {
    site_code: '1851',
    site_name: 'ยโสธรพิทยาคม',
    province: 'ยโสธร',
    center_code: '018',
    seats: 1770,
    special_seats: 0,
  },
  1852: {
    site_code: '1852',
    site_name: 'ศรีสะเกษวิทยาลัย',
    province: 'ศรีสะเกษ',
    center_code: '018',
    seats: 1320,
    special_seats: 0,
  },
  1853: {
    site_code: '1853',
    site_name: 'สตรีสิริเกศ',
    province: 'ศรีสะเกษ',
    center_code: '018',
    seats: 1715,
    special_seats: 0,
  },
  1854: {
    site_code: '1854',
    site_name: 'อำนาจเจริญ',
    province: 'อำนาจเจริญ',
    center_code: '018',
    seats: 1770,
    special_seats: 0,
  },
  1855: {
    site_code: '1855',
    site_name: 'เตรียมอุดมศึกษาพัฒนาการ อุบลราชธานี',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 900,
    special_seats: 0,
  },
  1856: {
    site_code: '1856',
    site_name: 'นารีนุกูล',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1440,
    special_seats: 0,
  },
  1857: {
    site_code: '1857',
    site_name: 'ลือคำหาญวารินชำราบ',
    province: 'อุบลราชธานี',
    center_code: '018',
    seats: 1380,
    special_seats: 0,
  },
  1901: {
    site_code: '1901',
    site_name: 'เบญจมราชูทิศ จังหวัดจันทบุรี',
    province: 'จันทบุรี',
    center_code: '019',
    seats: 1050,
    special_seats: 0,
  },
  1902: {
    site_code: '1902',
    site_name: 'ศรียานุสรณ์',
    province: 'จันทบุรี',
    center_code: '019',
    seats: 1050,
    special_seats: 0,
  },
  1903: {
    site_code: '1903',
    site_name: 'ดัดดรุณี',
    province: 'ฉะเชิงเทรา',
    center_code: '019',
    seats: 960,
    special_seats: 0,
  },
  1904: {
    site_code: '1904',
    site_name: 'เบญจมราชรังสฤษฎิ์',
    province: 'ฉะเชิงเทรา',
    center_code: '019',
    seats: 1500,
    special_seats: 0,
  },
  1905: {
    site_code: '1905',
    site_name: 'โพธิสัมพันธ์พิทยาคาร',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1320,
    special_seats: 0,
  },
  1906: {
    site_code: '1906',
    site_name: 'พนัสพิทยาคาร',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1907: {
    site_code: '1907',
    site_name: 'ชลกันยานุกูล',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1500,
    special_seats: 0,
  },
  1908: {
    site_code: '1908',
    site_name: 'ชลราษฎรอำรุง',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1500,
    special_seats: 0,
  },
  1909: {
    site_code: '1909',
    site_name: 'สาธิต พิบูลบำเพ็ญ มหาวิทยาลัยบูรพา',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 870,
    special_seats: 0,
  },
  1910: {
    site_code: '1910',
    site_name: 'ศรีราชา',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1140,
    special_seats: 0,
  },
  1911: {
    site_code: '1911',
    site_name: 'ตราษตระการคุณ',
    province: 'ตราด',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1912: {
    site_code: '1912',
    site_name: 'กบินทร์วิทยา',
    province: 'ปราจีนบุรี',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1913: {
    site_code: '1913',
    site_name: 'ปราจิณราษฎรอำรุง',
    province: 'ปราจีนบุรี',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1914: {
    site_code: '1914',
    site_name: 'ปราจีนกัลยาณี',
    province: 'ปราจีนบุรี',
    center_code: '019',
    seats: 810,
    special_seats: 0,
  },
  1915: {
    site_code: '1915',
    site_name: 'แกลง วิทยสถาวร',
    province: 'ระยอง',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1916: {
    site_code: '1916',
    site_name: 'ระยองวิทยาคม',
    province: 'ระยอง',
    center_code: '019',
    seats: 1020,
    special_seats: 0,
  },
  1917: {
    site_code: '1917',
    site_name: 'ระยองวิทยาคมปากน้ำ',
    province: 'ระยอง',
    center_code: '019',
    seats: 870,
    special_seats: 0,
  },
  1918: {
    site_code: '1918',
    site_name: 'วัดป่าประดู่',
    province: 'ระยอง',
    center_code: '019',
    seats: 1020,
    special_seats: 0,
  },
  1919: {
    site_code: '1919',
    site_name: 'สระแก้ว',
    province: 'สระแก้ว',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1920: {
    site_code: '1920',
    site_name: 'อรัญประเทศ',
    province: 'สระแก้ว',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1951: {
    site_code: '1951',
    site_name: 'เบญจมราชูทิศ จังหวัดจันทบุรี',
    province: 'จันทบุรี',
    center_code: '019',
    seats: 1050,
    special_seats: 0,
  },
  1952: {
    site_code: '1952',
    site_name: 'เบญจมราชรังสฤษฎิ์',
    province: 'ฉะเชิงเทรา',
    center_code: '019',
    seats: 1650,
    special_seats: 0,
  },
  1953: {
    site_code: '1953',
    site_name: 'โพธิสัมพันธ์พิทยาคาร',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1200,
    special_seats: 0,
  },
  1954: {
    site_code: '1954',
    site_name: 'ชลกันยานุกูล',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1050,
    special_seats: 0,
  },
  1955: {
    site_code: '1955',
    site_name: 'ชลราษฎรอำรุง',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1050,
    special_seats: 0,
  },
  1956: {
    site_code: '1956',
    site_name: 'สาธิต พิบูลบำเพ็ญ มหาวิทยาลัยบูรพา',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 870,
    special_seats: 0,
  },
  1957: {
    site_code: '1957',
    site_name: 'ศรีราชา',
    province: 'ชลบุรี',
    center_code: '019',
    seats: 1050,
    special_seats: 0,
  },
  1958: {
    site_code: '1958',
    site_name: 'ตราษตระการคุณ',
    province: 'ตราด',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  1959: {
    site_code: '1959',
    site_name: 'ปราจิณราษฎรอำรุง',
    province: 'ปราจีนบุรี',
    center_code: '019',
    seats: 1140,
    special_seats: 0,
  },
  1960: {
    site_code: '1960',
    site_name: 'ระยองวิทยาคม',
    province: 'ระยอง',
    center_code: '019',
    seats: 1020,
    special_seats: 0,
  },
  1961: {
    site_code: '1961',
    site_name: 'วัดป่าประดู่',
    province: 'ระยอง',
    center_code: '019',
    seats: 1020,
    special_seats: 0,
  },
  1962: {
    site_code: '1962',
    site_name: 'อรัญประเทศ',
    province: 'สระแก้ว',
    center_code: '019',
    seats: 900,
    special_seats: 0,
  },
  2001: {
    site_code: '2001',
    site_name: 'กำแพงเพชรพิทยาคม',
    province: 'กำแพงเพชร',
    center_code: '020',
    seats: 1920,
    special_seats: 0,
  },
  2002: {
    site_code: '2002',
    site_name: 'ชัยนาทพิทยาคม',
    province: 'ชัยนาท',
    center_code: '020',
    seats: 1740,
    special_seats: 0,
  },
  2003: {
    site_code: '2003',
    site_name: 'ตากพิทยาคม',
    province: 'ตาก',
    center_code: '020',
    seats: 1590,
    special_seats: 0,
  },
  2004: {
    site_code: '2004',
    site_name: 'สรรพวิทยาคม',
    province: 'ตาก',
    center_code: '020',
    seats: 1560,
    special_seats: 0,
  },
  2005: {
    site_code: '2005',
    site_name: 'ตาคลีประชาสรรค์',
    province: 'นครสวรรค์',
    center_code: '020',
    seats: 660,
    special_seats: 0,
  },
  2006: {
    site_code: '2006',
    site_name: 'นครสวรรค์',
    province: 'นครสวรรค์',
    center_code: '020',
    seats: 2100,
    special_seats: 0,
  },
  2007: {
    site_code: '2007',
    site_name: 'นวมินทราชูทิศ มัชฌิม',
    province: 'นครสวรรค์',
    center_code: '020',
    seats: 1410,
    special_seats: 0,
  },
  2008: {
    site_code: '2008',
    site_name: 'ตะพานหิน',
    province: 'พิจิตร',
    center_code: '020',
    seats: 1530,
    special_seats: 0,
  },
  2009: {
    site_code: '2009',
    site_name: 'พิจิตรพิทยาคม',
    province: 'พิจิตร',
    center_code: '020',
    seats: 1770,
    special_seats: 0,
  },
  2010: {
    site_code: '2010',
    site_name: 'มหาวิทยาลัยนเรศวร',
    province: 'พิษณุโลก',
    center_code: '020',
    seats: 3930,
    special_seats: 0,
  },
  2011: {
    site_code: '2011',
    site_name: 'เพชรพิทยาคม',
    province: 'เพชรบูรณ์',
    center_code: '020',
    seats: 1860,
    special_seats: 0,
  },
  2012: {
    site_code: '2012',
    site_name: 'วิทยานุกูลนารี',
    province: 'เพชรบูรณ์',
    center_code: '020',
    seats: 2010,
    special_seats: 0,
  },
  2013: {
    site_code: '2013',
    site_name: 'หล่มสักวิทยาคม',
    province: 'เพชรบูรณ์',
    center_code: '020',
    seats: 2130,
    special_seats: 0,
  },
  2014: {
    site_code: '2014',
    site_name: 'สุโขทัยวิทยาคม',
    province: 'สุโขทัย',
    center_code: '020',
    seats: 1740,
    special_seats: 0,
  },
  2015: {
    site_code: '2015',
    site_name: 'สวรรค์อนันต์วิทยา',
    province: 'สุโขทัย',
    center_code: '020',
    seats: 990,
    special_seats: 0,
  },
  2016: {
    site_code: '2016',
    site_name: 'อุตรดิตถ์ดรุณี',
    province: 'อุตรดิตถ์',
    center_code: '020',
    seats: 2190,
    special_seats: 0,
  },
  2017: {
    site_code: '2017',
    site_name: 'อุทัยวิทยาคม',
    province: 'อุทัยธานี',
    center_code: '020',
    seats: 1830,
    special_seats: 0,
  },
  2051: {
    site_code: '2051',
    site_name: 'กำแพงเพชรพิทยาคม',
    province: 'กำแพงเพชร',
    center_code: '020',
    seats: 1920,
    special_seats: 0,
  },
  2052: {
    site_code: '2052',
    site_name: 'ชัยนาทพิทยาคม',
    province: 'ชัยนาท',
    center_code: '020',
    seats: 1740,
    special_seats: 0,
  },
  2053: {
    site_code: '2053',
    site_name: 'ตากพิทยาคม',
    province: 'ตาก',
    center_code: '020',
    seats: 1590,
    special_seats: 0,
  },
  2054: {
    site_code: '2054',
    site_name: 'นครสวรรค์',
    province: 'นครสวรรค์',
    center_code: '020',
    seats: 2100,
    special_seats: 0,
  },
  2055: {
    site_code: '2055',
    site_name: 'พิจิตรพิทยาคม',
    province: 'พิจิตร',
    center_code: '020',
    seats: 1770,
    special_seats: 0,
  },
  2056: {
    site_code: '2056',
    site_name: 'มหาวิทยาลัยนเรศวร',
    province: 'พิษณุโลก',
    center_code: '020',
    seats: 3930,
    special_seats: 0,
  },
  2057: {
    site_code: '2057',
    site_name: 'วิทยานุกูลนารี',
    province: 'เพชรบูรณ์',
    center_code: '020',
    seats: 2010,
    special_seats: 0,
  },
  2058: {
    site_code: '2058',
    site_name: 'หล่มสักวิทยาคม',
    province: 'เพชรบูรณ์',
    center_code: '020',
    seats: 2130,
    special_seats: 0,
  },
  2059: {
    site_code: '2059',
    site_name: 'สุโขทัยวิทยาคม',
    province: 'สุโขทัย',
    center_code: '020',
    seats: 1740,
    special_seats: 0,
  },
  2060: {
    site_code: '2060',
    site_name: 'อุตรดิตถ์ดรุณี',
    province: 'อุตรดิตถ์',
    center_code: '020',
    seats: 2190,
    special_seats: 0,
  },
  2061: {
    site_code: '2061',
    site_name: 'อุทัยวิทยาคม',
    province: 'อุทัยธานี',
    center_code: '020',
    seats: 1830,
    special_seats: 0,
  },
  2101: {
    site_code: '2101',
    site_name: 'กาฬสินธุ์พิทยาสรรพ์',
    province: 'กาฬสินธุ์',
    center_code: '021',
    seats: 2100,
    special_seats: 0,
  },
  2102: {
    site_code: '2102',
    site_name: 'อนุกูลนารี',
    province: 'กาฬสินธุ์',
    center_code: '021',
    seats: 1920,
    special_seats: 0,
  },
  2103: {
    site_code: '2103',
    site_name: 'สาธิตมหาวิทยาลัยมหาสารคาม (ฝ่ายมัธยม)',
    province: 'มหาสารคาม',
    center_code: '021',
    seats: 1380,
    special_seats: 0,
  },
  2104: {
    site_code: '2104',
    site_name: 'ผดุงนารี',
    province: 'มหาสารคาม',
    center_code: '021',
    seats: 1500,
    special_seats: 0,
  },
  2105: {
    site_code: '2105',
    site_name: 'สารคามพิทยาคม',
    province: 'มหาสารคาม',
    center_code: '021',
    seats: 1500,
    special_seats: 0,
  },
  2106: {
    site_code: '2106',
    site_name: 'ร้อยเอ็ดวิทยาลัย',
    province: 'ร้อยเอ็ด',
    center_code: '021',
    seats: 2520,
    special_seats: 0,
  },
  2107: {
    site_code: '2107',
    site_name: 'สตรีศึกษา ร้อยเอ็ด',
    province: 'ร้อยเอ็ด',
    center_code: '021',
    seats: 2100,
    special_seats: 0,
  },
  2151: {
    site_code: '2151',
    site_name: 'กาฬสินธุ์พิทยาสรรพ์',
    province: 'กาฬสินธุ์',
    center_code: '021',
    seats: 2100,
    special_seats: 0,
  },
  2152: {
    site_code: '2152',
    site_name: 'ผดุงนารี',
    province: 'มหาสารคาม',
    center_code: '021',
    seats: 1500,
    special_seats: 0,
  },
  2153: {
    site_code: '2153',
    site_name: 'สารคามพิทยาคม',
    province: 'มหาสารคาม',
    center_code: '021',
    seats: 1500,
    special_seats: 0,
  },
  2154: {
    site_code: '2154',
    site_name: 'ร้อยเอ็ดวิทยาลัย',
    province: 'ร้อยเอ็ด',
    center_code: '021',
    seats: 2520,
    special_seats: 0,
  },
  2155: {
    site_code: '2155',
    site_name: 'สตรีศึกษา ร้อยเอ็ด',
    province: 'ร้อยเอ็ด',
    center_code: '021',
    seats: 2100,
    special_seats: 0,
  },
  2301: {
    site_code: '2301',
    site_name: 'ศรียาภัย',
    province: 'ชุมพร',
    center_code: '023',
    seats: 2940,
    special_seats: 0,
  },
  2302: {
    site_code: '2302',
    site_name: 'มหาวิทยาลัยวลัยลักษณ์',
    province: 'นครศรีธรรมราช',
    center_code: '023',
    seats: 5980,
    special_seats: 0,
  },
  2303: {
    site_code: '2303',
    site_name: 'พิชัยรัตนาคาร',
    province: 'ระนอง',
    center_code: '023',
    seats: 1155,
    special_seats: 0,
  },
  2304: {
    site_code: '2304',
    site_name: 'สุราษฎร์ธานี',
    province: 'สุราษฎร์ธานี',
    center_code: '023',
    seats: 1820,
    special_seats: 0,
  },
  2305: {
    site_code: '2305',
    site_name: 'สุราษฎร์พิทยา',
    province: 'สุราษฎร์ธานี',
    center_code: '023',
    seats: 2450,
    special_seats: 0,
  },
  2351: {
    site_code: '2351',
    site_name: 'ศรียาภัย',
    province: 'ชุมพร',
    center_code: '023',
    seats: 2940,
    special_seats: 0,
  },
  2352: {
    site_code: '2352',
    site_name: 'มหาวิทยาลัยวลัยลักษณ์',
    province: 'นครศรีธรรมราช',
    center_code: '023',
    seats: 5980,
    special_seats: 0,
  },
  2353: {
    site_code: '2353',
    site_name: 'พิชัยรัตนาคาร',
    province: 'ระนอง',
    center_code: '023',
    seats: 1155,
    special_seats: 0,
  },
  2354: {
    site_code: '2354',
    site_name: 'สุราษฎร์ธานี',
    province: 'สุราษฎร์ธานี',
    center_code: '023',
    seats: 1820,
    special_seats: 0,
  },
  2355: {
    site_code: '2355',
    site_name: 'สุราษฎร์พิทยา',
    province: 'สุราษฎร์ธานี',
    center_code: '023',
    seats: 2450,
    special_seats: 0,
  },
  2501: {
    site_code: '2501',
    site_name: 'มหาวิทยาลัยนราธิวาสราชนครินทร์',
    province: 'นราธิวาส',
    center_code: '025',
    seats: 3180,
    special_seats: 0,
  },
  2502: {
    site_code: '2502',
    site_name: 'คณะราษฎรบำรุง จังหวัดยะลา',
    province: 'ยะลา',
    center_code: '025',
    seats: 1890,
    special_seats: 0,
  },
  2503: {
    site_code: '2503',
    site_name: 'สตรียะลา',
    province: 'ยะลา',
    center_code: '025',
    seats: 690,
    special_seats: 0,
  },
  2551: {
    site_code: '2551',
    site_name: 'มหาวิทยาลัยนราธิวาสราชนครินทร์',
    province: 'นราธิวาส',
    center_code: '025',
    seats: 3180,
    special_seats: 0,
  },
  2552: {
    site_code: '2552',
    site_name: 'คณะราษฎรบำรุง จังหวัดยะลา',
    province: 'ยะลา',
    center_code: '025',
    seats: 1890,
    special_seats: 0,
  },
  2553: {
    site_code: '2553',
    site_name: 'สตรียะลา',
    province: 'ยะลา',
    center_code: '025',
    seats: 690,
    special_seats: 0,
  },
  2601: {
    site_code: '2601',
    site_name: 'ปิยะมหาราชาลัย',
    province: 'นครพนม',
    center_code: '026',
    seats: 1860,
    special_seats: 0,
  },
  2602: {
    site_code: '2602',
    site_name: 'มุกดาหาร',
    province: 'มุกดาหาร',
    center_code: '026',
    seats: 1410,
    special_seats: 0,
  },
  2651: {
    site_code: '2651',
    site_name: 'ปิยะมหาราชาลัย',
    province: 'นครพนม',
    center_code: '026',
    seats: 1860,
    special_seats: 0,
  },
  2652: {
    site_code: '2652',
    site_name: 'มุกดาหาร',
    province: 'มุกดาหาร',
    center_code: '026',
    seats: 1410,
    special_seats: 0,
  },

  '0101': {
    site_code: '0101',
    site_name: 'ราชวินิต มัธยม',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 900,
    special_seats: 0,
  },
  '0102': {
    site_code: '0102',
    site_name: 'สาธิตจุฬาลงกรณ์มหาวิทยาลัย (ฝ่ายมัธยม)',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1080,
    special_seats: 0,
  },
  '0103': {
    site_code: '0103',
    site_name: 'นนทรีวิทยา',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 900,
    special_seats: 0,
  },
  '0104': {
    site_code: '0104',
    site_name: 'ศรีอยุธยา ในพระอุปถัมภ์ฯ',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1560,
    special_seats: 30,
  },
  '0105': {
    site_code: '0105',
    site_name: 'สันติราษฎร์วิทยาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1620,
    special_seats: 0,
  },
  '0106': {
    site_code: '0106',
    site_name: 'วัดสุทธิวราราม',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1290,
    special_seats: 0,
  },
  '0201': {
    site_code: '0201',
    site_name: 'สารวิทยา',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1800,
    special_seats: 0,
  },
  '0202': {
    site_code: '0202',
    site_name: 'หอวัง',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1800,
    special_seats: 0,
  },
  '0203': {
    site_code: '0203',
    site_name: 'ดอนเมืองทหารอากาศบำรุง',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1740,
    special_seats: 0,
  },
  '0204': {
    site_code: '0204',
    site_name: 'วิมุตยารามพิทยากร',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 600,
    special_seats: 0,
  },
  '0205': {
    site_code: '0205',
    site_name: 'สตรีวิทยา ๒',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1290,
    special_seats: 0,
  },
  '0206': {
    site_code: '0206',
    site_name: 'ฤทธิยะวรรณาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1800,
    special_seats: 0,
  },
  '0207': {
    site_code: '0207',
    site_name: 'ราชวินิตบางเขน',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1410,
    special_seats: 0,
  },
  '0208': {
    site_code: '0208',
    site_name: 'เทพศิรินทร์ นนทบุรี',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1380,
    special_seats: 0,
  },
  '0209': {
    site_code: '0209',
    site_name: 'ปากเกร็ด',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1620,
    special_seats: 0,
  },
  '0210': {
    site_code: '0210',
    site_name: 'สวนกุหลาบวิทยาลัย นนทบุรี',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1350,
    special_seats: 0,
  },
  '0211': {
    site_code: '0211',
    site_name: 'เบญจมราชานุสรณ์',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 960,
    special_seats: 0,
  },
  '0212': {
    site_code: '0212',
    site_name: 'วัดเขมาภิรตาราม',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1860,
    special_seats: 0,
  },
  '0213': {
    site_code: '0213',
    site_name: 'สตรีนนทบุรี',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 840,
    special_seats: 0,
  },
  '0301': {
    site_code: '0301',
    site_name: 'ชุมแพศึกษา',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1380,
    special_seats: 0,
  },
  '0302': {
    site_code: '0302',
    site_name: 'รร.เมืองพลพิทยาคม',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 930,
    special_seats: 0,
  },
  '0303': {
    site_code: '0303',
    site_name: 'กัลยาณวัตร',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1650,
    special_seats: 0,
  },
  '0304': {
    site_code: '0304',
    site_name: 'แก่นนครวิทยาลัย',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1950,
    special_seats: 0,
  },
  '0305': {
    site_code: '0305',
    site_name: 'ขอนแก่นวิทยายน',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1560,
    special_seats: 0,
  },
  '0306': {
    site_code: '0306',
    site_name: 'ขามแก่นนคร',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1590,
    special_seats: 0,
  },
  '0307': {
    site_code: '0307',
    site_name: 'บึงกาฬ',
    province: 'บึงกาฬ',
    center_code: '003',
    seats: 960,
    special_seats: 0,
  },
  '0308': {
    site_code: '0308',
    site_name: 'เลยพิทยาคม',
    province: 'เลย',
    center_code: '003',
    seats: 1560,
    special_seats: 0,
  },
  '0309': {
    site_code: '0309',
    site_name: 'ธาตุนารายณ์วิทยา',
    province: 'สกลนคร',
    center_code: '003',
    seats: 1350,
    special_seats: 0,
  },
  '0310': {
    site_code: '0310',
    site_name: 'สกลราชวิทยานุกูล',
    province: 'สกลนคร',
    center_code: '003',
    seats: 1800,
    special_seats: 0,
  },
  '0311': {
    site_code: '0311',
    site_name: 'ปทุมเทพวิทยาคาร',
    province: 'หนองคาย',
    center_code: '003',
    seats: 1200,
    special_seats: 0,
  },
  '0312': {
    site_code: '0312',
    site_name: 'หนองบัวพิทยาคาร',
    province: 'หนองบัวลำภู',
    center_code: '003',
    seats: 990,
    special_seats: 0,
  },
  '0313': {
    site_code: '0313',
    site_name: 'ประจักษ์ศิลปาคาร',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 870,
    special_seats: 0,
  },
  '0314': {
    site_code: '0314',
    site_name: 'สตรีราชินูทิศ',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 1500,
    special_seats: 0,
  },
  '0315': {
    site_code: '0315',
    site_name: 'อุดรพิชัยรักษ์พิทยา',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 1470,
    special_seats: 0,
  },
  '0316': {
    site_code: '0316',
    site_name: 'อุดรพิทยานุกูล',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 1680,
    special_seats: 0,
  },
  '0401': {
    site_code: '0401',
    site_name: 'ดำรงราษฎร์สงเคราะห์',
    province: 'เชียงราย',
    center_code: '004',
    seats: 1920,
    special_seats: 30,
  },
  '0402': {
    site_code: '0402',
    site_name: 'สามัคคีวิทยาคม',
    province: 'เชียงราย',
    center_code: '004',
    seats: 2250,
    special_seats: 30,
  },
  '0403': {
    site_code: '0403',
    site_name: 'กาวิละวิทยาลัย',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 725,
    special_seats: 25,
  },
  '0404': {
    site_code: '0404',
    site_name: 'มงฟอร์ตวิทยาลัย',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1725,
    special_seats: 25,
  },
  '0405': {
    site_code: '0405',
    site_name: 'ยุพราชวิทยาลัย',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1750,
    special_seats: 25,
  },
  '0406': {
    site_code: '0406',
    site_name: 'วัฒโนทัยพายัพ',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1225,
    special_seats: 25,
  },
  '0407': {
    site_code: '0407',
    site_name: 'วารีเชียงใหม่',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1425,
    special_seats: 25,
  },
  '0408': {
    site_code: '0408',
    site_name: 'สตรีศรีน่าน',
    province: 'น่าน',
    center_code: '004',
    seats: 1950,
    special_seats: 30,
  },
  '0409': {
    site_code: '0409',
    site_name: 'พะเยาพิทยาคม',
    province: 'พะเยา',
    center_code: '004',
    seats: 1800,
    special_seats: 30,
  },
  '0410': {
    site_code: '0410',
    site_name: 'นารีรัตน์จังหวัดแพร่',
    province: 'แพร่',
    center_code: '004',
    seats: 1860,
    special_seats: 30,
  },
  '0411': {
    site_code: '0411',
    site_name: 'ห้องสอนศึกษา ในพระอุปถัมภ์ฯ',
    province: 'แม่ฮ่องสอน',
    center_code: '004',
    seats: 990,
    special_seats: 30,
  },
  '0412': {
    site_code: '0412',
    site_name: 'บุญวาทย์วิทยาลัย',
    province: 'ลำปาง',
    center_code: '004',
    seats: 1800,
    special_seats: 30,
  },
  '0413': {
    site_code: '0413',
    site_name: 'ลำปางกัลยาณี',
    province: 'ลำปาง',
    center_code: '004',
    seats: 1650,
    special_seats: 30,
  },
  '0414': {
    site_code: '0414',
    site_name: 'จักรคำคณาทร จังหวัดลำพูน',
    province: 'ลำพูน',
    center_code: '004',
    seats: 1530,
    special_seats: 30,
  },
  '0501': {
    site_code: '0501',
    site_name: 'ธัญบุรี',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 1350,
    special_seats: 0,
  },
  '0502': {
    site_code: '0502',
    site_name: 'ธัญรัตน์',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 1710,
    special_seats: 0,
  },
  '0503': {
    site_code: '0503',
    site_name: 'สายปัญญารังสิต',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 1620,
    special_seats: 0,
  },
  '0504': {
    site_code: '0504',
    site_name: 'คณะราษฎร์บำรุงปทุมธานี',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 960,
    special_seats: 0,
  },
  '0505': {
    site_code: '0505',
    site_name: 'ปทุมวิไล',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 930,
    special_seats: 0,
  },
  '0506': {
    site_code: '0506',
    site_name: 'จอมสุรางค์อุปถัมภ์',
    province: 'พระนครศรีอยุธยา',
    center_code: '005',
    seats: 990,
    special_seats: 0,
  },
  '0507': {
    site_code: '0507',
    site_name: 'อยุธยานุสรณ์',
    province: 'พระนครศรีอยุธยา',
    center_code: '005',
    seats: 1140,
    special_seats: 0,
  },
  '0508': {
    site_code: '0508',
    site_name: 'อยุธยาวิทยาลัย',
    province: 'พระนครศรีอยุธยา',
    center_code: '005',
    seats: 1770,
    special_seats: 0,
  },
  '0509': {
    site_code: '0509',
    site_name: 'สิงห์บุรี',
    province: 'สิงห์บุรี',
    center_code: '005',
    seats: 750,
    special_seats: 0,
  },
  '0510': {
    site_code: '0510',
    site_name: 'อ่างทองปัทมโรจน์วิทยาคม',
    province: 'อ่างทอง',
    center_code: '005',
    seats: 2250,
    special_seats: 0,
  },
  '0801': {
    site_code: '0801',
    site_name: 'วิสุทธรังษี จังหวัดกาญจนบุรี',
    province: 'กาญจนบุรี',
    center_code: '008',
    seats: 1260,
    special_seats: 0,
  },
  '0802': {
    site_code: '0802',
    site_name: 'กาญจนานุเคราะห์',
    province: 'กาญจนบุรี',
    center_code: '008',
    seats: 1500,
    special_seats: 0,
  },
  '0803': {
    site_code: '0803',
    site_name: 'รัตนโกสินทร์สมโภชบวรนิเวศศาลายา ในพระสังฆราชูปถัมภ์',
    province: 'นครปฐม',
    center_code: '008',
    seats: 500,
    special_seats: 0,
  },
  '0804': {
    site_code: '0804',
    site_name: 'พระปฐมวิทยาลัย',
    province: 'นครปฐม',
    center_code: '008',
    seats: 1140,
    special_seats: 0,
  },
  '0805': {
    site_code: '0805',
    site_name: 'ราชินีบูรณะ',
    province: 'นครปฐม',
    center_code: '008',
    seats: 860,
    special_seats: 0,
  },
  '0806': {
    site_code: '0806',
    site_name: 'ศรีวิชัยวิทยา',
    province: 'นครปฐม',
    center_code: '008',
    seats: 820,
    special_seats: 0,
  },
  '0807': {
    site_code: '0807',
    site_name: 'วัดไร่ขิงวิทยา',
    province: 'นครปฐม',
    center_code: '008',
    seats: 600,
    special_seats: 0,
  },
  '0808': {
    site_code: '0808',
    site_name: 'ประจวบวิทยาลัย',
    province: 'ประจวบคีรีขันธ์',
    center_code: '008',
    seats: 1620,
    special_seats: 0,
  },
  '0809': {
    site_code: '0809',
    site_name: 'เบญจมเทพอุทิศจังหวัดเพชรบุรี',
    province: 'เพชรบุรี',
    center_code: '008',
    seats: 2070,
    special_seats: 0,
  },
  '0810': {
    site_code: '0810',
    site_name: 'รัตนราษฎร์บำรุง',
    province: 'ราชบุรี',
    center_code: '008',
    seats: 1020,
    special_seats: 0,
  },
  '0811': {
    site_code: '0811',
    site_name: 'เบญจมราชูทิศ ราชบุรี',
    province: 'ราชบุรี',
    center_code: '008',
    seats: 2160,
    special_seats: 0,
  },
  '0812': {
    site_code: '0812',
    site_name: 'ถาวรานุกูล',
    province: 'สมุทรสงคราม',
    center_code: '008',
    seats: 510,
    special_seats: 0,
  },
  '0813': {
    site_code: '0813',
    site_name: 'สมุทรสาครบูรณะ',
    province: 'สมุทรสาคร',
    center_code: '008',
    seats: 1980,
    special_seats: 0,
  },
  '0814': {
    site_code: '0814',
    site_name: 'กรรณสูตศึกษาลัย',
    province: 'สุพรรณบุรี',
    center_code: '008',
    seats: 1710,
    special_seats: 0,
  },
  '0815': {
    site_code: '0815',
    site_name: 'สงวนหญิง',
    province: 'สุพรรณบุรี',
    center_code: '008',
    seats: 960,
    special_seats: 0,
  },
  '0901': {
    site_code: '0901',
    site_name: 'ปทุมคงคา',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 600,
    special_seats: 0,
  },
  '0902': {
    site_code: '0902',
    site_name: 'สายน้ำผึ้ง ในพระอุปถัมภ์ ฯ',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 600,
    special_seats: 0,
  },
  '0903': {
    site_code: '0903',
    site_name: 'กุนนทีรุทธารามวิทยาคม',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 900,
    special_seats: 0,
  },
  '0904': {
    site_code: '0904',
    site_name: 'สุรศักดิ์มนตรี',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 1200,
    special_seats: 30,
  },
  '0905': {
    site_code: '0905',
    site_name: 'พระโขนงพิทยาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 510,
    special_seats: 0,
  },
  '0906': {
    site_code: '0906',
    site_name: 'วชิรธรรมสาธิต',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 750,
    special_seats: 0,
  },
  '0907': {
    site_code: '0907',
    site_name: 'มัธยมวัดธาตุทอง',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 750,
    special_seats: 0,
  },
  '0908': {
    site_code: '0908',
    site_name: 'สาธิตมหาวิทยาลัยศรีนครินทรวิโรฒ ประสานมิตร (ฝ่ายมัธยม)',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 1230,
    special_seats: 0,
  },
  '0909': {
    site_code: '0909',
    site_name: 'นครนายกวิทยาคม',
    province: 'นครนายก',
    center_code: '009',
    seats: 2070,
    special_seats: 0,
  },
  '0910': {
    site_code: '0910',
    site_name: 'พระนารายณ์',
    province: 'ลพบุรี',
    center_code: '009',
    seats: 1590,
    special_seats: 0,
  },
  '0911': {
    site_code: '0911',
    site_name: 'พิบูลวิทยาลัย',
    province: 'ลพบุรี',
    center_code: '009',
    seats: 2190,
    special_seats: 0,
  },
  '0912': {
    site_code: '0912',
    site_name: 'สระบุรีวิทยาคม',
    province: 'สระบุรี',
    center_code: '009',
    seats: 2100,
    special_seats: 30,
  },
  '0151': {
    site_code: '0151',
    site_name: 'ราชวินิต มัธยม',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 900,
    special_seats: 0,
  },
  '0152': {
    site_code: '0152',
    site_name: 'เตรียมอุดมศึกษา',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 3120,
    special_seats: 30,
  },
  '0153': {
    site_code: '0153',
    site_name: 'นนทรีวิทยา',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 900,
    special_seats: 0,
  },
  '0154': {
    site_code: '0154',
    site_name: 'ศรีอยุธยา ในพระอุปถัมภ์ฯ',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1550,
    special_seats: 30,
  },
  '0155': {
    site_code: '0155',
    site_name: 'สันติราษฎร์วิทยาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1620,
    special_seats: 0,
  },
  '0156': {
    site_code: '0156',
    site_name: 'วัดสุทธิวราราม',
    province: 'กรุงเทพมหานคร',
    center_code: '001',
    seats: 1410,
    special_seats: 0,
  },
  '0251': {
    site_code: '0251',
    site_name: 'สารวิทยา',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1800,
    special_seats: 0,
  },
  '0252': {
    site_code: '0252',
    site_name: 'หอวัง',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1800,
    special_seats: 0,
  },
  '0253': {
    site_code: '0253',
    site_name: 'ดอนเมืองทหารอากาศบำรุง',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1740,
    special_seats: 0,
  },
  '0254': {
    site_code: '0254',
    site_name: 'ฤทธิยะวรรณาลัย',
    province: 'กรุงเทพมหานคร',
    center_code: '002',
    seats: 1800,
    special_seats: 0,
  },
  '0255': {
    site_code: '0255',
    site_name: 'เทพศิรินทร์ นนทบุรี',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1380,
    special_seats: 0,
  },
  '0256': {
    site_code: '0256',
    site_name: 'ปากเกร็ด',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1620,
    special_seats: 0,
  },
  '0257': {
    site_code: '0257',
    site_name: 'สวนกุหลาบวิทยาลัย นนทบุรี',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1350,
    special_seats: 0,
  },
  '0258': {
    site_code: '0258',
    site_name: 'วัดเขมาภิรตาราม',
    province: 'นนทบุรี',
    center_code: '002',
    seats: 1860,
    special_seats: 0,
  },
  '0351': {
    site_code: '0351',
    site_name: 'ชุมแพศึกษา',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1140,
    special_seats: 0,
  },
  '0352': {
    site_code: '0352',
    site_name: 'รร.เมืองพลพิทยาคม',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 690,
    special_seats: 0,
  },
  '0353': {
    site_code: '0353',
    site_name: 'กัลยาณวัตร',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1620,
    special_seats: 0,
  },
  '0354': {
    site_code: '0354',
    site_name: 'แก่นนครวิทยาลัย',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1950,
    special_seats: 0,
  },
  '0355': {
    site_code: '0355',
    site_name: 'ขอนแก่นวิทยายน',
    province: 'ขอนแก่น',
    center_code: '003',
    seats: 1560,
    special_seats: 0,
  },
  '0356': {
    site_code: '0356',
    site_name: 'บึงกาฬ',
    province: 'บึงกาฬ',
    center_code: '003',
    seats: 570,
    special_seats: 0,
  },
  '0357': {
    site_code: '0357',
    site_name: 'เลยพิทยาคม',
    province: 'เลย',
    center_code: '003',
    seats: 960,
    special_seats: 0,
  },
  '0358': {
    site_code: '0358',
    site_name: 'สกลราชวิทยานุกูล',
    province: 'สกลนคร',
    center_code: '003',
    seats: 1800,
    special_seats: 0,
  },
  '0359': {
    site_code: '0359',
    site_name: 'ปทุมเทพวิทยาคาร',
    province: 'หนองคาย',
    center_code: '003',
    seats: 900,
    special_seats: 0,
  },
  '0360': {
    site_code: '0360',
    site_name: 'หนองบัวพิทยาคาร',
    province: 'หนองบัวลำภู',
    center_code: '003',
    seats: 690,
    special_seats: 0,
  },
  '0361': {
    site_code: '0361',
    site_name: 'สตรีราชินูทิศ',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 1200,
    special_seats: 0,
  },
  '0362': {
    site_code: '0362',
    site_name: 'อุดรพิชัยรักษ์พิทยา',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 1200,
    special_seats: 0,
  },
  '0363': {
    site_code: '0363',
    site_name: 'อุดรพิทยานุกูล',
    province: 'อุดรธานี',
    center_code: '003',
    seats: 1350,
    special_seats: 0,
  },
  '0451': {
    site_code: '0451',
    site_name: 'ดำรงราษฎร์สงเคราะห์',
    province: 'เชียงราย',
    center_code: '004',
    seats: 1920,
    special_seats: 30,
  },
  '0452': {
    site_code: '0452',
    site_name: 'สามัคคีวิทยาคม',
    province: 'เชียงราย',
    center_code: '004',
    seats: 2250,
    special_seats: 30,
  },
  '0453': {
    site_code: '0453',
    site_name: 'กาวิละวิทยาลัย',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 725,
    special_seats: 25,
  },
  '0454': {
    site_code: '0454',
    site_name: 'ยุพราชวิทยาลัย',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1750,
    special_seats: 25,
  },
  '0455': {
    site_code: '0455',
    site_name: 'วารีเชียงใหม่',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1425,
    special_seats: 25,
  },
  '0456': {
    site_code: '0456',
    site_name: 'หอพระ',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 700,
    special_seats: 25,
  },
  '0457': {
    site_code: '0457',
    site_name: 'นวมินทราชูทิศ พายัพ',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 950,
    special_seats: 25,
  },
  '0458': {
    site_code: '0458',
    site_name: 'สตรีศรีน่าน',
    province: 'น่าน',
    center_code: '004',
    seats: 1950,
    special_seats: 30,
  },
  '0459': {
    site_code: '0459',
    site_name: 'พะเยาพิทยาคม',
    province: 'พะเยา',
    center_code: '004',
    seats: 1800,
    special_seats: 30,
  },
  '0460': {
    site_code: '0460',
    site_name: 'นารีรัตน์จังหวัดแพร่',
    province: 'แพร่',
    center_code: '004',
    seats: 1860,
    special_seats: 30,
  },
  '0461': {
    site_code: '0461',
    site_name: 'ห้องสอนศึกษา ในพระอุปถัมภ์ฯ',
    province: 'แม่ฮ่องสอน',
    center_code: '004',
    seats: 990,
    special_seats: 30,
  },
  '0462': {
    site_code: '0462',
    site_name: 'บุญวาทย์วิทยาลัย',
    province: 'ลำปาง',
    center_code: '004',
    seats: 1800,
    special_seats: 30,
  },
  '0463': {
    site_code: '0463',
    site_name: 'ลำปางกัลยาณี',
    province: 'ลำปาง',
    center_code: '004',
    seats: 1650,
    special_seats: 30,
  },
  '0464': {
    site_code: '0464',
    site_name: 'ส่วนบุญโญปถัมภ์ ลำพูน',
    province: 'ลำพูน',
    center_code: '004',
    seats: 1890,
    special_seats: 30,
  },
  '0465': {
    site_code: '0465',
    site_name: 'วัฒโนทัยพายัพ',
    province: 'เชียงใหม่',
    center_code: '004',
    seats: 1000,
    special_seats: 0,
  },
  '0551': {
    site_code: '0551',
    site_name: 'ธัญบุรี',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 1350,
    special_seats: 0,
  },
  '0552': {
    site_code: '0552',
    site_name: 'ธัญรัตน์',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 1710,
    special_seats: 0,
  },
  '0553': {
    site_code: '0553',
    site_name: 'สายปัญญารังสิต',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 1620,
    special_seats: 0,
  },
  '0554': {
    site_code: '0554',
    site_name: 'คณะราษฎร์บำรุงปทุมธานี',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 960,
    special_seats: 0,
  },
  '0555': {
    site_code: '0555',
    site_name: 'ปทุมวิไล',
    province: 'ปทุมธานี',
    center_code: '005',
    seats: 930,
    special_seats: 0,
  },
  '0556': {
    site_code: '0556',
    site_name: 'จอมสุรางค์อุปถัมภ์',
    province: 'พระนครศรีอยุธยา',
    center_code: '005',
    seats: 990,
    special_seats: 0,
  },
  '0557': {
    site_code: '0557',
    site_name: 'อยุธยานุสรณ์',
    province: 'พระนครศรีอยุธยา',
    center_code: '005',
    seats: 1140,
    special_seats: 0,
  },
  '0558': {
    site_code: '0558',
    site_name: 'อยุธยาวิทยาลัย',
    province: 'พระนครศรีอยุธยา',
    center_code: '005',
    seats: 1770,
    special_seats: 0,
  },
  '0559': {
    site_code: '0559',
    site_name: 'สิงห์บุรี',
    province: 'สิงห์บุรี',
    center_code: '005',
    seats: 750,
    special_seats: 0,
  },
  '0560': {
    site_code: '0560',
    site_name: 'สตรีอ่างทอง',
    province: 'อ่างทอง',
    center_code: '005',
    seats: 1770,
    special_seats: 0,
  },
  '0851': {
    site_code: '0851',
    site_name: 'กาญจนานุเคราะห์',
    province: 'กาญจนบุรี',
    center_code: '008',
    seats: 1500,
    special_seats: 0,
  },
  '0852': {
    site_code: '0852',
    site_name: 'รัตนโกสินทร์สมโภชบวรนิเวศศาลายา ในพระสังฆราชูปถัมภ์',
    province: 'นครปฐม',
    center_code: '008',
    seats: 700,
    special_seats: 0,
  },
  '0853': {
    site_code: '0853',
    site_name: 'พระปฐมวิทยาลัย',
    province: 'นครปฐม',
    center_code: '008',
    seats: 1140,
    special_seats: 0,
  },
  '0854': {
    site_code: '0854',
    site_name: 'ราชินีบูรณะ',
    province: 'นครปฐม',
    center_code: '008',
    seats: 860,
    special_seats: 0,
  },
  '0855': {
    site_code: '0855',
    site_name: 'วัดไร่ขิงวิทยา',
    province: 'นครปฐม',
    center_code: '008',
    seats: 600,
    special_seats: 0,
  },
  '0856': {
    site_code: '0856',
    site_name: 'ประจวบวิทยาลัย',
    province: 'ประจวบคีรีขันธ์',
    center_code: '008',
    seats: 1620,
    special_seats: 0,
  },
  '0857': {
    site_code: '0857',
    site_name: 'เบญจมเทพอุทิศจังหวัดเพชรบุรี',
    province: 'เพชรบุรี',
    center_code: '008',
    seats: 2070,
    special_seats: 0,
  },
  '0858': {
    site_code: '0858',
    site_name: 'เบญจมราชูทิศ ราชบุรี',
    province: 'ราชบุรี',
    center_code: '008',
    seats: 2160,
    special_seats: 0,
  },
  '0859': {
    site_code: '0859',
    site_name: 'ถาวรานุกูล',
    province: 'สมุทรสงคราม',
    center_code: '008',
    seats: 510,
    special_seats: 0,
  },
  '0860': {
    site_code: '0860',
    site_name: 'สมุทรสาครบูรณะ',
    province: 'สมุทรสาคร',
    center_code: '008',
    seats: 1980,
    special_seats: 0,
  },
  '0861': {
    site_code: '0861',
    site_name: 'กรรณสูตศึกษาลัย',
    province: 'สุพรรณบุรี',
    center_code: '008',
    seats: 1710,
    special_seats: 0,
  },
  '0951': {
    site_code: '0951',
    site_name: 'ปทุมคงคา',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 600,
    special_seats: 0,
  },
  '0952': {
    site_code: '0952',
    site_name: 'สายน้ำผึ้ง ในพระอุปถัมภ์ ฯ',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 600,
    special_seats: 0,
  },
  '0953': {
    site_code: '0953',
    site_name: 'กุนนทีรุทธารามวิทยาคม',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 900,
    special_seats: 0,
  },
  '0954': {
    site_code: '0954',
    site_name: 'สุรศักดิ์มนตรี',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 1200,
    special_seats: 30,
  },
  '0955': {
    site_code: '0955',
    site_name: 'วชิรธรรมสาธิต',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 750,
    special_seats: 0,
  },
  '0956': {
    site_code: '0956',
    site_name: 'มัธยมวัดธาตุทอง',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 750,
    special_seats: 0,
  },
  '0957': {
    site_code: '0957',
    site_name: 'สาธิตมหาวิทยาลัยศรีนครินทรวิโรฒ ประสานมิตร (ฝ่ายมัธยม)',
    province: 'กรุงเทพมหานคร',
    center_code: '009',
    seats: 1230,
    special_seats: 0,
  },
  '0958': {
    site_code: '0958',
    site_name: 'นครนายกวิทยาคม',
    province: 'นครนายก',
    center_code: '009',
    seats: 2070,
    special_seats: 0,
  },
  '0959': {
    site_code: '0959',
    site_name: 'พระนารายณ์',
    province: 'ลพบุรี',
    center_code: '009',
    seats: 1590,
    special_seats: 0,
  },
  '0960': {
    site_code: '0960',
    site_name: 'พิบูลวิทยาลัย',
    province: 'ลพบุรี',
    center_code: '009',
    seats: 2190,
    special_seats: 0,
  },
  '0961': {
    site_code: '0961',
    site_name: 'สระบุรีวิทยาคม',
    province: 'สระบุรี',
    center_code: '009',
    seats: 2100,
    special_seats: 30,
  },
};
