import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import callApi from 'helpers/callapi';

import moment from 'moment';
import cn from 'classnames';

// Components
import ImportModal from './ImportModal';
import VideoModal from 'components/VideoModal';
import Timer from 'components/Timer2';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import useImportInfo from './useImportInfo';
import FileModal from './FilesModal';

const BASE_PATH = 'https://www.mytcas.com/programs';

const Programs = () => {
  const history = useHistory();

  const [expiryTimestamp, setExpiryTimestamp] = useState();

  const { loading, university, setUniversity, fetchUniversityInfo } = useMyUniversity();
  const [currentIndex, setCurrentIndex] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [importErrors, setImportErrors] = useState([]);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);
  const [logURL, setLogURL] = useState('');
  const adminConfig = useSelector((state) => state.configs || {});
  const [updatedData, setUpdatedData] = useState([]);

  const fetchUpdateProgram = async () => {
    const response = await callApi({ url: '/programs/last-update' });
    setUpdatedData(response.data);

    const date = moment(response.endDate);
    setExpiryTimestamp(date.toDate());
    // start();
  };

  useEffect(() => {
    fetchUpdateProgram();
  }, []);
  if (!university) return null;

  if (!adminConfig.is_closed_for_import) {
    // history.push('/programs/import');
    history.push('/programs');
    // return <div></div>;
  }
  if (adminConfig.program_project_allow_update_rounds === '') {
    history.push('/programs');
    return <div></div>;
  }

  return (
    <div>
      <h1>ปรับปรุงข้อมูลหลักสูตร</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            ผู้ดูแลหลักสูตรสามารถแก้ข้อมูลได้ตามแบบฟอร์มด้านล่าง
            <br />
            ระบบจะนำไปแทนที่ข้อมูลเดิมให้ในเวลา 03:00 น. ของทุกวัน
            <br />
            {/* <b>หมายเหตุ:</b> สามารถโหลดไฟล์ย้อนหลังได้ที่หน้า{' '}
            <a href="/logs">
              <b>ประวัติการใช้งาน</b>
            </a> */}
          </p>
        </div>
        <div className="help">
          <a onClick={() => setIsVideoModalVisible(!isVideoModalVisible)}>
            <i className="i-video"></i>
            วีดีโอวิธีการใช้งาน
          </a>
          <a href="https://assets.mytcas.com/d/T65-Uadmin-v5-19apr22.pdf" target="_blank">
            <i className="i-manual"></i>
            คู่มือการใช้งาน (v5)
          </a>
        </div>
      </div>

      {/* <div className="sec-announce"></div> */}

      {updatedData.length > 0 && expiryTimestamp && (
        <div className="sec-important">
          <Timer expiryTimestamp={expiryTimestamp}></Timer>

          <p>มีการปรับปรุงข้อมูลหลักสูตร รอขึ้นแสดงผลที่หน้าเว็บไซต์ดังนี้</p>
          <ol>
            {updatedData.map((data) => {
              return (
                <li>
                  <b className={cn('badge', { '-green': data.last_update_type === 'create' })}>
                    {data.last_update_type === 'update' ? 'ปรับปรุง' : 'เพิ่มใหม่'}
                  </b>
                  <span className="id">
                    {data.program_id} {data.major_id && data.major_id !== '0' ? `MAJOR ${data.major_id}` : ''}
                  </span>
                  <span className="course">{data.program_name_th}</span>
                  <a
                    href={`${BASE_PATH}/${data.program_id}${
                      data.major_id && data.major_id !== '0' ? `?major=${data.major_id}` : ''
                    }`}
                    className="current"
                  >
                    ข้อมูลปัจจุบัน →
                  </a>
                  <a
                    href={`${BASE_PATH}/${data.program_id}${
                      data.major_id && data.major_id !== '0' ? `?major=${data.major_id}&preview=true` : '?preview=true'
                    }`}
                    className="new"
                  >
                    ข้อมูลใหม่ →
                  </a>
                </li>
              );
            })}
            {/* <li>
              <b className="badge -green">เพิ่มใหม่</b>
              <span className="id">10010121300001A</span>
              <span className="course">หลักสูตรวิศวกรรมศาสตรบัณฑิต สาขาวิชาวิศวกรรมศาสตร์</span>
              <a href="#" className="current">
                &nbsp;
              </a>
              <a href="#" className="new">
                ข้อมูลใหม่ →
              </a>
            </li> */}
          </ol>
        </div>
      )}

      {Boolean(importErrors.length) && (
        <div className="sec-error">
          <h2>ไม่สามารถอัปโหลด{errorTitle}ได้</h2>
          <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ ทั้งหมด {importErrors.length} แถว</p>
          <ul>
            {importErrors.map(({ index, message }, _idx) => (
              <li key={_idx}>
                แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
              </li>
            ))}
          </ul>
        </div>
      )}

      <ul className="sec-import">
        {Array(3)
          .fill()
          .map((_, index) => {
            const {
              title,
              exampleFilePath,
              filePath,
              lastUpdateFilePath,
              updatedAt,
              uploadable,
              count,
              uploadURL,
              logFileURL,
            } = useImportInfo(university, index + 1, { type: 'update' });
            const _lastUpdateFilePath = lastUpdateFilePath || filePath;

            return (
              <li key={index} className={`round-${index + 1}`}>
                <h2>
                  <b className="num">{index + 1}</b>
                  <b>
                    {title}
                    <i>{count ? `(${count} รายการ)` : ''}</i>
                  </b>
                </h2>
                <a className="example" href={filePath}>
                  ดาวน์โหลดไฟล์ตั้งต้น
                </a>
                {_lastUpdateFilePath ? (
                  <div className="file">
                    <a className="csv" href={_lastUpdateFilePath}>
                      <i className="i-course"></i>
                      <b>{_lastUpdateFilePath.split('/').pop()}</b>
                      <small>{moment(updatedAt).add(543, 'years').format('D MMM YY HH:mm น.')} </small>
                    </a>
                    <a
                      className="_heading viewall"
                      onClick={() => {
                        setLogURL(logFileURL);
                        setIsFileModalVisible(true);
                        console.log(logFileURL);
                      }}
                    >
                      ดูไฟล์ทั้งหมด
                    </a>
                  </div>
                ) : (
                  <div className="file -none">
                    <a href="#">
                      <i className="i-course"></i>
                      <b>ยังไม่มีข้อมูล</b>
                    </a>
                  </div>
                )}
                <div className="action">
                  <a
                    className={cn('btn-main', { 'btn-disabled': !uploadable })}
                    style={uploadable ? null : { cursor: 'not-allowed' }}
                    onClick={uploadable ? () => setCurrentIndex(index + 1) : () => {}}
                  >
                    <i className="i-upload"></i> นำเข้าข้อมูล
                  </a>
                </div>
              </li>
            );
          })}
      </ul>
      <ImportModal
        index={currentIndex}
        onCancel={() => setCurrentIndex()}
        university={university}
        onUniversityUpdate={(_university) => setUniversity(_university)}
        onErrorsChange={(errors) => {
          setErrorTitle({ 1: 'เนื้อหาหลักสูตร', 2: 'เงื่อนไขการรับ', 3: 'การคำนวณคะแนน' }[currentIndex]);
          setCurrentIndex();
          setImportErrors(errors);
        }}
        config={{
          type: 'update',
          onUploadDone: (results) => {
            fetchUpdateProgram();
            fetchUniversityInfo();
          },
        }}
      />

      <VideoModal isVisible={isVideoModalVisible} vimeoId={'642471244'} setIsVisible={setIsVideoModalVisible} />

      {isFileModalVisible && logURL && (
        <FileModal isVisible={isFileModalVisible} setIsVisible={setIsFileModalVisible} url={logURL} />
      )}
    </div>
  );
};

export default Programs;
