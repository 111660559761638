import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue, downloadFromUrl } from 'helpers/helpers';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const schools = useSelector((state) => {
    return state.schools || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rounds, setRounds] = useState([]);
  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  let foundSchool = false;
  console.log(schoolSearchText, selectedSchool);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    citizen_id: '',
  });

  const fetchSchool = async (university_id) => {
    try {
      const result = await callApi({
        url: '/schools/all',
      });
      dispatch({
        type: 'UPDATE_SCHOOLS',
        data: result,
      });
    } catch (error) {
      console.log('errror', error);
    }
  };
  // const [creditCount, setCreditCount] = useState()
  const fetchStudents = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: students } = await callApi({
        url: '/students',
        query,
      });
      setStudents(students || []);
      setStudentCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setStudents([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents(1, {});
  }, []);

  useEffect(() => {
    if (me) {
      fetchSchool();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchStudents(_page);
  };

  const onSchoolChange = async (value, option) => {
    setFilterQuery({
      ...filterQuery,
      school: value,
    });
    // setSchoolSearchText('');
    setSelectedSchool(option);
  };
  const onSearch = async () => {
    setCurrentPage(1);
    fetchStudents(1);
  };

  const exportStudents = async () => {
    try {
      setLoading(true);
      const result = await callApi({
        url: '/students/exports',
        method: 'POST',
      });

      downloadFromUrl(result.link);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('errror', error);
    }
  };

  const onExportStudentButtonClick = () => {
    Modal.confirm({
      title: 'ต้องการนำออกข้อมูลนักเรียนทั้งหมด',
      content: (
        <div>
          <p>
            หลังจากกดยืนยันระบบจะใช้เวลา 3 - 5 นาทีในการสร้างไฟล์นักเรียนทั้งหมด หากท่านยังไม่ได้ไฟล์สามารถ Download
            ได้ที่เมนูข้อมูลระบบ
          </p>
        </div>
      ),
      onOk() {
        exportStudents();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            ข้อมูลนักเรียน
            <div style={{ float: 'right' }}>
              {['senior', 'super'].indexOf(get(me, 'role')) > -1 && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ marginRight: 10 }}
                  onClick={() => onExportStudentButtonClick()}
                >
                  นำออกข้อมูลนักเรียนทั้งหมด
                </Button>
              )}
            </div>
          </h1>

          <div className="sec-box">
            <h2>ค้นหานักเรียน</h2>
            <div className="sec-form">
              <div className="col-25">
                <label>รหัสประจำตัวประชาชน</label>
                <Input
                  value={get(filterQuery, 'citizen_id')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      citizen_id: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>
              <div className="col-50">
                <label>ค้นจากชื่อ, นามสกุล, อีเมล, เบอร์ติดต่อ</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchStudents(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {students.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลนักเรียน</th>
                </tr>
              </tbody>
            </table>
          )}
          {students.length > 0 && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr>
                    <th>รหัสประจำตัวประชาชน</th>
                    <th>ชื่อ - นามสกุล</th>
                    <th>โรงเรียน</th>
                    <th>ปีการศึกษาที่จบ</th>
                    <th>จังหวัด</th>
                    <th>เบอร์ติดต่อ</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: student.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    return (
                      <tr key={i}>
                        <td>
                          <Link to={`/students/${student._id}`}>{student.citizen_id}</Link>
                        </td>

                        <td>
                          {student.first_name} {student.last_name}
                        </td>
                        <td>{student.school_name}</td>
                        <td>{student.school_year}</td>
                        <td>{student.school_province_name}</td>
                        <td>{student.telephone}</td>
                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={studentCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
