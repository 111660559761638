module.exports = [
  {
    value: '1',
    label: 'ผู้สมัคร',
  },
  {
    value: '2',
    label: 'ผ่านการคัดเลือก',
  },
  {
    value: '3',
    label: 'ยืนยันสิทธิ์',
  },
  {
    value: '4',
    label: 'ยืนยันที่อื่นแล้ว',
  },
  {
    value: '5',
    label: 'ไม่ใช้สิทธิ์',
  },
  {
    value: '6',
    label: 'สละสิทธิ์',
  },
  {
    value: '7',
    label: 'ไม่ผ่านสัมภาษณ์',
  },
  {
    value: '8',
    label: 'ไม่ผ่านการคัดเลือก',
  },
  {
    value: '9',
    label: 'ผ่านการคัดเลือกในลำดับที่ดีกว่า',
  },
  {
    value: '10',
    label: 'ไม่เข้าระบบมาดำเนินการใดๆ',
  },
];
