import React, { useState, useEffect } from 'react';

// Helpers
import callApi from 'helpers/callapi';

// Components
import RoundRow from './RoundRow';
import EditModal from './EditModal';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';

const Rounds = ({ university, setUniversity }) => {
  const [editedRoundIndex, setEditedRoundIndex] = useState();

  return (
    <section>
      <h1>ข้อมูลการรับสมัคร</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            ทางสถาบันสามารถเพิ่มข้อมูลทั้งชื่อกำหนดการ วันที่ และไฟล์ข้อมูลแต่ละรอบได้ กำหนดการต่างๆ
            จะนำไปแจ้งเตือนผู้สมัครที่ติดตามหลักสูตรของมหาวิทยาลัย ส่วนไฟล์ข้อมูลแต่ละรอบ
            จะนำไปแสดงในหน้าข้อมูลของมหาวิทยาลัยที่เว็บไซต์{' '}
            <a href="https://www.mytcas.com" target="_blank">
              <u>myTCAS.com</u>
            </a>
          </p>
        </div>
      </div>

      <ul className="sec-admissions">
        {Array(5)
          .fill()
          .map((_, index) => (
            <RoundRow
              key={index}
              roundIndex={index + 1}
              university={university}
              onEdit={(roundIndex) => setEditedRoundIndex(roundIndex)}
            />
          ))}
      </ul>

      {/* 02.admissions - Modal */}
      <EditModal
        roundInfo={_.get(university, `round_${editedRoundIndex}`)}
        roundIndex={editedRoundIndex}
        setUniversity={setUniversity}
        onUpdateRound={(data) =>
          setUniversity((_university) => ({
            ..._university,
            [`round_${editedRoundIndex}`]: data,
          }))
        }
        onCancel={() => setEditedRoundIndex()}
      />
    </section>
  );
};

export default Rounds;
