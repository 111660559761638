import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  message,
  Input,
  Radio,
} from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AddModal from 'containers/Subject/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const me = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [selectedSubject, setSelectedSubject] = useState();

  const [status, setStatus] = useState('all');

  // const [creditCount, setCreditCount] = useState()
  const fetchSubjects = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1 };

      const { count, data: subjects } = await callApi({
        url: '/subjects',
        query,
      });

      setSubjects(subjects || []);

      setLoading(false);
    } catch (error) {
      console.log();
      // console.error('Error while trying to fetch subjects.', error);
      // message.error(error.message);
      setSubjects([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <div>
      <div className="t-main">
        <h1 className="t-title">
          <span>ข้อมูลรายวิชาสอบ</span>
          <span className="action">
            {get(me, 'role') === 'admin' && (
              <a
                type="primary"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setSelectedSubject({});
                  setAddModalVisible(true);
                }}
              >
                <PlusOutlined /> เพิ่มข้อมูลรายวิชาสอบ
              </a>
            )}
          </span>
        </h1>

        {/* <div className="t-form">
          <h2>ค้นหาข้อมูลนักเรียน</h2>
          <div className="col-1">
            <label>
              รหัสวิชา
              <input type="text" />
            </label>
          </div>
          <div className="col-1">
            <label>
              คำอธิบาย
              <input type="text" />
            </label>
          </div>
          <div className="col-1">
            <label>
              คะแนนเต็ม
              <input type="text" />
            </label>
          </div>
          <div className="col-1">
            <label>
              เลขบัตรประชาชน
              <input type="text" />
            </label>
          </div>
          <div className="col-1">
            <label>
              <br />
              <button>ค้นหาข้อมูล</button>
            </label>
          </div>
        </div> */}

        <table className="t-table">
          <thead>
            <tr>
              <th>รหัสวิชาสอบ</th>
              <th>คำอธิบาย</th>
              <th>คะแนนเต็ม</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject) => {
              return (
                <tr>
                  <td>
                    {get(me, 'role') === 'admin' ? (
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedSubject(subject);
                          setAddModalVisible(true);
                        }}
                      >
                        {subject.key}
                      </a>
                    ) : (
                      subject.key
                    )}
                  </td>
                  <td>{subject.label}</td>
                  <td>{subject.max_value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* <ul className="t-paging">
          <li>
            <a href="#">←</a>
          </li>
          <li className="active">
            <a href="#">1</a>
          </li>

          <li>
            <a href="#">→</a>
          </li>
        </ul> */}
      </div>
      {addModalVisible && (
        <AddModal
          setIsVisible={setAddModalVisible}
          isVisible={addModalVisible}
          onClose={() => fetchSubjects()}
          selectedSubject={selectedSubject}
        ></AddModal>
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
