import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TCAS_STATUSES from 'constants/tcas_statuses';
// Components

import { Button, Table, Pagination, Modal, Row, Col, Spin, Select, Form, message, Input, Radio } from 'antd';

const Option = Select.Option;

moment.locale('th');

const applicant_statuses = [
  { value: '1', label: 'ผู้สมัคร', tcas_status: '1' },
  { value: '2', label: 'ผ่านการคัดเลือก', tcas_status: '2' },
  { value: '3', label: 'ไม่ผ่านการคัดเลือก', tcas_status: '8' },
];

export default ({ onClose, setIsVisible, isVisible, enrollmentOffer }) => {
  const [loading, setLoading] = useState(false);
  const [applicantStatus, setApplicantStatus] = useState(enrollmentOffer.applicant_status);

  const updaetEnrollmentOffer = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        applicantStatus,
      };

      await callApi({
        url: `/enrollment-offers/${enrollmentOffer._id}`,
        method: 'put',
        body: requestBody,
      });
      setLoading(false);
      if (onClose) {
        onClose();
      }
      message.success('แก้ไขข้อมูลการรับสมัครสำเร็จ');
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };

  const _tcasStatus = _.find(applicant_statuses, { value: applicantStatus }).tcas_status;
  const tcasStatus = _.find(TCAS_STATUSES, { value: _tcasStatus });

  return (
    <div>
      <Modal
        title={`แก้ไขผลการรับสมัคร`}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        width={800}
        footer={[
          <Button key="back" onClick={() => setIsVisible(false)}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => updaetEnrollmentOffer()}>
            ยืนยัน
          </Button>,
        ]}
      >
        <Spin spinning={loading} delay={0}>
          <div className="t-main">
            <div>
              <div className="sec-form">
                <div className="col-25">สถานะการรับสมัคร (applicant_status)</div>
                <div className="col-75">
                  <select onChange={(e) => setApplicantStatus(e.target.value)} value={applicantStatus}>
                    {applicant_statuses.map((status) => {
                      return <option value={status.value}>{status.label}</option>;
                    })}
                  </select>
                </div>

                <div className="col-25">
                  สถานะในระบบ<br></br> (tcas_status)
                </div>
                <div className="col-75">
                  {tcasStatus.value} ({tcasStatus.label})
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
