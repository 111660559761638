const rules = [
  {
    errorMessage: 'มีความยาวอย่างน้อย 8 ตัว',
    regEx: '(?=.{8,})',
  },
  {
    errorMessage: 'มีตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว',
    regEx: '(?=.*[A-Z])',
  },
  {
    errorMessage: 'มีตัวพิมพ์เล็กอย่างน้อย 1 ตัว',
    regEx: '(?=.*[a-z])',
  },
  {
    errorMessage: 'มีตัวเลขอย่างน้อย 1 ตัว',
    regEx: '(?=.*[0-9])',
  },
  // {
  //   errorMessage: 'มีอักขระพิเศษ !@#$%^&*()\-__+. อย่างน้อย 1 ตัว',
  //   regEx: '(^A-Za-z0-9)'
  // }
];

const check = (passwordString) => {
  const errors = [];
  rules.forEach((rule) => {
    const regex = new RegExp(rule.regEx);
    if (!regex.test(passwordString)) {
      errors.push({ message: rule.errorMessage, valid: false });
    } else {
      errors.push({ message: rule.errorMessage, valid: true });
    }
  });
  return errors;
};

module.exports = {
  check,
};
