module.exports = {
  labels: {
    GAT: 'ความถนัดทั่วไป (GAT)',
    PAT1: 'ความถนัดทางคณิตศาสตร์ (PAT 1)',
    PAT2: 'ความถนัดทางวิทยาศาสตร์ (PAT 2)',
    PAT3: 'ความถนัดทางวิศวกรรมศาสตร์ (PAT 3)',
    PAT4: 'ความถนัดทางสถาปัตยกรรมศาสตร์ (PAT 4)',
    PAT5: 'ความถนัดทางวิชาชีพครู (PAT 5)',
    PAT6: 'ความถนัดทางศิลปกรรมศาสตร์ (PAT 6)',
    'PAT7.1': 'ความถนัดทางภาษาฝรั่งเศส (PAT 7.1)',
    'PAT7.2': 'ความถนัดทางภาษาเยอรมัน (PAT 7.2)',
    'PAT7.3': 'ความถนัดทางภาษาญี่ปุ่น (PAT 7.3)',
    'PAT7.4': 'ความถนัดทางภาษาจีน (PAT 7.4)',
    'PAT7.5': 'ความถนัดทางภาษาอาหรับ (PAT 7.5)',
    'PAT7.6': 'ความถนัดทางภาษาบาลี (PAT 7.6)',
    'PAT7.7': 'ความถนัดทางภาษาเกาหลี (PAT 7.7)',
    '09': 'วิชาสามัญ ภาษาไทย',
    19: 'วิชาสามัญ สังคมศึกษา',
    29: 'วิชาสามัญ ภาษาอังกฤษ',
    39: 'วิชาสามัญ คณิตศาสตร์ 1',
    49: 'วิชาสามัญ ฟิสิกส์',
    59: 'วิชาสามัญ เคมี',
    69: 'วิชาสามัญ ชีววิทยา',
    89: 'วิชาสามัญ คณิตศาสตร์ 2',
    99: 'วิชาสามัญ วิทยาศาสตร์ทั่วไป',
  },
  scores: {
    GAT: 'gat',
    PAT1: 'pat1',
    PAT2: 'pat2',
    PAT3: 'pat3',
    PAT4: 'pat4',
    PAT5: 'pat5',
    PAT6: 'pat6',
    'PAT7.1': 'pat7_1',
    'PAT7.2': 'pat7_2',
    'PAT7.3': 'pat7_3',
    'PAT7.4': 'pat7_4',
    'PAT7.5': 'pat7_5',
    'PAT7.6': 'pat7_6',
    'PAT7.7': 'pat7_7',
    '09': '9sub_09',
    19: '9sub_19',
    29: '9sub_29',
    39: '9sub_39',
    49: '9sub_49',
    59: '9sub_59',
    69: '9sub_69',
    89: '9sub_89',
    99: '9sub_99',
  },
  index: {
    GAT: 1,
    PAT1: 2,
    PAT2: 3,
    PAT3: 4,
    PAT4: 5,
    PAT5: 6,
    PAT6: 7,
    PAT7: 8,
    'PAT7.1': 9,
    'PAT7.2': 10,
    'PAT7.3': 11,
    'PAT7.4': 12,
    'PAT7.5': 13,
    'PAT7.6': 14,
    'PAT7.7': 15,
    '09': 16,
    19: 17,
    29: 18,
    39: 19,
    49: 20,
    59: 21,
    69: 22,
    89: 23,
    99: 24,
  },
  maxScore: {
    GAT: 300,
    PAT1: 300,
    PAT2: 300,
    PAT3: 300,
    PAT4: 300,
    PAT5: 300,
    PAT6: 300,
    PAT7: 300,
    'PAT7.1': 300,
    'PAT7.2': 300,
    'PAT7.3': 300,
    'PAT7.4': 300,
    'PAT7.5': 300,
    'PAT7.6': 300,
    'PAT7.7': 300,
    '09': 100,
    19: 100,
    29: 100,
    39: 100,
    49: 100,
    59: 100,
    69: 100,
    89: 100,
    99: 100,
  },
};
