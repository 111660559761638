import React, { useEffect } from 'react';
import { Modal } from 'antd';
import useUpload from './useUpload';

const ImportModal = ({ index, university, onCancel, isVisible, config, setIsVisible }) => {
  const filePath = '';
  const { uploadRef, setSelectedFile, onUpload, uploading, uploadProgress, importErrors } = useUpload(config);

  // useEffect(() => onErrorsChange(importErrors), [importErrors]);

  return (
    <Modal
      visible={isVisible}
      onCancel={() => {
        setIsVisible(false);
      }}
      width={900}
      footer={null}
    >
      <h2>นำเข้าข้อมูลการตัดสิทธิ์</h2>
      <br />
      {Boolean(importErrors.length) && (
        <div className="sec-error">
          <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ ทั้งหมด {importErrors.length} แถว</p>
          <ul>
            {importErrors.map(({ index, message }, _idx) => (
              <li key={_idx}>
                แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
              </li>
            ))}
          </ul>
        </div>
      )}

      {!filePath ? (
        <p>เลือกไฟล์ที่ต้องการนำเข้า แล้วคลิกปุ่มนำเข้าข้อมูล</p>
      ) : (
        <p>เลือกไฟล์ที่ต้องการนำเข้า ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์นี้ไปแทนที่ไฟล์เก่า</p>
      )}

      {filePath && (
        <p className="s-flex file">
          <i className="i-course"></i> {filePath.split('/').pop()}
        </p>
      )}
      <div className="sec-form">
        <div className="col-75">
          <input type="file" accept=".csv" ref={uploadRef} onChange={(e) => setSelectedFile(e.target.files[0])} />
          <div className={uploadProgress ? 't-progress active-animation' : 't-progress'}>
            <b />
          </div>
        </div>
        <div className="col-25">
          <a className="btn-main" onClick={() => onUpload(() => onCancel())}>
            {uploading ? (
              <span>กำลังนำเข้าข้อมูล</span>
            ) : (
              <span>
                <i className="i-upload" /> นำเข้าข้อมูล
              </span>
            )}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
