import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, map, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';
import ProcessModal from './ProcessModal';

import EXAM_SUBJECTS from 'constants/exam-subjects';

let EXAM_SUBJECT_LABELS = _.map(EXAM_SUBJECTS.labels, (value, key) => ({
  key,
  label: value,
  index: EXAM_SUBJECTS.index[key],
}));
EXAM_SUBJECT_LABELS = _.orderBy(EXAM_SUBJECT_LABELS, 'index');

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

const STATUSES = [
  { label: 'ทั้งหมด', value: 'all' },
  { label: 'ยังไม่อ่าน', value: 'pending' },
  { label: 'อ่านแล้ว', value: 'processed' },
];

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [selectedApp, setSelectedApp] = useState({});
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [examApplications, setExamApplications] = useState([]);
  const [appCount, setappCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    status: 'pending',
    subject: 'all',
  });

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchapps = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: examApplications } = await callApi({
        url: '/exams/enquiries',
        query,
      });
      setExamApplications(examApplications || []);
      setappCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setExamApplications([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      fetchSubjects();
    }
  }, [me]);
  useEffect(() => {
    fetchapps(1);
  }, []);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchapps(_page);
  };

  // if (me) {
  //   currentCourses = courses[me.university_id];
  // }
  const onStatusChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      status: value,
    });
  };

  const onSubjectChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      subject: value,
    });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchapps(1);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>ข้อมูลคำร้อง</h1>

          <div className="sec-box">
            <h2>ค้นหาข้อมูลคำร้อง</h2>
            <div className="sec-form">
              <div className="col-40">
                <label>รหัสที่นั่งสอบ, รหัสบัตรประชาชน</label>
                <div className="ant-text">
                  <Input
                    value={get(filterQuery, 'q')}
                    onChange={(e) =>
                      setFilterQuery({
                        ...filterQuery,
                        q: e.target.value,
                      })
                    }
                    onPressEnter={onSearch}
                    type="text"
                  />
                </div>
              </div>

              <div className="col-20">
                <label>
                  วิชา
                  <div className="select-container">
                    {/* อย่าลืมแก้ตรงนี้นะคร้าบ */}
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onSubjectChange}
                      value={get(filterQuery, 'subject')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                        ทั้งหมด
                      </Option>
                      {_.map(EXAM_SUBJECT_LABELS, (EXAM_SUBJECT_LABEL, key) => {
                        return (
                          <Option key={key} value={EXAM_SUBJECT_LABEL.key} label={EXAM_SUBJECT_LABEL.label}>
                            {EXAM_SUBJECT_LABEL.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20">
                <label>
                  สถานะคำร้อง
                  <div className="select-container">
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onStatusChange}
                      value={get(filterQuery, 'status')}
                      filterOption={filterLabelAndValue}
                    >
                      {STATUSES.map((STATUS) => (
                        <Option key={STATUS.label} value={STATUS.value} label={STATUS.label}>
                          {STATUS.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchapps(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {examApplications.length === 0 && (
            <table className="t-table -examApplications">
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลเงื่อนไขการรับสมัครในระบบ</th>
                </tr>
              </tbody>
            </table>
          )}
          {examApplications.length > 0 && (
            <>
              <table className="t-table -examApplications">
                <thead>
                  <tr>
                    <th>หมายเลขที่นั่ง</th>
                    <th>รหัสบัตรประชาชน</th>
                    <th>ชื่อ นามสกุล</th>
                    <th>วิชา</th>
                    <th>สถานะ</th>
                    <th>เวลาสร้าง</th>
                  </tr>
                </thead>
                <tbody>
                  {examApplications.map((app, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: app.program_id,
                    // });
                    // const university = find(universities, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    // เพิ่ม subject
                    // ส่งอีเมลล์ ที่ app
                    // app request สร้างโรงเรียน

                    const ic = 'i-' + app.status;

                    return (
                      <tr key={i}>
                        <td>
                          <a
                            onClick={() => {
                              setSelectedApp(app);
                              setProcessModalVisible(true);
                            }}
                          >
                            {app.app_id}
                          </a>
                        </td>
                        <td>{app.citizen_id}</td>

                        <td>
                          <span>
                            {get(app, 'student.first_name')} {get(app, 'student.last_name')}
                          </span>
                        </td>
                        <td>{EXAM_SUBJECTS.labels[app.subject_name]}</td>

                        <td>
                          <div className="s-flex">
                            {app.acknowledged === true && 'รับเรื่องแล้ว'}
                            {app.acknowledged !== true && 'รอการตรวจสอบ'}

                            {app.acknowledged === true && (
                              <i>
                                &nbsp;
                                <small>
                                  {app.acknowledged_at &&
                                    moment(app.acknowledged_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                                  {app.acknowledged_by && `โดย ${get(app, 'acknowledged_by.name')}`}
                                </small>
                              </i>
                            )}
                          </div>
                        </td>
                        <td>{moment(app.rechecked_at).add('543', 'year').format('D MMM HH:mm น.')}</td>
                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={40}
                total={appCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>

      {processModalVisible && (
        <ProcessModal
          isVisible={processModalVisible}
          setIsVisible={setProcessModalVisible}
          examApplication={selectedApp}
          onClose={() => {
            fetchapps();
            setSelectedApp({});
            setProcessModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
