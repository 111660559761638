import styled from 'styled-components';

export default styled.h1`
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;

  a {
    color: ${({ theme }) => theme.colors.black};
    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  > .anticon-right > svg {
    font-size: 20px;
    margin: 0px 10px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ContentHeadWrapper = styled.div`
  position: relative;
`;
