import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components

import { Button, Table, Collapse, Modal, Popconfirm, Row, Col, Spin, Select, Form, message, Input, Radio } from 'antd';
const { Panel } = Collapse;

const Option = Select.Option;

moment.locale('th');

const SEVERITIES = {
  infected: 'เป็นผู้ติดเชื้อ',
  high: 'เป็นผู้มีความเสี่ยงสูง',
};
export default ({ onClose, data, setIsVisible, isVisible, covidSites }) => {
  const application = data.applications[0];
  const [loading, setLoading] = useState(false);
  const [siteCode, setSiteCode] = useState(application.selected_covid_place);
  const [severity, setSeverity] = useState(application.severity);
  const [accommodated, setAccommodated] = useState(application.accommodated);

  const student = data.student;

  const subjects = data.applications.map((app) => app.subject_name).join(', ');

  const updateApplication = async (values) => {
    try {
      setLoading(true);

      const selectedSite = covidSites[siteCode];

      const requestBody = {
        siteName: selectedSite.site_name,
        siteCode,
        severity,
        accommodated,
      };

      await callApi({
        url: `/students/${student._id}/covid`,
        method: 'put',
        body: requestBody,
      });
      setLoading(false);
      if (onClose) {
        onClose();
      }
      message.success('แก้ไขข้อมูลสำเร็จ');
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };

  const deleteApplication = async (values) => {
    try {
      setLoading(true);

      await callApi({
        url: `/students/${student._id}/covid`,
        method: 'delete',
      });
      setLoading(false);
      if (onClose) {
        onClose();
      }
      message.success('แก้ไขข้อมูลสำเร็จ');
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={`${student.citizen_id}: ${student.title} ${student.first_name} ${student.last_name}`}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        width={800}
        footer={[
          <Button key="back" onClick={() => setIsVisible(false)}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => updateApplication()}>
            ยืนยัน
          </Button>,

          <Popconfirm
            onConfirm={() => {
              deleteApplication();
            }}
            title={'คุณต้องการจะลบผู้ใช้คนนี้ออกจากระบบ'}
            okText="ใช่"
            cancelText="ไม่ใช่"
          >
            <Button key="delete" type="danger" loading={loading} style={{ float: 'left' }}>
              ยกเลิกการสมัครสอบโควิด
            </Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={loading} delay={0}>
          <div className="t-main">
            <div>
              <div className="sec-form">
                <div className="col-25">วิชา</div>
                <div className="col-75">{subjects}</div>
                <div className="col-25">สถานที่ </div>
                <div className="col-75">
                  <select onChange={(e) => setSiteCode(e.target.value)} value={siteCode}>
                    {_.map(covidSites, (site) => {
                      return <option value={site.site_code}>{site.site_name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-25">อาการ</div>
                <div className="col-75">
                  <select onChange={(e) => setSeverity(e.target.value)} value={severity}>
                    {_.map(SEVERITIES, (severity, key) => {
                      return <option value={key}>{severity}</option>;
                    })}
                  </select>
                </div>

                <div className="col-25">ต้องการที่พัก</div>
                <div className="col-75">
                  <select onChange={(e) => setAccommodated(e.target.value)} value={accommodated}>
                    <option value={0}>ไม่ต้องการ</option>
                    <option value={1}>ต้องการ</option>
                  </select>
                </div>

                <div className="col-25">หมายเหตุ</div>
                <div className="col-75">{application.remark}</div>

                <div className="col-100">
                  <Collapse ghost>
                    <Panel header="หลักฐาน" key="1">
                      <img src={application.evidence_path}></img>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
