import React, { useEffect } from 'react';
import { Modal } from 'antd';
import useUpload from './useUpload';

const ImportModal = ({ index, university, onCancel, onUniversityUpdate, onErrorsChange, config = {} }) => {
  const {
    title,
    filePath,
    currentUniversity,

    uploadRef,
    uploadCount,
    selectedFile,
    setSelectedFile,

    onUpload,
    uploading,
    uploadProgress,

    importErrors,
  } = useUpload(university, index, config);

  useEffect(() => onUniversityUpdate(currentUniversity), [currentUniversity]);
  useEffect(() => onErrorsChange(importErrors), [importErrors]);

  if (!index) return null;
  return (
    <Modal visible={Boolean(index)} onCancel={onCancel} width={720} footer={null}>
      <h2>{title}</h2>
      <br />
      {uploadCount < index ? (
        <p>เลือกไฟล์ที่ต้องการนำเข้า แล้วคลิกปุ่มนำเข้าข้อมูล</p>
      ) : config.type === 'update' ? (
        <p>
          เลือกไฟล์ที่ต้องการนำเข้า ไฟล์ที่นำเข้ามีข้อมูลเฉพาะที่ต้องการปรับปรุง <br />
          ระบบจะเพิ่ม หรือปรับปรุงจากไฟล์หลักสูตรที่ทางสถาบันได้นำเข้าไว้แล้ว
        </p>
      ) : (
        <p>เลือกไฟล์ที่ต้องการนำเข้า ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์นี้ไปแทนที่ไฟล์เก่า</p>
      )}

      {uploadCount > index && config.type !== 'update' && (
        <p>
          <div>หากมีการแก้ไขข้อมูล{index === 1 ? 'เนื้อหาหลักสูตร' : 'เงื่อนไขการรับ'}</div>
          <div>ท่านต้องเพิ่ม{index === 1 ? 'เงื่อนไขการรับและ' : ''}การคำนวณคะแนนใหม่</div>
        </p>
      )}
      {filePath && (
        <p className="s-flex file">
          <i className="i-course"></i> {filePath.split('/').pop()}
        </p>
      )}
      <div className="sec-form">
        <div className="col-75">
          <input type="file" accept=".csv" ref={uploadRef} onChange={(e) => setSelectedFile(e.target.files[0])} />
          <div className={uploadProgress ? 't-progress active-animation' : 't-progress'}>
            <b />
          </div>
        </div>
        <div className="col-25">
          <a className="btn-main" onClick={() => onUpload(() => onCancel())}>
            {uploading ? (
              <span>กำลังนำเข้าข้อมูล</span>
            ) : (
              <span>
                <i className="i-upload" /> นำเข้าข้อมูล
              </span>
            )}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
