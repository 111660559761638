import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';

import RawListView from './RawListView';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

moment.locale('th');

export default () => {
  return (
    <RawListView
      getStudentFromSource={(_query) => {
        return callApi({
          url: '/raws/obec64',
          query: _query,
        });
      }}
      searchLabel="ค้นจากชื่อ, นามสกุล, รหัสประจำตัว"
    />
  );
};
