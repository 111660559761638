import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, isNumber, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import postalCodes from 'constants/postal_codes.json';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Form, message, Input, InputNumber, Popconfirm } from 'antd';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const postalCodeMap = _.keyBy(postalCodes, 'zipCode');

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ selectedSchool = {}, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const universities = useSelector((state) => state.universities || []);

  const [province, setProvince] = useState({});
  const [tumbol, setTumbol] = useState({});
  const [amph, setAmph] = useState({});
  const [zipCode, setZipCode] = useState('');

  console.log(tumbol);

  const createSchool = async (values) => {
    try {
      setLoading(true);
      console.log(province, tumbol, amph);

      const {
        provinceList = [],
        districtList = [],
        subDistrictList = [],
      } = postalCodeMap[zipCode || selectedSchool.zipcode] || {};
      const provinceObj = find(provinceList, { provinceId: province });
      const amphObj = find(districtList, { districtId: amph });
      const tumbolObj = find(subDistrictList, { subDistrictId: tumbol });

      const requestBody = {
        name: values.name,
        code: values.code,
        province_id: provinceObj.provinceId,
        province_name: provinceObj.provinceName,
        amph_id: amphObj.districtId,
        amph_name: amphObj.districtName,
        tmbl_id: tumbolObj.subDistrictId,
        tmbl_name: tumbolObj.subDistrictName,
        zipcode: values.zipcode,
      };

      let reqParams = {
        url: `/schools`,
        method: 'post',
        body: requestBody,
      };
      if (selectedSchool._id) {
        reqParams = {
          url: `/schools/${selectedSchool._id}`,
          method: 'put',
          body: requestBody,
        };
      }

      await callApi(reqParams);

      setLoading(false);
      setIsVisible(false);
      onClose();
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    await callApi({
      url: `/users/${selectedSchool._id}`,
      method: 'delete',
    });
    setLoading(false);
    setIsVisible(false);
    onClose();
  };
  useEffect(() => {
    let { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[zipCode] || {};

    setProvince(_.get(provinceList, '0.provinceId'));

    setAmph(_.get(districtList, '0.districtId'));

    subDistrictList = filter(subDistrictList, { districtId: _.get(districtList, '0.districtId') });
    setTumbol(_.get(subDistrictList, '0.subDistrictId'));
    // }
  }, [zipCode]);

  useEffect(() => {
    if (selectedSchool._id) {
      let { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[selectedSchool.zipcode] || {};
      setProvince(selectedSchool.province_id);
      setAmph(selectedSchool.amph_id);
      subDistrictList = filter(subDistrictList, { districtId: selectedSchool.amph_id });
      setTumbol(selectedSchool.tmbl_id);
    }
  }, [selectedSchool]);

  const onFormChange = (value, key) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({ [key]: value });
  };

  useEffect(() => {
    onFormChange(province, 'province_id');
  }, [province]);
  useEffect(() => {
    onFormChange(amph, 'amph_id');
  }, [amph]);
  useEffect(() => {
    onFormChange(tumbol, 'tmbl_id');
  }, [tumbol]);

  const {
    provinceList = [],
    districtList = [],
    subDistrictList = [],
  } = postalCodeMap[zipCode || selectedSchool.zipcode] || {};
  const filterDistrictList = filter(districtList, { provinceId: province });
  const filterSubdistrictList = filter(subDistrictList, { districtId: amph });
  console.log(selectedSchool, filterDistrictList, provinceList, districtList);

  return (
    <Modal
      title={`เพิ่มโรงเรียน`}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {selectedSchool._id ? 'แก้ไขโรงเรียน' : 'สร้างโรงเรียน'}
        </Button>,
        selectedSchool.xxxxx ? (
          <Popconfirm
            onConfirm={() => {
              deleteUser();
            }}
            title={'คุณต้องการจะลบผู้ใช้คนนี้ออกจากระบบ'}
            okText="ใช่"
            cancelText="ไม่ใช่"
          >
            <Button key="delete" type="danger" loading={loading} style={{ float: 'left' }}>
              ลบโรงเรียน
            </Button>
          </Popconfirm>
        ) : (
          <span></span>
        ),
      ]}
      onCancel={() => setIsVisible(false)}
    >
      <Form {...layout} form={form} name="control-hooks" className="sec-form" onFinish={createSchool}>
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
          <Col span={16}>
            <Form.Item
              label="รหัสโรงเรียน"
              name="code"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ รหัสโรงเรียน' }]}
              initialValue={selectedSchool.code}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="ชื่อโรงเรียน"
              name="name"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ ชื่อโรงเรียน' }]}
              initialValue={selectedSchool.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="รหัสไปรษณีย์"
              name="zipcode"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ รหัสไปรษณีย์' }]}
              initialValue={selectedSchool.zipcode}
            >
              <Input
                onChange={(e) => {
                  const value = e.target.value;
                  if (!Number(value)) {
                    return;
                  }
                  setZipCode(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="จังหวัด"
              name="province_id"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ จังหวัด' }]}
              initialValue={selectedSchool.province_id}
              value={province}
            >
              <select
                onChange={(e) => {
                  setProvince(e.target.value);
                  const _districtList = filter(districtList, { provinceId: e.target.value });
                  const _amph = _.get(_districtList, '0.districtId');
                  setAmph(_amph);
                  const _subdistrictList = filter(subDistrictList, { districtId: _amph });
                  setTumbol(_.get(_subdistrictList, '0.subDistrictId'));
                }}
                disabled={provinceList.length <= 1}
              >
                {provinceList.map((PROVINCE) => {
                  return (
                    <option value={PROVINCE.provinceId} key={PROVINCE.provinceId} name={PROVINCE.provinceName}>
                      {PROVINCE.provinceName}
                    </option>
                  );
                })}
              </select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="อำเภอ"
              name="amph_id"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ อำเภอ' }]}
              initialValue={selectedSchool.amph_id}
            >
              <select
                onChange={(e) => {
                  console.log(e.target.value);
                  setAmph(e.target.value);
                  const _subdistrictList = filter(subDistrictList, { districtId: e.target.value });
                  setTumbol(_.get(_subdistrictList, '0.subDistrictId'));
                }}
                disabled={filterDistrictList.length <= 1}
              >
                {filterDistrictList &&
                  filterDistrictList.map((AMPHURE) => {
                    return (
                      <option value={AMPHURE.districtId} key={AMPHURE.districtId} name={AMPHURE.districtName}>
                        {AMPHURE.districtName}
                      </option>
                    );
                  })}
              </select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="ตำบล"
              name="tmbl_id"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ ตำบล' }]}
              initialValue={selectedSchool.tmbl_id}
            >
              <select
                onChange={(e) => {
                  setTumbol(e.target.value);
                }}
                disabled={filterSubdistrictList.length <= 1}
                i
              >
                {filterSubdistrictList &&
                  filterSubdistrictList.map((TUMBOl) => {
                    return (
                      <option value={TUMBOl.subDistrictId} key={TUMBOl.subDistrictId} name={TUMBOl.subDistrictName}>
                        {TUMBOl.subDistrictName}
                      </option>
                    );
                  })}
              </select>
            </Form.Item>
          </Col>
        </Row>

        <div></div>
      </Form>
    </Modal>
  );
};
