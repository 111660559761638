import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

import AddModal from 'containers/School/AddModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [schools, setSchools] = useState([]);
  const [schoolCount, setSchoolCount] = useState(0);
  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});

  const [filterQuery, setFilterQuery] = useState({
    q: '',
  });

  const fetchSchool = async () => {
    try {
      const result = await callApi({
        url: '/schools',
      });
    } catch (error) {
      console.log('errror', error);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchSchools = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: schools } = await callApi({
        url: '/schools',
        query,
      });
      setSchools(schools);
      setSchoolCount(count);

      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setSchools([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchools(1, {});
  }, []);

  useEffect(() => {
    if (me) {
      // setFilterQuery({ ...filterQuery, university: me.university_id || '001' });
      // fetchSchools();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchSchools(_page);
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchSchools(1);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            ข้อมูลโรงเรียน
            <div style={{ float: 'right' }}>
              {(get(me, 'role') === 'senior' || get(me, 'role') === 'super') && (
                <span className="action">
                  <a
                    className="btn-mini"
                    onClick={() => {
                      setSelectedSchool({});
                      setAddModalVisible(true);
                    }}
                  >
                    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                      <path
                        d="m17 2c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm18 22c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm-28.7773438 2c-2.052 0-3.7226562 1.784516-3.7226562 3.978516v1.521484c0 3.281 1.6953906 6.132344 4.7753906 8.027344 2.591 1.595 6.0446094 2.472656 9.7246094 2.472656 2.19 0 4.431516-.324234 6.478516-.990234-.94-1.799-1.478516-3.839766-1.478516-6.009766 0-3.496 1.386813-6.664 3.632812-9zm28.7773438 1c.552 0 1 .448 1 1v6h6c.552 0 1 .448 1 1s-.448 1-1 1h-6v6c0 .552-.448 1-1 1s-1-.448-1-1v-6h-6c-.552 0-1-.448-1-1s.448-1 1-1h6v-6c0-.552.448-1 1-1z"
                        fill="currentColor"
                      />
                    </svg>
                    เพิ่มโรงเรียน
                  </a>
                </span>
              )}

              {/* <span className="action">
                <a className="btn-mini" onClick={() => exportRanking()}>
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                    <path
                      d="m17 2c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm18 22c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm-28.7773438 2c-2.052 0-3.7226562 1.784516-3.7226562 3.978516v1.521484c0 3.281 1.6953906 6.132344 4.7753906 8.027344 2.591 1.595 6.0446094 2.472656 9.7246094 2.472656 2.19 0 4.431516-.324234 6.478516-.990234-.94-1.799-1.478516-3.839766-1.478516-6.009766 0-3.496 1.386813-6.664 3.632812-9zm28.7773438 1c.552 0 1 .448 1 1v6h6c.552 0 1 .448 1 1s-.448 1-1 1h-6v6c0 .552-.448 1-1 1s-1-.448-1-1v-6h-6c-.552 0-1-.448-1-1s.448-1 1-1h6v-6c0-.552.448-1 1-1z"
                      fill="currentColor"
                    />
                  </svg>
                  Export
                </a>
              </span> */}
            </div>
          </h1>

          <div className="sec-box">
            <h2>ค้นหานักเรียน</h2>
            <div className="sec-form">
              <div className="col-50">
                <label>ชื่อโรงเรียน, ชื่อจังหวัด, รหัสโรงเรียน</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchSchools(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {schools.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลโรงเรียน</th>
                </tr>
              </tbody>
            </table>
          )}
          {schools.length > 0 && (
            <>
              <table className="t-table -schools">
                <thead>
                  <tr>
                    <th>รหัสโรงเรียน</th>
                    <th>ชื่อโรงเรียน</th>
                    <th>จังหวัด</th>
                    <th>ที่อยู่</th>
                  </tr>
                </thead>
                <tbody>
                  {schools.map((school, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: school.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    return (
                      <tr key={i}>
                        <td>
                          <a
                            onClick={() => {
                              console.log(school);
                              setSelectedSchool(school);
                              setAddModalVisible(true);
                            }}
                          >
                            {' '}
                            {school.code}
                          </a>

                          {/* <Link to={`/schools/${school._id}`}>{school.code}</Link> */}
                        </td>

                        <td>{school.name}</td>
                        <td>{school.province_name}</td>
                        <td>
                          {school.tmbl_name} {school.amph_name} {school.province_name} {school.zipcode}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={schoolCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>
      {addModalVisible && (
        <AddModal
          setIsVisible={setAddModalVisible}
          onClose={() => {
            fetchSchools();
            setAddModalVisible(false);
          }}
          isVisible={addModalVisible}
          selectedSchool={selectedSchool}
        ></AddModal>
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
