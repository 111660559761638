import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import moment from 'moment';

import callapi from 'helpers/callapi';

const FileModal = ({ setIsVisible, isVisible, url }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await callapi({ url: url });
      setFiles(response.data);
    };
    fetchFiles();
  }, []);

  return (
    <Modal visible={isVisible} onCancel={() => setIsVisible(false)} width={720} footer={null}>
      {files.map((file) => {
        return (
          <div className="file">
            <a href={file.link}>
              <i className="i-course"></i>
              <b>{file.link.split('/').pop()}</b>
              <div>
                <small>{moment(file.created_at).add(543, 'years').format('D MMM YY HH:mm น.')} </small>
              </div>
              <div>
                <small>{file.description} </small>
              </div>
            </a>
          </div>
        );
      })}
    </Modal>
  );
};

export default FileModal;
