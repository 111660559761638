import React, { useState, useEffect, useRef } from 'react';
import callApi from 'helpers/callapi';
import { message } from 'antd';

const ISUPDATE = true;
const useUpload = (config = { type: 'import', onUploadDone: () => {} }) => {
  const uploadRef = useRef();

  const [importErrors, setImportErrors] = useState([]);

  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState();
  const onUpload = async (done) => {
    if (uploading || !selectedFile) return;
    try {
      const data = new FormData();
      data.append('disqualify', selectedFile);

      setUploading(true);
      const result = await callApi({
        method: 'POST',
        url: '/enrollment-offers/import/disqualify-students',
        body: data,
        uploadFile: true,
        onUploadProgress: (data) => setUploadProgress(Math.round((100 * data.loaded) / data.total)),
      });

      setUploadProgress();
      setUploading(false);
      setSelectedFile();
      uploadRef.current.value = '';
      setImportErrors([]);
      config.onUploadDone(result);
      message.success(`อัพโหลดข้อมูลตัดสิทธิ์สำเร็จ`);
      done();
    } catch (error) {
      const importErrors = _.get(error, 'reason.errors') || [];
      if (importErrors.length) {
        setImportErrors(
          importErrors.reduce((rows, { index, messages }) => {
            return rows.concat(messages.map((message) => ({ index, message })));
          }, [])
        );
        setUploadProgress();
        setUploading(false);
      } else {
        setUploadProgress();
        setUploading(false);
      }
    }
  };

  return {
    uploadRef,
    selectedFile,
    setSelectedFile,
    onUpload,
    uploading,
    uploadProgress,
    importErrors,
  };
};

export default useUpload;
