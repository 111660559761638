import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import cn from 'classnames';
import SUBJECTCOLORS from 'constants/subject_colors';
import { map, get } from 'lodash';

export default function ({ scores }) {
  // const [phone, setPhone] = useState('+66909607070');
  // const [confirmResult, setConfirmResult] = useState(null);

  return (
    <div className="t-scores">
      {scores &&
        map(scores, (value, key) => {
          if (key === 'cal_type' || key === 'cal_subject_name') return null;
          return (
            <b
              key={key}
              className={key}
              style={{
                width: value + '%',
                backgroundColor: get(SUBJECTCOLORS, `${key}.color`) || '#000000',
              }}
            ></b>
          );
        })}
    </div>
  );
}
