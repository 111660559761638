export default {
  colors: {
    primary: '#f3e8b5',
    // primary: '#19253a',
    primaryLight: '#bbf0e3',
    secondary: '#eee',
    branding: '#19253a',
    gold: '#d4af37',
    gray: '#e8e8e8',
    red: '#f93524',
    black: '#333',
    success: '#52c41a',
  },
  media: {
    desktop: '768px',
    mobile: '767px',
  },
};
