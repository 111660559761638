import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import { Spin, Select, message, Modal, Input } from 'antd';
import { useLocation, useParams } from 'react-router';
import PasswordHelper from 'helpers/password';

moment.locale('th');

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const query = useQuery();
  const [loading, setLoading] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const [passwordError, setPasswordError] = useState(PasswordHelper.check(''));

  const resetPassword = async (values) => {
    if (!checkAllValidPassword()) {
      return false;
    }
    try {
      setLoading(true);
      console.log(query);

      const requestBody = {
        email: query.get('email'),
        token: query.get('token'),
        password: password,
      };

      await callApi({
        url: `/users/reset-password`,
        method: 'post',
        body: requestBody,
      });

      setLoading(false);
      Modal.success({
        content: 'ระบบได้เปลี่ยนรหัสผ่านให้คุณเรียบร้อย',
        onOk: () => {
          window.location.href = '/credential';
        },
      });

      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };
  const checkAllValidPassword = () => {
    let valid = true;
    passwordError.forEach((error) => (valid = error.valid && valid));
    valid = valid && password === confirmPassword;
    return valid;
  };

  return (
    <div>
      <div>
        <Spin spinning={loading}>
          <div className="t-body">
            <div className="t-login">
              <div className="t-form">
                <div className="text-center">
                  <a href="/">
                    <img src="/img/tcas-logo.png" alt="Logo" width="194" height="45" />
                  </a>
                  <h1>รีเซ็ตรหัสผ่าน</h1>
                </div>

                <div>
                  <label>
                    รหัสผ่านใหม่
                    <Input.Password
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError(PasswordHelper.check(e.target.value));
                      }}
                      value={password}
                      type="password"
                      placeholder="รหัสผ่าน"
                    />
                  </label>
                </div>

                <div>
                  <label>
                    ยืนยันรหัสผ่านใหม่
                    <Input.Password
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      type="password"
                      placeholder="ยืนยันรหัสผ่าน"
                    />
                  </label>
                </div>

                <div>
                  {passwordError.map((error) => {
                    return (
                      <div className="s-flex">
                        <i
                          className={cn({
                            'i-yes': error.valid,
                            'i-no': !error.valid,
                          })}
                        ></i>{' '}
                        {error.message}
                      </div>
                    );
                  })}
                  <div className="s-flex">
                    <i
                      className={cn({
                        'i-yes': password === confirmPassword,
                        'i-no': password !== confirmPassword,
                      })}
                    ></i>{' '}
                    ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน
                  </div>
                </div>

                <div>
                  <button
                    onClick={() => {
                      resetPassword();
                    }}
                    className={cn({
                      'btn-alt': !checkAllValidPassword(),
                    })}
                  >
                    {' '}
                    ยืนยันการเปลี่ยนรหัสผ่าน
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};
