import React from 'react';
import _ from 'lodash';
import useRound from './useRound';
import moment from 'moment';

const RoundRow = ({ university, roundIndex, onEdit }) => {
  const roundInfo = _.get(university, `round_${roundIndex}`);
  const {
    roundName,
    filePath,
    uploadedAt,

    existedKeys,

    registerDateString,
    intervieweeAnnounceDateString,
    intervieweeAnnounceDate2String,
    interviewDateString,
    candidateAnnounceDateString,
    confirmDateString,
    confirmDate2String,
    cancelDateString,
    studentAnnounceDateString,
  } = useRound(roundInfo, roundIndex);

  return (
    <li key={roundIndex} className={`round-${roundIndex}`}>
      <h2>
        <b className="num">
          {roundIndex === 5 ? <i className="i-disabled"></i> : roundIndex}
        </b>
        <span>{roundName}</span>
      </h2>
      <table className="event">
        {existedKeys.length ? (
          <tbody>
            {registerDateString && (
              <tr>
                <td>รับสมัคร</td>
                <td>{registerDateString}</td>
              </tr>
            )}
            {intervieweeAnnounceDateString && (
              <tr>
                <td>
                  ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์
                  {intervieweeAnnounceDate2String && ' ครั้งที่ 1'}
                </td>
                <td>{intervieweeAnnounceDateString}</td>
              </tr>
            )}
            {intervieweeAnnounceDate2String && (
              <tr>
                <td>ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์ ครั้งที่ 2</td>
                <td>{intervieweeAnnounceDate2String}</td>
              </tr>
            )}
            {interviewDateString && (
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน {interviewDateString}</td>
              </tr>
            )}
            {candidateAnnounceDateString && (
              <tr>
                <td>ประกาศรายชื่อผู้ผ่านการคัดเลือก</td>
                <td>{candidateAnnounceDateString}</td>
              </tr>
            )}
            {confirmDateString && (
              <tr>
                <td>ยืนยันสิทธิ TCAS{confirmDate2String && ' ครั้งที่ 1'}</td>
                <td>{confirmDateString}</td>
              </tr>
            )}
            {confirmDate2String && (
              <tr>
                <td>ยืนยันสิทธิ TCAS ครั้งที่ 2</td>
                <td>{confirmDate2String}</td>
              </tr>
            )}
            {cancelDateString && (
              <tr>
                <td>ยกเลิกสิทธิ TCAS</td>
                <td>{cancelDateString}</td>
              </tr>
            )}
            {studentAnnounceDateString && (
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>{studentAnnounceDateString}</td>
              </tr>
            )}
          </tbody>
        ) : (
          <tr>
            <td>ไม่มีข้อมูล</td>
          </tr>
        )}
      </table>
      {filePath ? (
        <div className="file">
          <a href={filePath}>
            <i className="i-book"></i>
            <b>{filePath.split('/').pop()}</b>
            <small>
              {moment(uploadedAt).add(543, 'years').format('D MMM YY HH:mm น.')}
            </small>
          </a>
        </div>
      ) : (
        <div className="file -none">
          <a href="#">
            <i className="i-book"></i>
            <b>ยังไม่มีข้อมูล</b>
          </a>
        </div>
      )}
      <div className="action">
        <a className="btn-main" onClick={() => onEdit(roundIndex)}>
          แก้ไขข้อมูล
        </a>
      </div>
    </li>
  );
};

export default RoundRow;
