import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, Form, message, Input, Radio } from 'antd';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ selectedOrder, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [role, setRole] = useState('user');
  const universities = useSelector((state) => state.universities || []);
  const changePassowrd = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        password: values.password,
        newPassword: values.newPassword,
      };

      await callApi({
        url: `/users/change-password`,

        body: requestBody,
      });

      setLoading(false);
      setIsVisible(false);
      message.success('เปลี่ยนรหัสผ่านสำเร็จ');
    } catch (error) {
      console.error('Error while trying to change password.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg, 1));
      setLoading(false);
    }
  };

  if (!isVisible) return null;
  return (
    <Modal
      title={`แก้ไขรหัสผ่าน`}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          แก้ไขรหัสผ่าน
        </Button>,
      ]}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={changePassowrd}>
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}

          <Col span={16}>
            <Form.Item
              label="รหัสผ่านเดิม"
              name="password"
              hasFeedback
              rules={[{ required: true, message: 'จำเป็นต้องใส่รหัสผ่าน' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              label="รหัสผ่านใหม่"
              name="newPassword"
              hasFeedback
              rules={[{ required: true, message: 'จำเป็นต้องใส่รหัสผ่าน' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              label="ยืนยันรหัสผ่าน"
              name="confirmPassword"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'กรุณายืนยันรหัสผ่าน!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('ยืนยันรหัสผ่านไม่ตรงกัน!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
