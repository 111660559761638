import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// Components
import ImportModal from './ImportModal';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import useImportInfo from './useImportInfo';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { map, get, find } from 'lodash';
import { Button, Popconfirm } from 'antd';
import FIELDS from 'constants/field_labels.json';
import ScoreCalculationBar from 'components/ScoreCalculationBar';
import ComplexSubjectInfo from './ComplexSubjectInfo';
import ReceiveCondition from './ReceiveCondition';
import { EditOutlined } from '@ant-design/icons';
import EditModal from './detail/EditModal';

const ProgramUpdateFields = [
  { label: 'ชื่อหลักสูตร', key: 'program_name_th' },
  { label: 'ภาษาอังกฤษ', key: 'program_name_en' },
  { label: 'ชื่อสาขา', key: 'major_name_th' },
  { label: 'ชื่อสาขาภาษาอังกฤษ', key: 'major_name_en' },
];

const ProgramProjectUpdateFields = [
  { label: 'ชื่อโครงการ', key: 'project_name_th', onlySuperAdminEdit: true },
  { label: 'ภาษาอังกฤษ', key: 'project_name_en', onlySuperAdminEdit: true },
  { label: 'จำนวนที่รับ', key: 'receive_student_number', type: 'number' },
  { label: 'receive_add_limit', key: 'receive_add_limit' },

  { label: 'คำอธิบายโครงการ', key: 'description', onlySuperAdminEdit: true },
  { label: 'เงื่อนไขการรับ', key: 'condition', type: 'textarea' },
  { label: 'สถานที่สัมภาษณ์', key: 'interview_location', type: 'textarea' },
  { label: 'วันที่สัมภาษณ์', key: 'interview_date', type: 'textarea' },
  { label: 'เวลาที่สัมภาษณ์', key: 'interview_time', type: 'textarea' },
];
const programEndPoint = '/programs';
const programProjectEndPoint = '/program-projects';

const ProgramDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector((state) => state.user);
  const [importPrograms, setImportPrograms] = useState([]);
  const { loading, university, setUniversity } = useMyUniversity();
  const [currentIndex, setCurrentIndex] = useState();
  const [importErrors, setImportErrors] = useState([]);
  const [showDeleteProgramProject, setShowDeleteProgramProject] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [program, setProgram] = useState();
  const [programProject, setProgramProject] = useState();
  const [editType, setEditType] = useState('');
  const subjects = useSelector((state) => state.subjects || []);

  let { programId } = useParams();

  const fetchProgramDetail = async () => {
    const result = await callApi({
      url: `/program-projects/${programId}`,
    });
    setProgram(result.program);
    setProgramProject({ ...result.programProject, program_name_th: result.program.program_name_th });
  };

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const deleteProgramProject = async () => {
    await callApi({
      url: `/program-projects/${programProject._id}`,
      method: 'delete',
    });
    history.replace('/programs');
  };

  useEffect(() => {
    fetchProgramDetail();
    fetchSubjects();
  }, []);

  if (!program || !programProject) return null;

  const subjectsObject = subjects.reduce((result, subject) => {
    result[subject.key] = subject;
    return result;
  }, {});
  const dispalyId = (id) => (id === '0' || !id ? '-' : id);

  return (
    <div>
      <h1 className="t-title">
        <div>{program.university_name_th}&nbsp;&nbsp; </div> <span> {program.program_name_th}</span>
        <small className="action">
          รับ <b>{programProject.receive_student_number} คน</b>
        </small>
      </h1>

      {me.role === 'super' && (
        <p className="detail-action">
          <Button
            onClick={() => {
              setEditModalVisible(true);
              setEditType('program');
            }}
            icon={<EditOutlined />}
          >
            แก้ไขหลักสูตร
          </Button>{' '}
          <Button
            onClick={() => {
              setEditModalVisible(true);
              setEditType('programProject');
            }}
            icon={<EditOutlined />}
          >
            แก้ไขโครงการ
          </Button>
        </p>
      )}
      {me.role === 'staff' &&
        (me.university_id === '031' || me.university_id === '218' || me.university_id === '115') &&
        programProject.type === '3_2565' && (
          <p className="detail-action">
            {/* <Button
              onClick={() => {
                setEditModalVisible(true);
                setEditType('programProject');
              }}
              icon={<EditOutlined />}
            >
              แก้ไขโครงการ
            </Button> */}
          </p>
        )}
      <table className="t-table">
        <thead>
          <tr>
            <th>รหัสหลักสูตร</th>
            <th>ชื่อหลักสูตร</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <th>Program</th>
                    <td>{program.program_id || '-'}</td>
                  </tr>
                  <tr>
                    <th>Major</th>
                    <td>{dispalyId(program.major_id)}</td>
                  </tr>
                  <tr>
                    <th>Project</th>
                    <td>{dispalyId(programProject.project_id)}</td>
                  </tr>

                  <tr>
                    <th>Round</th>
                    <td>{programProject.type}</td>
                  </tr>
                  <tr>
                    <th>Join ID</th>
                    <td>{dispalyId(programProject.join_id)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <th>หลักสูตร</th>
                    <td>{program.program_name_th || '-'}</td>
                  </tr>
                  <tr>
                    <th>เมเจอร์</th>
                    <td>{dispalyId(program.major_name_th)}</td>
                  </tr>
                  <tr>
                    <th>โครงการ</th>
                    <td>{dispalyId(programProject.project_name_th)}</td>
                  </tr>

                  <tr>
                    <th>รอบที่</th>
                    <td>{programProject.type.split('_')[0]}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr className="heading">
            <th>เงื่อนไขผู้สมัคร</th>
            <th>เงื่อนไขการรับ</th>
          </tr>
          <tr>
            <td>
              รับผู้สมัครที่จบจาก
              {programProject.only_formal === 1 ? (
                <div>
                  <i className="i-yes"></i> หลักสูตรแกนกลาง
                </div>
              ) : (
                ''
              )}
              {programProject.only_international === 1 ? (
                <div>
                  <i className="i-yes"></i> หลักสูตรนานาชาติ
                </div>
              ) : (
                ''
              )}
              {programProject.only_vocational === 1 ? (
                <div>
                  <i className="i-yes"></i> หลักสูตรอาชีวะ
                </div>
              ) : (
                ''
              )}
              {programProject.only_non_formal === 1 ? (
                <div>
                  <i className="i-yes"></i> หลักสูตรกศน.
                </div>
              ) : (
                ''
              )}
              {programProject.only_ged === 1 ? (
                <div>
                  <i className="i-yes"></i> หลักสูตรGED
                </div>
              ) : (
                ''
              )}
            </td>
            <td>
              <div className="s-flex">
                <div className="col-50">
                  <ReceiveCondition subjects={subjects} programProject={programProject}></ReceiveCondition>
                </div>
                <div className="col-50">
                  {programProject.scores && <ScoreCalculationBar scores={programProject.scores} />}
                  <table>
                    <tbody>
                      {programProject.scores &&
                        map(programProject.scores, (value, key) => {
                          if (key === 'cal_score_sum' || key === 'cal_subject_name' || key === 'cal_type') {
                            return <span />;
                          }
                          return (
                            <tr key={key}>
                              <td>{get(subjectsObject, `${key}.short_label`)}</td>
                              <td>{value}%</td>
                            </tr>
                          );
                        })}
                      {programProject.scores && (
                        <ComplexSubjectInfo subjectDictionary={subjectsObject} scores={programProject.scores} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr className="heading">
            <th colSpan="2">รายละเอียดหลักสูตร</th>
          </tr>
          <tr>
            <td colSpan="2">
              <h2>ข้อมูลรายละเอียด</h2>
              <p>{programProject.description}</p>
              <h2>ข้อมูลเงื่อนไขอื่นๆ</h2>
              <p>{programProject.condition}</p>
              <h2>ข้อมูลการสัมภาษณ์</h2>

              <p>วันที่: {programProject.interview_date || '-'}</p>
              <p>เวลา: {programProject.interview_time || '-'}</p>
              <p>สถานที่: {programProject.interview_location || '-'}</p>
            </td>
          </tr>
        </tbody>
      </table>

      {me.role === 'super' && (
        <div>
          {!showDeleteProgramProject && (
            <p className="_heading">
              <a onClick={() => setShowDeleteProgramProject(true)}>
                <small>ลบโครงการ?</small>
              </a>
            </p>
          )}
          {showDeleteProgramProject && (
            <Popconfirm
              onConfirm={() => {
                deleteProgramProject();
              }}
              title={'คุณต้องการลบโครงการออกจากระบบใช่หรือไม่?'}
              okText="ใช่"
              cancelText="ไม่ใช่"
            >
              <Button danger type="primary">
                ยืนยันการลบโครงการ
              </Button>
            </Popconfirm>
          )}
        </div>
      )}
      <EditModal
        program={editType === 'program' ? program : programProject}
        setIsVisible={setEditModalVisible}
        onClose={() => {
          fetchProgramDetail();
        }}
        isVisible={editModalVisible}
        endPoint={editType === 'program' ? programEndPoint : programProjectEndPoint}
        updateFields={editType === 'program' ? ProgramUpdateFields : ProgramProjectUpdateFields}
      />
    </div>
  );
};

export default ProgramDetail;
