import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import csv from 'csv-parser';
import fileReaderStream from 'filereader-stream';
import _, { find } from 'lodash';
import useUpload from './useUpload';

const ImportModal = ({ isVisible, config, setIsVisible, subjects, onClose }) => {
  const filePath = '';
  const {
    uploadRef,
    setSelectedFile,
    setImportSubjects,
    importSubjects,
    setCount,
    count,
    onUpload,
    uploading,
    uploadProgress,
    importErrors,
  } = useUpload(config);

  const [fileErrors, setFileErrors] = useState([]);

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    const results = [];
    const headers = [];
    let count = 0;

    fileReaderStream(file)
      .pipe(
        csv({
          mapHeaders: ({ header, index }) => {
            headers.push(header.toLowerCase().trim());
            return header.toLowerCase().trim();
          },
        })
      )
      .on('data', (data) => {
        results.push(data);
        count++;
      })
      .on('end', () => {
        // const  = [];
        const subjectsObj = [];
        const errors = headers.reduce((results, header) => {
          if (header === 'citizen_id') return results;
          const foundSubject = find(subjects, { key: header.trim() });
          console.log(foundSubject, header, header === 'citizen_id');
          if (!foundSubject) {
            results.push({
              message: { subject: `ไม่พบวิชา ${header} ในระบบ หากชื่อวิชาถูกต้องกรุณาติดต่อ Admin` },
              index: 1,
            });
            return results;
          } else {
            subjectsObj.push(foundSubject);
          }
          if (foundSubject.subject_type !== 'university_score') {
            results.push({ message: { subject: `วิชา ${header} ไม่สามารถนำเข้าเองได้` }, index: 1 });
          }
          return results;
        }, []);

        results.forEach((record, i) => {
          subjectsObj.forEach((subject) => {
            let score = record[subject.key];
            if (score) {
              console.log(score);
              score = parseFloat(score);
              if (score > subject.max_value) {
                errors.push({
                  index: i + 2,
                  message: { subject: `วิชา ${subject.key} คะแนนเกินค่ามากสุด (${subject.max_value})` },
                });
              }
              if (score < (subject.min_value || 0)) {
                errors.push({
                  index: i + 2,
                  message: {
                    subject: `วิชา ${subject.key} คะแนนต่ำกว่าค่าน้อยสุด (${subject.min_value || 0})`,
                  },
                });
              }
            }
          });
        });

        if (errors.length > 0) {
          setFileErrors(errors);
          setImportSubjects([]);
          setCount(0);
          e.target.value = null;
          return;
        } else {
          setSelectedFile(file);
          setImportSubjects(headers);
          setCount(count);
          setFileErrors([]);
        }

        // setImportScores(results);
        // setImportModalVisible(true);
      });
    // Prevent upload
    return false;
  };

  // useEffect(() => onErrorsChange(importErrors), [importErrors]);

  return (
    <Modal visible={isVisible} onCancel={() => setIsVisible(false)} width={900} footer={null}>
      <h2>นำเข้าคะแนนที่จัดสอบเอง</h2>
      <br />
      {Boolean(importErrors.length) && (
        <div className="sec-error">
          <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ ทั้งหมด {importErrors.length} แถว</p>
          <ul>
            {importErrors.map(({ index, message }, _idx) => (
              <li key={_idx}>
                แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
              </li>
            ))}
          </ul>
        </div>
      )}

      {Boolean(fileErrors.length) && (
        <div className="sec-error">
          <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ </p>
          <ul>
            {fileErrors.map(({ index, message }, _idx) => (
              <li key={_idx}>
                แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
              </li>
            ))}
          </ul>
        </div>
      )}

      <p>เลือกไฟล์ที่ต้องการนำเข้า แล้วคลิกปุ่มนำเข้าข้อมูล</p>

      {importSubjects.length > 0 && (
        <div className="sec-announce">
          <p>
            จำนวนนักเรียนที่นำเข้าระบบ <b>{count}</b> คน
          </p>
          <p>วิชาที่ต้องการนำเข้าระบบ </p>
          <ul>
            {importSubjects.map((header) => {
              const foundSubject = find(subjects, { key: header });
              if (foundSubject && !foundSubject.isStandardScore) {
                return (
                  <li className="msg">
                    {foundSubject.key}: {foundSubject.label}
                  </li>
                );
              } else {
                return <span></span>;
              }
            })}
          </ul>
        </div>
      )}

      {/* <p className="s-flex file">
        <i className="i-course"></i> {filePath.split('/').pop()}
      </p> */}

      <div className="sec-form">
        <div className="col-75">
          <input type="file" accept=".csv" ref={uploadRef} onChange={(e) => onSelectFile(e)} />
          <div className={uploadProgress ? 't-progress active-animation' : 't-progress'}>
            <b />
          </div>
        </div>
        <div className="col-25">
          <a
            className="btn-main"
            onClick={() =>
              onUpload(() => {
                onClose();
              })
            }
          >
            {uploading ? (
              <span>กำลังนำเข้าข้อมูล</span>
            ) : (
              <span>
                <i className="i-upload" /> นำเข้าข้อมูล
              </span>
            )}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
