import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import cn from 'classnames';
import FIELDS from 'constants/field_labels.json';
import { get, find, map } from 'lodash';
import moment from 'moment';

export default function ({ subjects, programProject }) {
  const complexCondition = get(programProject, 'score_conditions.score_condition');
  let complexSubject = []; //get(programProject, 'score_conditions.subject_names').split(' ');
  if (complexCondition) {
    complexSubject = get(programProject, 'score_conditions.subject_names').split(' ');
    complexSubject = complexSubject.map((minSubjectKey) => {
      const subjectKey = minSubjectKey.replace('min_', '');
      const subject = find(subjects, { key: subjectKey });
      if (subject) return subject.label;
      return '';
    });
  }

  const formatAgeDate = (date) => {
    return moment(date).add(543, 'years').format('DD/MM/YYYY');
  };

  return (
    <>
      {map(FIELDS, (FIELD, key) => {
        let value = programProject[key];

        if (value && value !== '0.0' && value !== '0') {
          if (['min_age_date', 'max_age_date'].indexOf(key) > -1) value = formatAgeDate(value);
          return (
            <div key={key}>
              <i className="i-yes"></i> {FIELD.label} <b>{value}</b>
            </div>
          );
        }
      })}

      {map(subjects, (subject) => {
        const key = `min_${subject.key}`;
        const isFoundInField = !!FIELDS[key];
        const score = get(programProject, `score_conditions.${key}`);
        if (score && score !== '0.0' && score !== '0' && !isFoundInField) {
          return (
            <div key={key}>
              <i className="i-yes"></i> {subject.label} <b>{score}</b>
            </div>
          );
        }
      })}

      {complexCondition === 1 && (
        <div key={'score-condition'}>
          <i className="i-yes"></i> {`คะแนนขั้นต่ำของวิชาใดวิชาหนึ่งระหว่าง${complexSubject.join(', ')}`}{' '}
          <b>{get(programProject, 'score_conditions.score_minimum')}</b>
        </div>
      )}
      {complexCondition === 2 && (
        <div key={'score-condition'}>
          <i className="i-yes"></i> {`คะแนนขั้นต่ำของผลรวมวิชา${complexSubject.join(', ')}`}{' '}
          <b>{get(programProject, 'score_conditions.score_minimum')}</b>
        </div>
      )}
    </>
  );
}
