import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, map, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import numeral from 'numeral';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';
import ProcessModal from './ProcessModal';
import Round3ProcessModal from './Round3ProcessModal';

// import TICKET_CATEGORIES from 'constants/order-types.json';
// const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
//   result[category.value] = category.label;
//   return result;
// }, {});

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

const STATUSES = [
  { label: 'ทั้งหมด', value: 'all' },
  { label: 'รอดำเนินการ', value: 'pending' },
  { label: 'จ่ายเงินแล้ว', value: 'paid' },
  { label: 'ยกเลิก', value: 'canceled' },
];
const ORDER_TYPES = [
  { label: 'ทั้งหมด', value: 'all' },
  // { label: 'การสมัครสอบ', value: 'pending' },
  { label: 'ตรวจผลสอบ', value: 'recheck_exam' },
  { label: 'สมัครเรียน', value: 'university_apply' },
];

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ticketCount, setTicketCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    status: 'pending',
    category: 'all',
  });

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchOrders = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: orders } = await callApi({
        url: '/orders',
        query,
      });
      setOrders(orders || []);
      setTicketCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setOrders([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      fetchSubjects();
    }
  }, [me]);
  useEffect(() => {
    fetchOrders(1);
  }, []);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchOrders(_page);
  };

  // if (me) {
  //   currentCourses = courses[me.university_id];
  // }
  const onStatusChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      status: value,
    });
  };

  const onCategoryChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      category: value,
    });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchOrders(1);
  };

  const dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };

  console.log(processModalVisible);

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>ข้อมูลการจ่ายเงิน</h1>

          <div className="sec-box">
            <h2>ค้นหาข้อมูลการจ่ายเงิน</h2>
            <div className="sec-form">
              <div className="col-40">
                <label>เลขที่รายการ, รหัสบัตรประชาชน</label>
                <div className="ant-text">
                  <Input
                    value={get(filterQuery, 'q')}
                    onChange={(e) =>
                      setFilterQuery({
                        ...filterQuery,
                        q: e.target.value,
                      })
                    }
                    onPressEnter={onSearch}
                    type="text"
                  />
                </div>
              </div>

              <div className="col-20">
                <label>
                  ชนิดการจ่ายเงิน
                  <div className="select-container">
                    {/* อย่าลืมแก้ตรงนี้นะคร้าบ */}
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onCategoryChange}
                      value={get(filterQuery, 'category')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                        ทั้งหมด
                      </Option>
                      {ORDER_TYPES.map((ORDER_TYPE) => (
                        <Option key={ORDER_TYPE.label} value={ORDER_TYPE.value} label={ORDER_TYPE.label}>
                          {ORDER_TYPE.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20">
                <label>
                  สถานะ
                  <div className="select-container">
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onStatusChange}
                      value={get(filterQuery, 'status')}
                      filterOption={filterLabelAndValue}
                    >
                      {STATUSES.map((STATUS) => (
                        <Option key={STATUS.label} value={STATUS.value} label={STATUS.label}>
                          {STATUS.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchOrders(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {orders.length === 0 && (
            <table className="t-table -orders">
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลเงื่อนไขการรับสมัครในระบบ</th>
                </tr>
              </tbody>
            </table>
          )}
          {orders.length > 0 && (
            <>
              <table className="t-table -orders">
                <thead>
                  <tr>
                    <th>เลขที่</th>
                    <th>เลขบัตรประชาชน</th>
                    <th>ชื่อ นามสกุล</th>
                    {/* <th>ชนิดรายการ</th> */}
                    <th>ราคา</th>
                    <th>วิธีจ่ายเงิน</th>
                    <th>สถานะ</th>

                    <th>เวลาสร้าง</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: order.program_id,
                    // });
                    // const university = find(universities, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    // เพิ่ม category
                    // ส่งอีเมลล์ ที่ order
                    // order request สร้างโรงเรียน

                    const ic = 'i-' + order.status;

                    return (
                      <tr key={i}>
                        <td>
                          <a
                            onClick={() => {
                              setSelectedOrder(order);
                              setProcessModalVisible(true);
                            }}
                          >
                            {order.order_id}
                          </a>
                        </td>
                        <td>{order.citizen_id}</td>

                        <td>
                          <span>
                            {get(order, 'student.first_name')} {get(order, 'student.last_name')}
                          </span>
                        </td>
                        {/* <td>{TICKET_LABELS[order.category] || TICKET_LABELS[order.ticket_type]}</td> */}

                        <td className="text-right">{numeral(order.amount).format('0,0.00')}</td>
                        <td>
                          {order.payment_method === 'cs' && 'เคาท์เตอร์เซอร์วิส'}
                          {order.payment_method === 'qr' && 'ธนาคาร'}
                          {order.payment_method !== 'qr' && order.payment_method !== 'cs' && '-'}
                        </td>
                        <td>{moment(dateFromObjectId(order._id)).add('543', 'year').format('D MMM HH:mm น.')}</td>
                        <td>
                          <div className="s-flex">
                            <i className={ic}></i>{' '}
                            {get(find(STATUSES, { value: order.status }), 'label') || order.status}
                            {order.status === 'canceled' && (
                              <i>
                                &nbsp;
                                <small>
                                  {order.canceled_at &&
                                    moment(order.canceled_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                                  {order.canceled_by && `โดย ${get(order, 'canceled_by.name')}`}
                                </small>
                              </i>
                            )}
                            {order.status === 'paid' && (
                              <i>
                                &nbsp;
                                <small>
                                  {order.paid_at && moment(order.paid_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                                  {order.paid_by && `โดย ${get(order, 'paid_by.name')}`}
                                </small>
                              </i>
                            )}
                          </div>
                        </td>

                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={40}
                total={ticketCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>

      {processModalVisible && selectedOrder.order_type === 'apply_round3' && (
        <Round3ProcessModal
          isVisible={processModalVisible}
          setIsVisible={setProcessModalVisible}
          order={selectedOrder}
          onClose={() => {
            fetchOrders();
            setSelectedOrder({});
            setProcessModalVisible(false);
          }}
        />
      )}
      {processModalVisible && selectedOrder.order_type === 'recheck_exam' && (
        <ProcessModal
          isVisible={processModalVisible}
          setIsVisible={setProcessModalVisible}
          order={selectedOrder}
          onClose={() => {
            fetchOrders();
            setSelectedOrder({});
            setProcessModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
