module.exports = [
  {
    value: '1_2565',
    label: 'Portfolio',
  },
  {
    value: '2_2565',
    label: 'Quota',
  },
  {
    value: '3_2565',
    label: 'Admission',
  },
  {
    value: '4_2565',
    label: 'Direct Admission',
  },
  {
    value: '41_2565',
    label: 'Direct Admission 1',
  },
  {
    value: '42_2565',
    label: 'Direct Admission 2',
  },
];
