import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import callApi from 'helpers/callapi';
import _ from 'lodash';
import useRound from './useRound';

const agendaOptions = [
  { label: 'รับสมัคร', value: 'register_date' },
  {
    label: 'ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์',
    value: 'interviewee_announce_date',
  },
  { label: 'สอบสัมภาษณ์', value: 'interview_date' },
  {
    label: 'ประกาศรายชื่อผู้ผ่านการคัดเลือก',
    value: 'candidate_announce_date',
  },
  { label: 'ยืนยันสิทธิ์ในระบบ', value: 'confirm_date' },
  { label: 'สละสิทธิ์ในระบบ', value: 'cancel_date' },
  {
    label: 'ประกาศรายชื่อผู้มีสิทธิ์เข้าศึกษา',
    value: 'student_announce_date',
  },
];

const toMoment = (date) => (date ? moment(date, 'YYYY-MM-DD').add(543, 'years') : null);

const useEditModal = (_roundInfo = {}, roundIndex = null) => {
  const [roundInfo, setRoundInfo] = useState(_roundInfo);
  useEffect(() => setRoundInfo(_roundInfo), [_roundInfo]);

  const uploadRef = useRef();
  const [selectedFile, setSelectedFile] = useState();

  const onRoundUpdate = async (data) => {
    await callApi({
      method: 'PUT',
      url: `/universities/rounds/${roundIndex}`,
      body: { data },
    });
    setRoundInfo((_roundInfo) => ({ ..._roundInfo, ...data }));
  };

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState();
  const onUpload = async (setUniversity) => {
    if (uploading || !selectedFile) return;
    try {
      const fileType = `file_path_${roundIndex === 5 ? 'handicap' : roundIndex}`;
      const data = new FormData();
      data.append('file', selectedFile);
      data.append('fileType', fileType);

      setUploading(true);
      const result = await callApi({
        method: 'POST',
        url: '/universities/upload',
        body: data,
        uploadFile: true,
        onUploadProgress: (data) => setUploadProgress(Math.round((100 * data.loaded) / data.total)),
      });
      setRoundInfo((_roundInfo) => ({
        ..._roundInfo,
        file_path: result.data[fileType],
        uploaded_at: result.data[`${fileType}_updated_at`],
      }));
      setUniversity((_university) => ({
        ..._university,
        [fileType]: result.data[fileType],
        [`${fileType}_updated_at`]: result.data[fileType],
      }));
      setUploadProgress();
      setUploading(false);
      setSelectedFile();
      uploadRef.current.value = '';
    } catch (error) {
      console.log('onUpload error', error);
      setUploadProgress();
      setUploading(false);
    }
  };

  const [fileRemoving, setFileRemoving] = useState(false);
  const onFileRemove = async (setUniversity) => {
    if (fileRemoving) return;
    const fileType = `file_path_${roundIndex === 5 ? 'handicap' : roundIndex}`;
    try {
      setFileRemoving(true);
      await callApi({
        method: 'DELETE',
        url: '/universities/files',
        query: {
          fileType,
        },
      });
      setRoundInfo((_roundInfo) => ({
        ..._roundInfo,
        file_path: null,
        uploadedAt: null,
      }));
      setUniversity((_university) => ({
        ..._university,
        [fileType]: null,
        [`${fileType}_updated_at`]: null,
      }));
      setFileRemoving(false);
    } catch (error) {
      console.log('onFileRemove error', error);
      setFileRemoving(false);
    }
  };

  const data = useRound(roundInfo, roundIndex);

  const [selectedKey, setSelectedKey] = useState(
    _.get(
      agendaOptions.filter(({ value }) => !data.existedKeys.includes(value)),
      '0.value'
    )
  );
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const onAgendaAdd = async () => {
    if (!selectedKey || !selectedStartDate || !selectedEndDate) return;
    await onRoundUpdate({
      [`${selectedKey.replace('_date', '_start_date')}`]: selectedStartDate,
      [`${selectedKey.replace('_date', '_end_date')}`]: selectedEndDate,
    });
    setSelectedKey((_key) =>
      _.get(
        agendaOptions.filter(({ value }) => !data.existedKeys.includes(value) && value !== _key),
        '0.value'
      )
    );
    setSelectedStartDate('');
    setSelectedEndDate('');
  };

  return {
    ...data,
    roundInfo,
    onRoundUpdate,
    isDeletable: (key) => {
      switch (roundIndex) {
        case 1:
          return !['confirm_date', 'cancel_date'].includes(key);
        case 2:
          return !['confirm_date', 'cancel_date'].includes(key);
        case 3:
          return ![
            'register_date',
            'interviewee_announce_date',
            'interviewee_announce_date_2',
            'confirm_date',
            'confirm_date_2',
            'cancel_date',
          ].includes(key);
        case 4:
          return ![
            'interviewee_announce_date',
            'interviewee_announce_date_2',
            'confirm_date',
            'confirm_date_2',
            'cancel_date',
          ].includes(key);
        default:
          return true;
      }
    },

    agendaOptions,
    selectedKey,
    setSelectedKey,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    onAgendaAdd,

    uploadRef,
    selectedFile,
    setSelectedFile,
    onUpload,
    uploading,
    uploadProgress,
    onFileRemove,
    fileRemoving,
  };
};

export default useEditModal;
