import { get, omitBy, isNil, sumBy } from 'lodash';
import Cookies from 'js-cookie';
import URL from 'url';
import config from '../config';
import axios from 'axios';

export default async ({ fullURL, url, method, query, body, contentType, uploadFile, onUploadProgress }) => {
  if (uploadFile) {
    const axiosInstance = axios.create({ baseURL: config.apiHost });

    const response = await axiosInstance
      .post(encodeURI(url), body, {
        headers: omitBy(
          {
            'Content-Type': uploadFile ? null : 'application/json; charset=utf-8',
            accessToken: Cookies.get('accessToken'),
          },
          isNil
        ),
        onUploadProgress,
      })
      .catch((error) => {
        return Promise.reject({ statusCode: error.response.statusCode, reason: error.response.data }); // eslint-disable-line
      });

    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject({ statusCode: response.status, reason: response.data }); // eslint-disable-line
    }
  } else {
    const response = await fetch(
      // eslint-disable-line
      `${fullURL || `${config.apiHost}${encodeURI(url)}`}${URL.format({
        query,
      })}`,
      omitBy(
        {
          method: method || (body ? 'POST' : 'GET'),
          headers: omitBy(
            {
              'Content-Type': uploadFile ? null : 'application/json; charset=utf-8',
              accessToken: Cookies.get('accessToken'),
            },
            isNil
          ),
          body: uploadFile ? body : body ? JSON.stringify(body) : undefined,
          enctype: 'multipart/form-data',
          // body: body ? JSON.stringify(body) : undefined,
        },
        isNil
      )
    );

    if (response.status === 200) {
      const json = await response.json();
      return Promise.resolve(json);
    } else {
      const json = await response.json();
      return Promise.reject({ statusCode: response.status, reason: json }); // eslint-disable-line
    }
  }
};
