import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import cn from 'classnames';

const CheckList = ({ useNo, isChecked, label }) => {
  return (
    <li>
      <i
        className={cn({
          'i-yes': isChecked,
          'i-none': !useNo && !isChecked,
          'i-no': useNo && !isChecked,
        })}
      ></i>{' '}
      {label}
    </li>
  );
};

export default CheckList;
