import React from 'react';
import _ from 'lodash';
import moment from 'moment';

const nameMap = {
  1: 'Portfolio',
  2: 'Quota',
  3: 'Admission',
  4: 'Direct Admission',
  41: 'Direct Admission 1',
  42: 'Direct Admission 2',
  5: 'ผู้พิการ',
};

const toMoment = (date) => (date ? moment(date, 'YYYY-MM-DD').add(543, 'years') : null);

const formatDate = (input) => {
  if (Array.isArray(input) && input.length) {
    const [startMoment, endMoment] = input;
    if (startMoment.format('YYYY-MM-DD') === endMoment.format('YYYY-MM-DD') || (startMoment && !endMoment)) {
      return startMoment.format('D MMM YY');
    }
    return startMoment.month() === endMoment.month()
      ? `${startMoment.format('D')} - ${endMoment.format('D MMM YY')}`
      : `${startMoment.format('D MMM')} - ${endMoment.format('D MMM YY')}`;
  } else if (moment.isMoment(input)) {
    return input.format('D MMM YY');
  }

  return '';
};

const useRound = (roundInfo = {}, roundIndex = null) => {
  const registerDateString = formatDate(
    _.compact([toMoment(roundInfo.register_start_date), toMoment(roundInfo.register_end_date)])
  );
  const intervieweeAnnounceDateString = formatDate(
    _.compact([toMoment(roundInfo.interviewee_announce_start_date), toMoment(roundInfo.interviewee_announce_end_date)])
  );
  const intervieweeAnnounceDate2String = formatDate(
    _.compact([
      toMoment(roundInfo.interviewee_announce_start_date_2),
      toMoment(roundInfo.interviewee_announce_end_date_2),
    ])
  );
  const interviewDateString = formatDate(
    _.compact([toMoment(roundInfo.interview_start_date), toMoment(roundInfo.interview_end_date)])
  );
  const candidateAnnounceDateString = formatDate(
    _.compact([toMoment(roundInfo.candidate_announce_start_date), toMoment(roundInfo.candidate_announce_end_date)])
  );
  const confirmDateString = formatDate(
    _.compact([toMoment(roundInfo.confirm_start_date), toMoment(roundInfo.confirm_end_date)])
  );
  const confirmDate2String = formatDate(
    _.compact([toMoment(roundInfo.confirm_start_date_2), toMoment(roundInfo.confirm_end_date_2)])
  );
  const cancelDateString = formatDate(
    _.compact([toMoment(roundInfo.cancel_start_date), toMoment(roundInfo.cancel_end_date)])
  );
  const studentAnnounceDateString = formatDate(
    _.compact([toMoment(roundInfo.student_announce_start_date), toMoment(roundInfo.student_announce_end_date)])
  );

  return {
    roundName: nameMap[roundIndex],

    filePath: roundInfo.file_path,
    uploadedAt: roundInfo.uploaded_at,

    existedKeys: _.compact([
      registerDateString ? 'register_date' : null,
      intervieweeAnnounceDateString ? 'interviewee_announce_date' : null,
      intervieweeAnnounceDate2String ? 'interviewee_announce_date_2' : null,
      interviewDateString ? 'interview_date' : null,
      candidateAnnounceDateString ? 'candidate_announce_date' : null,
      confirmDateString ? 'confirm_date' : null,
      confirmDate2String ? 'confirm_date_2' : null,
      cancelDateString ? 'cancel_date' : null,
      studentAnnounceDateString ? 'student_announce_date' : null,
    ]),

    registerDateString,
    intervieweeAnnounceDateString,
    intervieweeAnnounceDate2String,
    interviewDateString,
    candidateAnnounceDateString,
    confirmDateString,
    confirmDate2String,
    cancelDateString,
    studentAnnounceDateString,
  };
};

export default useRound;
