import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, map, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';
import ProcessModal from './ProcessModal';

import TICKET_CATEGORIES from 'constants/ticket-types.json';
const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
  result[category.value] = category.label;
  return result;
}, {});

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

const STATUSES = [
  { label: 'ทั้งหมด', value: 'all' },
  { label: 'รอดำเนินการ', value: 'pending' },
  { label: 'ดำเนินการแล้ว', value: 'processed' },
  { label: 'ปฏิเสธ', value: 'rejected' },
];

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketCount, setTicketCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    status: 'pending',
    category: 'all',
  });

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchTickets = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: tickets } = await callApi({
        url: '/tickets',
        query,
      });
      setTickets(tickets || []);
      setTicketCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setTickets([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      fetchSubjects();
    }
  }, [me]);
  useEffect(() => {
    fetchTickets(1);
  }, []);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchTickets(_page);
  };

  // if (me) {
  //   currentCourses = courses[me.university_id];
  // }
  const onStatusChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      status: value,
    });
  };

  const onCategoryChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      category: value,
    });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchTickets(1);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>ข้อมูลคำร้อง</h1>

          <div className="sec-box">
            <h2>ค้นหาข้อมูลคำร้อง</h2>
            <div className="sec-form">
              <div className="col-40">
                <label>หมายเลขคำร้อง, ชื่อ, นามสกุล, รหัสประจำตัว, อีเมล, เบอร์ติดต่อ </label>
                <div className="ant-text">
                  <Input
                    value={get(filterQuery, 'q')}
                    onChange={(e) =>
                      setFilterQuery({
                        ...filterQuery,
                        q: e.target.value,
                      })
                    }
                    onPressEnter={onSearch}
                    type="text"
                  />
                </div>
              </div>

              <div className="col-20">
                <label>
                  หมวดหมู่
                  <div>
                    {/* อย่าลืมแก้ตรงนี้นะคร้าบ */}
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onCategoryChange}
                      value={get(filterQuery, 'category')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                        ทั้งหมด
                      </Option>
                      {TICKET_CATEGORIES.map((TICKET_CATEGORY) => (
                        <Option key={TICKET_CATEGORY.label} value={TICKET_CATEGORY.value} label={TICKET_CATEGORY.label}>
                          {TICKET_CATEGORY.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20">
                <label>
                  สถานะคำร้อง
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onStatusChange}
                      value={get(filterQuery, 'status')}
                      filterOption={filterLabelAndValue}
                    >
                      {STATUSES.map((STATUS) => (
                        <Option key={STATUS.label} value={STATUS.value} label={STATUS.label}>
                          {STATUS.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchTickets(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {tickets.length === 0 && (
            <table className="t-table -tickets">
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลคำร้อง</th>
                </tr>
              </tbody>
            </table>
          )}
          {tickets.length > 0 && (
            <>
              <table className="t-table -tickets">
                <thead>
                  <tr>
                    <th>หมายเลข</th>
                    <th>ชื่อ นามสกุล นักเรียน</th>
                    <th>หมวดหมู่คำขอ</th>
                    <th>สถานะ</th>
                    <th>เวลาสร้าง</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets.map((ticket, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: ticket.program_id,
                    // });
                    // const university = find(universities, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });
                    const requestedData = reduce(
                      ticket.requested_data,
                      (results, value, key) => {
                        if (['is_tcas'].indexOf(key) === -1) {
                          results.push(key);
                        }
                        return results;
                      },
                      []
                    );
                    // เพิ่ม category
                    // ส่งอีเมลล์ ที่ ticket
                    // ticket request สร้างโรงเรียน

                    const ic = 'i-' + ticket.status;

                    return (
                      <tr key={i}>
                        <td>
                          <a
                            onClick={() => {
                              setSelectedTicket(ticket);
                              setProcessModalVisible(true);
                            }}
                          >
                            {ticket.ticket_id}
                          </a>
                        </td>

                        <td>
                          {ticket.ticket_type === 'create_student' ? (
                            <span>
                              {get(ticket, 'requested_data.first_name.value')}{' '}
                              {get(ticket, 'requested_data.last_name.value')}
                            </span>
                          ) : (
                            <span>
                              {get(ticket, 'student.first_name')} {get(ticket, 'student.last_name')}
                            </span>
                          )}
                        </td>
                        <td>{TICKET_LABELS[ticket.category] || TICKET_LABELS[ticket.ticket_type]}</td>

                        <td>
                          <div className="s-flex">
                            <i className={ic}></i>{' '}
                            {get(find(STATUSES, { value: ticket.status }), 'label') || ticket.status}
                            {ticket.status === 'rejected' && (
                              <i>
                                &nbsp;
                                <small>
                                  {ticket.rejected_at &&
                                    moment(ticket.rejected_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                                  {ticket.rejected_by && `โดย ${get(ticket, 'rejected_by.name')}`}
                                </small>
                              </i>
                            )}
                            {ticket.status === 'processed' && (
                              <i>
                                &nbsp;
                                <small>
                                  {ticket.approved_at &&
                                    moment(ticket.approved_at).add(543, 'year').format('D MMM HH:mm น.')}{' '}
                                  {ticket.approved_by && `โดย ${get(ticket, 'approved_by.name')}`}
                                </small>
                              </i>
                            )}
                          </div>
                        </td>
                        <td>{moment(ticket.created_at).add('543', 'year').format('D MMM HH:mm น.')}</td>
                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={40}
                total={ticketCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>

      {processModalVisible && (
        <ProcessModal
          isVisible={processModalVisible}
          setIsVisible={setProcessModalVisible}
          ticket={selectedTicket}
          onClose={() => {
            fetchTickets();
            setSelectedTicket({});
            setProcessModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
