import React, { useState, useEffect, useRef } from 'react';

import _, { get, map, reduce } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

// Helpers
import callApi from 'helpers/callapi';

// Component
import CalendarModal from './CalendarModal';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import numeral from 'numeral';

import { UNIVERSITY_TYPES } from 'constants/constants';
import { Modal, Tooltip } from 'antd';
import RoundRow from 'containers/Rounds/RoundRow';
import useRound from 'containers/Rounds/useRound';

moment.locale('th');

// const UNIVERSITY_TYPES = TH.UNIVERSITY_TYPES;
const allRounds = [
  { name: 'Portfolio', index: 1 },
  { name: 'Quota', index: 2 },
  { name: 'Admission', index: 3 },
  { name: 'Direct Admission', index: 4 },
];

export default () => {
  // const [universities, setUniversities] = useState([]);
  const dispatch = useDispatch();
  const [hasAnnouncement, setHasAnnouncement] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [schoolCount, setSchoolCount] = useState(0);

  const [calendarModalVisible, setCalendarModalVisible] = useState(false);
  const [selectedRound, setSelectedRound] = useState();

  const [universityCountData, setUniversityCountData] = useState({
    count: 0,
    completeCount: 0,
    incompleteCount: 0,
    universityCountByTypes: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    programCountByUniversityTypes: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    programProjectCountByRounds: { 1: 0, 2: 0, 3: 0, 4: 0 },
    allProgramCount: 0,
    allProgramProjectCount: 0,
    completeUniversityCount: 0,
    incompleteUniversityCount: 0,
  });

  const fetchUniversities = async () => {
    if (universities.length > 0) {
      return;
    }
    try {
      const result = await callApi({
        url: '/universities',
      });
      // dispatch({ type: 'UPDATE_UNIVERSITIES', data: result.data });
      const resultData = result.data.reduce((result, university) => {
        if (university.university_id !== '999') {
          result.push(university);
        }
        return result;
      }, []);
      setUniversities(resultData);
      const counting = resultData.reduce(
        (result, university) => {
          if (university.university_id === '999') {
            return result;
          }
          result.allProgramCount += university.program_count || 0;
          result.allProgramProjectCount += university.program_project_count || 0;
          result.universityCountByTypes[university.university_type]++;
          result.programCountByUniversityTypes[university.university_type] += university.program_count || 0;
          if (university.program_project_count_by_round) {
            result.programProjectCountByRounds = reduce(
              university.program_project_count_by_round,
              (result, count, round) => {
                const roundString = round.split('_')[0];
                result[roundString] += count || 0;
                return result;
              },
              result.programProjectCountByRounds
            );
          }

          if (university.program_file && university.condition_file && university.scoring_file) {
            result.completeUniversityCount++;
          } else {
            result.incompleteUniversityCount++;
          }

          return result;
        },
        {
          allProgramCount: 0,
          allProgramProjectCount: 0,
          universityCountByTypes: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
          programCountByUniversityTypes: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
          programProjectCountByRounds: { 1: 0, 2: 0, 3: 0, 4: 0 },
          completeUniversityCount: 0,
          incompleteUniversityCount: 0,
        }
      );
      setUniversityCountData({
        ...universityCountData,
        ...counting,
        count: result.count - 1,
      });

      // setProgramProjects(programProjects || []);
    } catch (error) {
      console.log('errror', error);
      // setProgramProjects([]);
    }
  };

  const fecthSchoolDashboard = async () => {
    const result = await callApi({
      url: '/schools/dashboard',
    });
    setSchoolCount(get(result, 'count') || 0);
  };

  useEffect(() => {
    fetchUniversities();
    fecthSchoolDashboard();
  }, []);

  const openCalendarModal = (round) => {
    if (!round) return;

    setCalendarModalVisible(true);
    setSelectedRound(round);
  };

  if (universities.length === 0) return null;
  return (
    <div className="t-main">
      <h1>ภาพรวมสำหรับผู้ดูแล</h1>

      <div className="sec-dashboard">
        <div className="sec-box">
          <div className="title">
            <h3>มหาวิทยาลัย</h3>
            <b>{universityCountData.count}</b>

            <div className="no">
              <i className="i-no"></i> {universityCountData.incompleteUniversityCount}
            </div>

            <div className="yes">
              <i className="i-yes"></i> {universityCountData.completeUniversityCount}
            </div>
          </div>
          <div className="graph">
            <div className="t-scores">
              {map(universityCountData.universityCountByTypes, (countByType, key) => {
                return (
                  <b
                    style={{
                      width: (countByType / universityCountData.count) * 100 + '%',
                    }}
                  ></b>
                );
              })}
            </div>
            <table className="detail">
              <tbody>
                {map(universityCountData.universityCountByTypes, (countByType, key) => {
                  return (
                    <tr>
                      <td>{get(UNIVERSITY_TYPES, `${key}.th`)}</td>
                      <td>{numeral(countByType).format('0,0')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="sec-box">
          <div className="title">
            <h3>หลักสูตร</h3>
            <b>{numeral(universityCountData.allProgramCount).format('0.0a')} </b>
          </div>
          <div className="graph">
            <div className="t-scores">
              {map(universityCountData.programCountByUniversityTypes, (countByType, key) => {
                return (
                  <b
                    style={{
                      width: (countByType / universityCountData.allProgramCount) * 100 + '%',
                    }}
                  ></b>
                );
              })}
            </div>
            <table className="detail">
              <tbody>
                {map(universityCountData.programCountByUniversityTypes, (countByType, key) => {
                  return (
                    <tr>
                      <td>{get(UNIVERSITY_TYPES, `${key}.th`)}</td>
                      <td>{numeral(countByType).format('0,0')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="sec-box">
          <div className="title">
            <h3>เงื่อนไขการรับ</h3>
            <b>{numeral(universityCountData.allProgramProjectCount).format('0.0a')}</b>
          </div>
          <div className="graph">
            <div className="t-scores">
              {map(universityCountData.programProjectCountByRounds, (countByRound, key) => {
                return (
                  <b
                    style={{
                      width: (countByRound / universityCountData.allProgramProjectCount) * 100 + '%',
                    }}
                  ></b>
                );
              })}
            </div>
            <table className="detail">
              <tbody>
                {map(universityCountData.programProjectCountByRounds, (countByRound, key) => {
                  return (
                    <tr>
                      <td>รอบ {key}</td>
                      <td>{numeral(countByRound).format('0,0')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="sec-box">
          <div className="title">
            <h3>นักเรียน</h3>
            <b>0</b>
            <h3>โรงเรียน</h3>
            <b>{numeral(schoolCount).format('0,0')}</b>
          </div>
          <div className="graph">
            <div className="t-scores">
              <b style={{ width: 100 + '%' }}></b>
              {/* <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b> */}
            </div>
            <table className="detail">
              <tbody>
                <tr>
                  <td>หลักสูตร 1</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>หลักสูตร 2</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>หลักสูตร 3</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>หลักสูตร 4</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>อื่นๆ</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <h2>สถานะข้อมูลของมหาวิทยาลัย</h2>
      {/* <div className="sec-box">
        <div className="sec-form">
          <div className="col-25">
            <label>มหาวิทยาลัย</label>
            <select>
              <option>ทุกมหาวิทยาลัย</option>
              <option>มหาวิทยาลัยพะเยา</option>
            </select>
          </div>
          <div className="col-50">
            <label>เลือกรอบที่ยังไม่มีข้อมูล</label>
            <div className="group">
              <label>
                <input type="checkbox" /> 1
              </label>
              <label>
                <input type="checkbox" /> 2
              </label>
              <label>
                <input type="checkbox" /> 3
              </label>
              <label>
                <input type="checkbox" /> 4
              </label>
              <label>
                <input type="checkbox" /> ผูู้พิการ
              </label>
            </div>
          </div>
          <div className="col-25 col-action">
            <a className="btn-main">ค้นหาข้อมูล</a>
          </div>
        </div>
      </div> */}

      <table className="t-table -ustatus">
        <thead>
          <tr>
            <th></th>
            <th>รหัส</th>
            <th>ชื่อ</th>
            <th>หลักสูตร</th>
            <th className="text-center">
              จน.เงื่อนไข
              <br />
              การรับ
            </th>
            <th>1. Porfolio</th>
            <th>2. Quota</th>
            <th>3. Admission</th>
            <th>
              4. Direct
              <br />
              Admission
            </th>
            <th>ผู้พิการ</th>
          </tr>
        </thead>
        <tbody>
          {universities.map((university) => {
            const isUpdateProgram = university.program_file && university.condition_file && university.scoring_file;
            return (
              <tr>
                <td>
                  <i
                    className={cn({
                      'i-no': !isUpdateProgram,
                      'i-yes': isUpdateProgram,
                    })}
                  ></i>
                </td>
                <td>{university.university_id}</td>
                <td>{university.university_name}</td>
                <td>{university.program_count || 0}</td>
                <td>{university.program_project_count || 0}</td>

                {allRounds.map((round) => {
                  const roundKey = `round_${round.index}`;
                  const filepath = university[`file_path_${round.index}`];
                  const roundData = university[roundKey];

                  const projectCount =
                    get(university, `counting.program_project_round${round.index}_count`) ||
                    university[`program_project_round${round.index}_count`];

                  return (
                    <td>
                      {filepath ? (
                        <a href={filepath} target="_blank">
                          <i className="i-book-yes"></i>
                        </a>
                      ) : (
                        <i className="i-book-no"></i>
                      )}

                      <i
                        className={cn({
                          'i-calendar-none': !get(roundData, 'register_start_date'),
                          'i-calendar-yes': get(roundData, 'register_start_date'),
                        })}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          openCalendarModal(roundData);
                        }}
                      ></i>

                      {round.index === 3 && (
                        <>
                          {university.program_file ? (
                            <a href={university.program_file}>
                              <i className="i-course-yes"></i>
                            </a>
                          ) : (
                            <i className="i-course-no"></i>
                          )}

                          {university.condition_file ? (
                            <a href={university.condition_file}>
                              <i className="i-condition-yes"></i>
                            </a>
                          ) : (
                            <i className="i-condition-no"></i>
                          )}
                          {university.scoring_file ? (
                            <a href={university.scoring_file}>
                              <i className="i-cal-yes"></i>
                            </a>
                          ) : (
                            <i className="i-cal-no"></i>
                          )}
                        </>
                      )}
                      {projectCount ? <div>({projectCount})</div> : ''}
                    </td>
                  );
                })}

                <td>
                  {university.file_path_handicap ? (
                    <a href={university.file_path_handicap} target="_blank">
                      <i className="i-book-yes"></i>
                    </a>
                  ) : (
                    <i className="i-book-no"></i>
                  )}
                </td>
              </tr>
            );
          })}

          {/* <tr>
            <td>
              <i className="i-yes"></i>
            </td>
            <td>มหาวิทยาลัยแม่ฟ้าหลวง</td>
            <td>10</td>
            <td>25</td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
              <i className="i-course-yes"></i>
              <i className="i-condition-yes"></i>
              <i className="i-cal-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
            </td>
          </tr> */}
        </tbody>
      </table>
      <CalendarModal
        isVisible={calendarModalVisible}
        setIsVisible={setCalendarModalVisible}
        roundData={selectedRound}
      ></CalendarModal>
    </div>
  );
};
