import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// import AdminDetailModal from './AdminModal';

moment.locale('th');

export default () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const me = useSelector((state) => state.user);
  const [logs, setLogs] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // const [creditCount, setCreditCount] = useState()
  const fetchLogs = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1 };

      const { count, data: logs } = await callApi({
        url: '/logs',
        query,
      });
      setLogCount(count);

      setLogs(logs || []);

      setLoading(false);
    } catch (error) {
      console.log();

      setLogs([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchLogs(_page);
  };

  return (
    <div>
      <div className="t-main">
        <h1>ประวัติการใช้งาน</h1>

        <table>
          <thead>
            <tr>
              <th>วันที่</th>
              <th>คำอธิบาย</th>
              <th>ผู้ทำรายการ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, i) => {
              const hideDownload = log.type && log.type.indexOf('ticket') > -1;

              return (
                <tr key={i}>
                  <td>{moment(log.created_at).format('DD/MM/YY HH:mm:ss')}</td>
                  <td>{log.description}</td>
                  <td>{get(log, 'user.name') || get(log, 'user.email')}</td>
                  <td>
                    {log.link && !hideDownload && ['super', 'senior'].indexOf(me.role) > -1 && (
                      <a className="btn-alt" style={{ cursor: 'pointer' }} href={log.link}>
                        ดาวน์โหลด
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          current={currentPage}
          onChange={onChangePage}
          defaultPageSize={20}
          total={logCount}
          showSizeChanger={false}
        />

        {/* <ul className="t-paging">
          <li>
            <a href="#">←</a>
          </li>
          <li className="active">
            <a href="#">1</a>
          </li>

          <li>
            <a href="#">→</a>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
