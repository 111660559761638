import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default ({ getStudentFromSource, searchLabel, hideSchoolSelect }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const schools = useSelector((state) => {
    return state.schools || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rounds, setRounds] = useState([]);
  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  let foundSchool = false;
  console.log(schoolSearchText, selectedSchool);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
  });

  const fetchSchool = async (university_id) => {
    try {
      const result = await callApi({
        url: '/schools/all',
      });
      dispatch({
        type: 'UPDATE_SCHOOLS',
        data: result,
      });
    } catch (error) {
      console.log('errror', error);
    }
  };
  // const [creditCount, setCreditCount] = useState()
  const fetchStudents = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: students } = await getStudentFromSource(query);

      setStudents(students || []);
      setStudentCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setStudents([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents(1, {});
  }, []);

  useEffect(() => {
    if (me) {
      fetchSchool();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchStudents(_page);
  };

  const onSchoolChange = async (value, option) => {
    setFilterQuery({
      ...filterQuery,
      school: value,
    });
    // setSchoolSearchText('');
    setSelectedSchool(option);
  };
  const onSearch = async () => {
    setCurrentPage(1);
    fetchStudents(1);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            ข้อมูลนักเรียน
            <div style={{ float: 'right' }}>
              {get(me, 'role') === 'admin' && (
                <Upload {...props}>
                  <button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginRight: 10 }}
                    // onClick={() => setImportModalVisible(true)}
                  >
                    <PlusOutlined /> นำเข้าข้อมูลนักเรียน
                  </button>
                </Upload>
              )}

              {/* <span className="action">
                <a className="btn-mini" onClick={() => exportRanking()}>
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                    <path
                      d="m17 2c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm18 22c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm-28.7773438 2c-2.052 0-3.7226562 1.784516-3.7226562 3.978516v1.521484c0 3.281 1.6953906 6.132344 4.7753906 8.027344 2.591 1.595 6.0446094 2.472656 9.7246094 2.472656 2.19 0 4.431516-.324234 6.478516-.990234-.94-1.799-1.478516-3.839766-1.478516-6.009766 0-3.496 1.386813-6.664 3.632812-9zm28.7773438 1c.552 0 1 .448 1 1v6h6c.552 0 1 .448 1 1s-.448 1-1 1h-6v6c0 .552-.448 1-1 1s-1-.448-1-1v-6h-6c-.552 0-1-.448-1-1s.448-1 1-1h6v-6c0-.552.448-1 1-1z"
                      fill="currentColor"
                    />
                  </svg>
                  Export
                </a>
              </span> */}
            </div>
          </h1>

          <div className="sec-box">
            <h2>ค้นหานักเรียน</h2>
            <div className="sec-form">
              <div className="col-50">
                <label>{searchLabel}</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>
              {!hideSchoolSelect && (
                <div className="col-25">
                  <label>โรงเรียน</label>
                  <Select
                    showSearch={true}
                    style={{ width: '100%', marginTop: 0 }}
                    value={get(filterQuery, 'school')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={onSchoolChange}
                    // value={get(filterQuery, 'university')}
                    filterOption={filterLabelAndValue}
                    onSearch={(text) => setSchoolSearchText(text)}
                    onSelect={() => setSchoolSearchText('')}
                  >
                    <Option key={'all'} label={''} value={''}>
                      โรงเรียนทั้งหมด
                    </Option>
                    {schools.map((school, index) => {
                      if (schoolSearchText.length < 2 && index > 100) {
                        if (selectedSchool.value === school.value) {
                          foundSchool = true;
                        }
                        return null;
                      }

                      return (
                        <Option key={school.code} label={school.name} value={school.value} index={index}>
                          {school.name}
                        </Option>
                      );
                    })}
                    {selectedSchool.index > 100 && !foundSchool && schoolSearchText.length < 2 && (
                      <Option
                        key={selectedSchool.code}
                        label={selectedSchool.label}
                        value={selectedSchool.value}
                        index={selectedSchool.index}
                      >
                        {selectedSchool.label}
                      </Option>
                    )}
                  </Select>
                </div>
              )}

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchStudents(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {students.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลนักเรียน</th>
                </tr>
              </tbody>
            </table>
          )}
          {students.length > 0 && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr>
                    <th>รหัสประจำตัวประชาชน</th>
                    <th>ชื่อ - นามสกุล</th>
                    <th>โรงเรียน</th>
                    <th>ปีการศึกษาที่จบ</th>
                    <th>จังหวัด</th>
                    <th>เบอร์ติดต่อ</th>
                    <th>เกรด</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: student.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    return (
                      <tr key={i}>
                        <td>{student.citizen_id}</td>

                        <td>
                          {student.first_name} {student.last_name}
                        </td>
                        <td>{student.school_name}</td>
                        <td>{student.school_year}</td>
                        <td>{student.school_province_name}</td>
                        <td>{student.telephone}</td>
                        <td>{student.gpax5}</td>
                        {/* <td> */}
                        {/* {_.map(program.score, (score, key) => {
                        const subject = find(subjects, { key });

                        return (
                          <div key={key}>
                            {subject && subject.label} {score}%
                          </div>
                        );
                      })} */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={studentCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
