import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TCAS_STATUSES from 'constants/tcas_statuses';
// Components

import { Button, Select, Pagination, Modal, Row, Col, Spin, Form, message, Input, Radio } from 'antd';

const Option = Select.Option;

moment.locale('th');

const applicant_statuses = [
  { value: '1', label: 'ผู้สมัคร', tcas_status: '1' },
  { value: '2', label: 'ผ่านการคัดเลือก', tcas_status: '2' },
  { value: '3', label: 'ไม่ผ่านการคัดเลือก', tcas_status: '8' },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ onClose, setIsVisible, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [enrollment, setEnrollment] = useState({});
  const me = useSelector((state) => state.user);
  const [form] = Form.useForm();

  const createEnrollment = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        applicantStatus,
      };

      await callApi({
        url: `/enrollment-offers/${enrollmentOffer._id}`,
        method: 'put',
        body: requestBody,
      });
      setLoading(false);
      if (onClose) {
        onClose();
      }
      message.success('แก้ไขข้อมูลการรับสมัครสำเร็จ');
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={`แก้ไขผลการรับสมัคร`}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        width={800}
        footer={[
          <Button key="back" onClick={() => setIsVisible(false)}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => createEnrollment()}>
            ยืนยัน
          </Button>,
        ]}
      >
        <Spin spinning={loading} delay={0}>
          <div className="t-main">
            <div>
              <div className="sec-form">
                <Form {...layout} form={form} name="control-hooks" onFinish={createEnrollment}>
                  <Row>
                    {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
                    <Col span={16}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'จำเป็นต้องใส่ Email' }]}
                        initialValue={enrollment.email}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'จำเป็นต้องใส่ Name' }]}
                        initialValue={enrollment.name}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: !enrollment._id,
                            message: 'จำเป็นต้องใส่ Password',
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        label="ตำแหน่ง"
                        name="role"
                        rules={[{ required: true, message: 'จำเป็นต้องใส่ ตำแหน่ง' }]}
                        initialValue={enrollment.role || 'staff'}
                      >
                        <Select
                          onChange={(value) => {
                            setRole(value);
                          }}
                        >
                          <Select.Option value={'staff'}>University Admin</Select.Option>
                          <Select.Option value={'junior'}>Junior Admin</Select.Option>
                          <Select.Option value={'senior'}>Senior Admin</Select.Option>
                          {me.role === 'super' && <Select.Option value={'super'}>Super Admin</Select.Option>}
                        </Select>
                      </Form.Item>
                    </Col>
                    )}
                  </Row>

                  <div></div>
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
