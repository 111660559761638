import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AddModal from 'containers/Admin/AddModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [adminDetailModalVisible, setAdminDetailModalVisible] = useState(false);
  // const [selectedAdmin, setSelectedAdmin] = useState();
  const [selectedAdmin, setSelectedAdmin] = useState();

  const [status, setStatus] = useState('all');
  const me = useSelector((state) => state.user);
  const universities = useSelector((state) => state.universities || []);

  // const [creditCount, setCreditCount] = useState()
  const fetchAdmins = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1 };

      const { count, data: admins } = await callApi({
        url: '/users',
        query,
      });

      setAdmins(admins);

      setLoading(false);
    } catch (error) {
      setAdmins([]);
      setLoading(false);
    }
  };

  const fetchUniversities = async () => {
    if (universities.length > 0) {
      return;
    }
    try {
      const result = await callApi({
        url: '/configs/universities',
      });
      dispatch({ type: 'UPDATE_UNIVERSITIES', data: result });

      // setPrograms(programs || []);
    } catch (error) {
      console.log(error);
      // setPrograms([]);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    if (me) {
      fetchUniversities();
    }
  }, [me]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    fetchAdmins(page);
  };

  return (
    <div>
      <div className="t-main">
        <h1>
          <div style={{ float: 'right' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setSelectedAdmin({});
                setAddModalVisible(true);
              }}
            >
              เพิ่มคนดูแลระบบ
            </Button>
          </div>
          คนดูแลระบบ
        </h1>

        <table>
          <thead>
            <tr>
              <th>ชื่อ</th>
              <th>อีเมล</th>
              <th>เบอร์ติดต่อ</th>
              <th>รหัส</th>
              <th>มหาวิทยาลัย</th>
              <th>ตำแหน่ง</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => {
              const university = find(universities, {
                university_id: admin.university_id,
              });
              return (
                <tr>
                  <td>{admin.name}</td>
                  <td>
                    <a
                      onClick={() => {
                        if (me.role !== 'super' && admin.role === 'super') {
                          return;
                        }
                        setSelectedAdmin(admin);
                        setAddModalVisible(true);
                      }}
                    >
                      {admin.email}
                    </a>
                  </td>
                  <td>{admin.telephone}</td>
                  <td>{university && admin.role === 'staff' ? admin.university_id : '-'}</td>
                  <td>{university && admin.role === 'staff' ? university.university_name : '-'}</td>
                  <td>{admin.role}</td>
                  {/* <td>{admin.maxValue}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {addModalVisible && (
        <AddModal
          setIsVisible={setAddModalVisible}
          isVisible={addModalVisible}
          selectedAdmin={selectedAdmin}
          onClose={() => fetchAdmins(currentPage)}
        ></AddModal>
      )}
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
