import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import cn from 'classnames';
import useRound from 'containers/Rounds/useRound';
import { Modal } from 'antd';

const CalendarModal = ({ roundData, isVisible, setIsVisible }) => {
  if (!roundData) return <span></span>;
  const {
    existedKeys,
    registerDateString,
    intervieweeAnnounceDateString,
    intervieweeAnnounceDate2String,
    interviewDateString,
    candidateAnnounceDateString,
    confirmDateString,
    confirmDate2String,
    cancelDateString,
    studentAnnounceDateString,
  } = useRound(roundData);

  return (
    <Modal visible={isVisible} onCancel={() => setIsVisible(false)} footer={null}>
      <table className="event">
        {existedKeys.length ? (
          <tbody>
            {registerDateString && (
              <tr>
                <td>รับสมัคร</td>
                <td>{registerDateString}</td>
              </tr>
            )}
            {intervieweeAnnounceDateString && (
              <tr>
                <td>
                  ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์
                  {intervieweeAnnounceDate2String && ' ครั้งที่ 1'}
                </td>
                <td>{intervieweeAnnounceDateString}</td>
              </tr>
            )}
            {intervieweeAnnounceDate2String && (
              <tr>
                <td>ประกาศรายชื่อผู้มีสิทธิ์สัมภาษณ์ ครั้งที่ 2</td>
                <td>{intervieweeAnnounceDate2String}</td>
              </tr>
            )}
            {interviewDateString && (
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน {interviewDateString}</td>
              </tr>
            )}
            {candidateAnnounceDateString && (
              <tr>
                <td>ประกาศรายชื่อผู้ผ่านการคัดเลือก</td>
                <td>{candidateAnnounceDateString}</td>
              </tr>
            )}
            {confirmDateString && (
              <tr>
                <td>ยืนยันสิทธิ TCAS{confirmDate2String && ' ครั้งที่ 1'}</td>
                <td>{confirmDateString}</td>
              </tr>
            )}
            {confirmDate2String && (
              <tr>
                <td>ยืนยันสิทธิ TCAS ครั้งที่ 2</td>
                <td>{confirmDate2String}</td>
              </tr>
            )}
            {cancelDateString && (
              <tr>
                <td>ยกเลิกสิทธิ TCAS</td>
                <td>{cancelDateString}</td>
              </tr>
            )}
            {studentAnnounceDateString && (
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>{studentAnnounceDateString}</td>
              </tr>
            )}
          </tbody>
        ) : (
          <tr>
            <td>ไม่มีข้อมูล</td>
          </tr>
        )}
      </table>
    </Modal>
  );
};

export default CalendarModal;
