import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import { filterLabelAndValue } from 'helpers/helpers';

import AddModal from 'containers/School/AddModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [schools, setSchools] = useState([]);

  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  const [isOnlyCurrentStudent, setIsOnlyCurrentStudent] = useState(true);

  const [filterQuery, setFilterQuery] = useState({
    school: '',
  });
  let foundSchool = false;
  const fetchSchool = async () => {
    try {
      const result = await callApi({
        url: '/schools',
      });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const onSchoolChange = async (value, option) => {
    setFilterQuery({
      ...filterQuery,
      school: value,
    });
    // setSchoolSearchText('');
    setSelectedSchool(option);
  };
  const fetchSchools = async () => {
    try {
      setLoading(true);

      const result = await callApi({
        url: '/schools/all',
      });
      dispatch({
        type: 'UPDATE_SCHOOLS',
        data: result,
      });
      setSchools(result);
      setLoading(false);
    } catch (error) {
      setSchools([]);
      setLoading(false);
      console.log('errror', error);
    }
  };
  console.log(isOnlyCurrentStudent === 'false', isOnlyCurrentStudent);

  const downloadFile = async () => {
    try {
      setLoading(true);

      const result = await callApi({
        url: `/schools/${filterQuery.school}/enrollments`,
        query: { before65: isOnlyCurrentStudent === 'false' },
      });
      download(result.filePath);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(get(error, 'reason.message') || 'Unable to login, please try again later.');
    }
  };

  useEffect(() => {
    fetchSchools();
  }, []);

  useEffect(() => {
    if (me) {
      // setFilterQuery({ ...filterQuery, university: me.university_id || '001' });
      // fetchSchools();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchSchools(_page);
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchSchools(1);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>ดาวน์โหลดข้อมูลผลการรับสมัครแยกตามโรงเรียน</h1>

          <div className="sec-box">
            <div className="sec-form">
              <div className="col-50">
                <label>โรงเรียน</label>
                <Select
                  showSearch={true}
                  style={{ width: '100%', marginTop: 0 }}
                  value={get(filterQuery, 'school')}
                  size={'large'}
                  dropdownMatchSelectWidth={false}
                  onChange={onSchoolChange}
                  // value={get(filterQuery, 'university')}
                  filterOption={filterLabelAndValue}
                  onSearch={(text) => setSchoolSearchText(text)}
                  onSelect={() => setSchoolSearchText('')}
                >
                  <Option key={'all'} label={''} value={''}>
                    โรงเรียนทั้งหมด
                  </Option>
                  {schools.map((school, index) => {
                    if (schoolSearchText.length < 2 && index > 100) {
                      if (selectedSchool.value === school.value) {
                        foundSchool = true;
                      }
                      return null;
                    }

                    return (
                      <Option key={school.code} label={school.name} value={school.value} index={index}>
                        {school.name}
                      </Option>
                    );
                  })}
                  {selectedSchool.index > 100 && !foundSchool && schoolSearchText.length < 2 && (
                    <Option
                      key={selectedSchool.code}
                      label={selectedSchool.label}
                      value={selectedSchool.value}
                      index={selectedSchool.index}
                    >
                      {selectedSchool.label}
                    </Option>
                  )}
                </Select>
              </div>
              <div className="col-25">
                <label>ปีการศึกษาที่จบ</label>
                <select value={isOnlyCurrentStudent} onChange={(e) => setIsOnlyCurrentStudent(e.target.value)}>
                  <option value={true}>เฉพาะปีปัจจุบัน</option>
                  <option value={false}>เฉพาะปีก่อนหน้า</option>
                </select>
              </div>

              <div className="col-25 col-action">
                <a
                  className={cn('btn-main', {
                    disabled: !(filterQuery.school && filterQuery.school !== 'all'),
                  })}
                  onClick={() => {
                    if (filterQuery.school && filterQuery.school !== 'all') {
                      downloadFile();
                    }
                  }}
                >
                  ดาวน์โหลดข้อมูลผลการรับสมัคร
                </a>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
