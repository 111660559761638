import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import callApi from 'helpers/callapi';

const useMyUniversity = () => {
  const me = useSelector((state) => state.user);

  const [university, setUniversity] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUniversityInfo = async () => {
    try {
      setLoading(true);

      const universityInfo = await callApi({
        url: '/users/me/universities',
      });

      setUniversity({
        ...universityInfo,
        round_1: {
          ...(universityInfo.round_1 || {}),
          file_path: universityInfo.file_path_1,
          uploaded_at: universityInfo.file_path_1_updated_at,
        },
        round_2: {
          ...(universityInfo.round_2 || {}),
          file_path: universityInfo.file_path_2,
          uploaded_at: universityInfo.file_path_2_updated_at,
        },
        round_3: {
          ...(universityInfo.round_3 || {}),
          file_path: universityInfo.file_path_3,
          uploaded_at: universityInfo.file_path_3_updated_at,
        },
        round_4: {
          ...(universityInfo.round_4 || {}),
          file_path: universityInfo.file_path_4,
          uploaded_at: universityInfo.file_path_4_updated_at,
        },
        round_5: {
          file_path: universityInfo.file_path_handicap,
          uploaded_at: universityInfo.file_path_handicap_updated_at,
        },
      });
      console.log(universityInfo.round_1.latest_enrollment_file);
      setLoading(false);
      return universityInfo;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      fetchUniversityInfo();
    }
  }, [me]);

  return { loading, university, setUniversity, fetchUniversityInfo };
};

export default useMyUniversity;
