import React from 'react';
import { useState, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { padStart } from 'lodash';

const Timer = ({ expiryTimestamp }) => {
  const { seconds, minutes, hours, isRunning, start, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  });

  return (
    <div>
      <p>
        เหลือเวลาอีก {padStart(minutes, 2, '0')}:{padStart(seconds, 2, '0')} นาที
      </p>
    </div>
  );
};

export default Timer;
