import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

import CheckStatusModal from './CheckStatus/ImportModal';
// import AdminDetailModal from './AdminModal';

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  // const loaded = useSelector((state) => state.coursesLoaded);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [checkResult, setCheckResult] = useState();

  const [filterQuery, setFilterQuery] = useState({
    citizen_id: '',
    // first_name: '',
    // last_name: '',
  });

  const onCheck = async () => {
    const response = await callApi({ url: '/students/check-status', query: filterQuery });
    setCheckResult(response);
  };

  const _onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onCheck();
    }
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1 className="">
            <div style={{ float: 'right' }}>
              <Button
                type="primary"
                icon={<ImportOutlined />}
                onClick={() => {
                  setImportModalVisible(true);
                }}
              >
                นำเข้าไฟล์เพื่อตรวจสอบสถานะ
              </Button>
            </div>
            ตรวจสอบสถานะผู้สมัคร
          </h1>

          <div className="sec-box">
            <h2>ค้นหานักเรียน</h2>
            <div className="sec-form">
              <div className="col-25">
                <label>รหัสประจำตัว</label>
                <input
                  type="text"
                  value={filterQuery.citizen_id}
                  onChange={(e) => setFilterQuery({ ...filterQuery, citizen_id: e.target.value })}
                  onKeyPress={_onKeyPress}
                />
              </div>
              {/* <div className="col-25">
                <label>ชื่อ</label>
                <input
                  type="text"
                  value={filterQuery.first_name}
                  onChange={(e) => setFilterQuery({ ...filterQuery, first_name: e.target.value })}
                  onKeyPress={_onKeyPress}
                />
              </div>
              <div className="col-25">
                <label>นามสกุล</label>
                <input
                  type="text"
                  value={filterQuery.last_name}
                  onChange={(e) => setFilterQuery({ ...filterQuery, last_name: e.target.value })}
                  onKeyPress={_onKeyPress}
                />
              </div> */}

              <div className="col-25 col-action">
                <a className="btn-main" onClick={onCheck}>
                  ตรวจสอบสถานะ
                </a>
              </div>
            </div>
          </div>

          {checkResult && (
            <div className="sec-box">
              <h2>
                ผลลัพธ์
                {checkResult.first_name ? (
                  <>
                    : {checkResult.first_name} {checkResult.last_name}
                  </>
                ) : (
                  ''
                )}
              </h2>
              <b>Code {checkResult.code}:</b> {checkResult.message}
            </div>
          )}
        </div>
      </Spin>
      <CheckStatusModal
        setIsVisible={setImportModalVisible}
        isVisible={importModalVisible}
        onClose={(result) => {}}
        width={1000}
      />
    </div>
  );
};
