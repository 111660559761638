import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Timer from 'components/TimerOtp';

// Components

import { Button, Table, Pagination, Modal, Row, Col, Spin, Select, Form, message, Input, Radio } from 'antd';

const Option = Select.Option;

moment.locale('th');

export default ({
  onClose,
  setIsVisible,
  isVisible,
  requestParams,
  onSubmitOtp,
  errorMessage,
  onCancel,
  downloading,
}) => {
  const [loading, setLoading] = useState(false);
  const me = useSelector((state) => state.user);
  const [otpResult, setOtpResult] = useState({});
  const [otp, setOtp] = useState('');

  const requestOtp = async () => {
    try {
      setLoading(true);
      const result = await callApi({
        url: `/users/me/get-otp?type=export-submit-${requestParams.roundNumber}`,
      });
      result.expiry = new Date(result.expiry);
      setOtpResult(result);
      setLoading(false);
    } catch (error) {
      console.error('Error while trying to edit user.', error);
      (get(error, 'reason.errors') || []).forEach(({ msg }) => message.error(msg));
      setLoading(false);
    }
  };
  useEffect(() => {
    requestOtp();
  }, []);

  return (
    <div>
      <Modal
        title={``}
        visible={isVisible}
        onCancel={onCancel}
        width={400}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading || downloading}
            onClick={() => onSubmitOtp(otpResult.ref_code, otp)}
          >
            ยืนยัน
          </Button>,
        ]}
      >
        <div>
          <p>กรุณากรอกรหัส OTP ที่ส่งไปยังเบอร์มือถือ {me.telephone}</p>

          <div style={{ marginBottom: 10 }}>
            <label>
              <b>Ref. Code: &nbsp;&nbsp;</b>
              <span>{otpResult.ref_code}</span>
            </label>
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>
              <b>รหัส OTP:&nbsp; &nbsp;&nbsp;</b>
              <input
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                  if (e.target.value.length >= 6) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              ></input>
            </label>
          </div>
          {otpResult && otpResult.expiry && <Timer expiryTimestamp={otpResult.expiry}></Timer>}
          <div className="error">{errorMessage}</div>
        </div>
      </Modal>
    </div>
  );
};
