import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

export default () => {
  const [isVisible, setIsVisible] = useState(false);

  const [isProgress, setIsProgress] = useState(false);

  return (
    <div className="t-main">
      {/* 08.program-edit ============================================================= */}
      <h1>ปรับปรุงข้อมูลหลักสูตร</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            ผู้ดูแลหลักสูตรสามารถแก้ข้อมูลได้ตามแบบฟอร์มด้านล่าง
            <br />
            ระบบจะนำไปแทนที่ข้อมูลเดิมให้ในเวลา 02:00 น. ของทุกวัน
            <br />
            <b>หมายเหตุ:</b> สามารถโหลดไฟล์ย้อนหลังได้ที่หน้า{' '}
            <a href="/logs">
              <b>ประวัติการใช้งาน</b>
            </a>
          </p>
        </div>
        <div className="help">
          <a href="#">
            <i className="i-video"></i>
            วีดีโอวิธีการใช้งาน
          </a>
          <a href="#">
            <i className="i-manual"></i>
            คู่มือการใช้งาน
          </a>
        </div>
      </div>

      <div className="sec-important">
        <h2>มีข้อมูลใหม่รอขึ้นเว็บ อีก 6 ชั่วโมง 20:12 นาที</h2>
        <p>มีการปรับปรุงข้อมูลหลักสูตร รอขึ้นแสดงผลที่หน้าเว็บไซต์ดังนี้</p>
        <ol>
          <li>
            <b className="badge">ปรับปรุง</b>
            <span className="id">10010121300001A</span>
            <span className="course">หลักสูตรวิศวกรรมศาสตรบัณฑิต สาขาวิชาวิศวกรรมศาสตร์</span>
            <a href="#" className="current">
              ข้อมูลปัจจุบัน →
            </a>
            <a href="#" className="new">
              ข้อมูลใหม่ →
            </a>
          </li>
          <li>
            <b className="badge">ปรับปรุง</b>
            <span className="id">10010121300001A</span>
            <span className="course">หลักสูตรวิศวกรรมศาสตรบัณฑิต สาขาวิชาวิศวกรรมเครื่องกล</span>
            <a href="#" className="current">
              ข้อมูลปัจจุบัน →
            </a>
            <a href="#" className="new">
              ข้อมูลใหม่ →
            </a>
          </li>
          <li>
            <b className="badge -green">เพิ่มใหม่</b>
            <span className="id">10010121300001A</span>
            <span className="course">หลักสูตรวิศวกรรมศาสตรบัณฑิต สาขาวิชาวิศวกรรมศาสตร์</span>
            <a href="#" className="current">
              &nbsp;
            </a>
            <a href="#" className="new">
              ข้อมูลใหม่ →
            </a>
          </li>
          <li>
            <b className="badge">ปรับปรุง</b>
            <span className="id">10010121300001A</span>
            <span className="course">หลักสูตรวิศวกรรมศาสตรบัณฑิต สาขาวิชาวิศวกรรมศาสตร์</span>
            <a href="#" className="current">
              ข้อมูลปัจจุบัน →
            </a>
            <a href="#" className="new">
              ข้อมูลใหม่ →
            </a>
          </li>
        </ol>
      </div>

      <ul className="sec-import">
        <li className="round-1">
          <h2>
            <b className="num">1</b>
            <span>เนื้อหาหลักสูตร</span>
          </h2>
          <a className="example" href="#">
            ดาวน์โหลดไฟล์ตั้งต้น
          </a>
          <div className="file">
            <a href="#">
              <i className="i-course"></i>
              <b>course-v1.pdf</b>
              <small>11 พ.ย. 64 12:02 น.</small>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </li>
        <li className="round-2">
          <h2>
            <b className="num">2</b>
            <span>เงื่อนไขการรับ</span>
          </h2>
          <a className="example" href="#">
            ดาวน์โหลดไฟล์ตั้งต้น
          </a>
          <div className="file -none">
            <a href="#">
              <i className="i-course"></i>
              <b>ยังไม่มีข้อมูล</b>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </li>
        <li className="round-3">
          <h2>
            <b className="num">3</b>
            <span>การคำนวณคะแนน</span>
          </h2>
          <a className="example" href="#">
            ดาวน์โหลดไฟล์ตั้งต้น
          </a>
          <div className="file -none">
            <a href="#">
              <i className="i-course"></i>
              <b>ยังไม่มีข้อมูล</b>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </li>
      </ul>

      {/* 07.student-check ============================================================= */}
      <h1 className="t-title">ตรวจสอบสถานะผู้สมัคร</h1>
      <div className="sec-box">
        <h2>ค้นหานักเรียน</h2>
        <div className="sec-form">
          <div className="col-25">
            <label>รหัสประจำตัว</label>
            <input type="text" />
          </div>
          <div className="col-25">
            <label>ชื่อ</label>
            <input type="text" />
          </div>
          <div className="col-25">
            <label>นามสกุล</label>
            <input type="text" />
          </div>

          <div className="col-25 col-action">
            <a className="btn-main">ตรวจสอบสถานะ</a>
          </div>
        </div>
      </div>

      <div className="sec-box">
        <h2>ผลลัพธ์</h2>
        <b>Code 1:</b> ไม่พบเลขบัตรประชาชนนี้ในระบบ ยังไม่ได้ลงทะเบียน
      </div>

      {/* 06.student-list ============================================================= */}
      <h1 className="t-title">
        <span>ข้อมูลนักเรียน</span>

        <span className="action">
          <a className="btn-mini">
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <path
                d="m17 2c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm18 22c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm-28.7773438 2c-2.052 0-3.7226562 1.784516-3.7226562 3.978516v1.521484c0 3.281 1.6953906 6.132344 4.7753906 8.027344 2.591 1.595 6.0446094 2.472656 9.7246094 2.472656 2.19 0 4.431516-.324234 6.478516-.990234-.94-1.799-1.478516-3.839766-1.478516-6.009766 0-3.496 1.386813-6.664 3.632812-9zm28.7773438 1c.552 0 1 .448 1 1v6h6c.552 0 1 .448 1 1s-.448 1-1 1h-6v6c0 .552-.448 1-1 1s-1-.448-1-1v-6h-6c-.552 0-1-.448-1-1s.448-1 1-1h6v-6c0-.552.448-1 1-1z"
                fill="currentColor"
              />
            </svg>
            เพิ่มข้อมูลนักเรียน
          </a>
        </span>
      </h1>
      <div className="sec-box">
        <h2>ค้นหานักเรียน</h2>
        <div className="sec-form">
          <div className="col-50">
            <label>ค้นจากชื่อ, นามสกุล, รหัสประจำตัว, อีเมล, เบอร์ติดต่อ</label>
            <input type="text" />
          </div>
          <div className="col-25">
            <label>โรงเรียน</label>
            <select>
              <option>AAA</option>
              <option>BBB</option>
            </select>
          </div>

          <div className="col-25 col-action">
            <a className="btn-main">ค้นหาข้อมูล</a>
          </div>
        </div>
      </div>
      <p>
        <small className="s-flex">
          <i className="i-none"></i>&nbsp;ยังไม่เข้าระบบ&nbsp;
          <i className="i-yes"></i>&nbsp;ยืนยันแล้ว&nbsp;
          <i className="i-no"></i>&nbsp;เข้าระบบแล้วแต่ยืนยันไม่สำเร็จ
        </small>
      </p>
      <table className="t-table -students">
        <thead>
          <tr>
            <th></th>
            <th>รหัสประจำตัว</th>
            <th>ชื่อ นามสกุล</th>
            <th>โรงเรียน</th>
            <th>ปีการศึกษาที่จบ</th>
            <th>จังหวัด</th>
            <th>เบอร์ติดต่อ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <i className="i-yes"></i>
            </td>
            <td>123456789012</td>
            <td>นายสมชาย รักการเรียน</td>
            <td>วิทยาลัยอาชีวศึกษานครปฐม</td>
            <td>2563</td>
            <td>นครปฐม</td>
            <td>0891234567</td>
          </tr>
          <tr>
            <td>
              <i className="i-none"></i>
            </td>
            <td>123456789012</td>
            <td>นายสมชาย รักการเรียน</td>
            <td>วิทยาลัยอาชีวศึกษานครปฐม</td>
            <td>2563</td>
            <td>นครปฐม</td>
            <td>0891234567</td>
          </tr>
          <tr>
            <td>
              <i className="i-no"></i>
            </td>
            <td>123456789012</td>
            <td>นายสมชาย รักการเรียน</td>
            <td>วิทยาลัยอาชีวศึกษานครปฐม</td>
            <td>2563</td>
            <td>นครปฐม</td>
            <td>0891234567</td>
          </tr>
        </tbody>
      </table>
      {/* 07.student-detail ============================================================= */}
      <hr className="hr-full" />
      <h1 className="t-title">นายสมชาย รักการเรียน</h1>

      <table className="t-table -profile">
        <thead>
          <tr>
            <th>ข้อมูลส่วนบุคคล </th>
            <th>
              <a className="btn-ghost">แก้ไขข้อมูลส่วนบุคคล</a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>ชื่อ-นามสกุล</th>
            <td> นายสมชาย รักการเรียน</td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>
              ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูล
              รายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูล รายละเอียดต่างๆ
              ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูลรายละเอียดต่างๆ ข้อมูล รายละเอียดต่างๆ ข้อมูล
              รายละเอียดต่างๆ
            </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>

          <tr className="heading">
            <th>ข้อมูลการศึกษา </th>
            <th>
              <a className="btn-ghost">แก้ไขข้อมูลการศึกษา</a>
            </th>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>

          <tr className="heading">
            <th>ข้อมูล 8 กลุ่มสาระฯ</th>
            <th>
              <a className="btn-ghost">แก้ไขข้อมูล 8 กลุ่มสาระฯ</a>
            </th>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr className="heading">
            <th>คะแนนภาษาอังกฤษ</th>
            <th>
              <a className="btn-ghost">แก้ไขคะแนนภาษาอังกฤษ</a>
            </th>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr className="heading">
            <th>ข้อมูลบุคคลที่ติดต่อได้</th>
            <th>
              <a className="btn-ghost">แก้ไขข้อมูลบุคคลที่ติดต่อได้</a>
            </th>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr className="heading">
            <th>ข้อมูลทั่วไป</th>
            <th>
              <a className="btn-ghost">แก้ไขข้อมูลทั่วไป</a>
            </th>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
          <tr>
            <th>ชื่อฟีลด์</th>
            <td>ข้อมูลรายละเอียดต่างๆ </td>
          </tr>
        </tbody>
      </table>

      {/* 00.admin-dashboard ============================================================= */}
      <h1>ภาพรวมสำหรับผู้ดูแล</h1>

      <div className="sec-dashboard">
        <div className="sec-box">
          <div className="title">
            <h3>มหาวิทยาลัย</h3>
            <b>129</b>

            <div className="no">
              <i className="i-no"></i> 30
            </div>

            <div className="yes">
              <i className="i-yes"></i> 99
            </div>
          </div>
          <div className="graph">
            <div className="t-scores">
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
            </div>
            <table className="detail">
              <tbody>
                <tr>
                  <td>ทปอ</td>
                  <td>80</td>
                </tr>
                <tr>
                  <td>มรภ</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>มทร</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>เอกชน</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>สมทบ</td>
                  <td>8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="sec-box">
          <div className="title">
            <h3>หลักสูตร</h3>
            <b>153</b>
          </div>
          <div className="graph">
            <div className="t-scores">
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
            </div>
            <table className="detail">
              <tbody>
                <tr>
                  <td>ประเภท A</td>
                  <td>80</td>
                </tr>
                <tr>
                  <td>ประเภท B</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>ประเภท C</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>ประเภท D</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>อื่นๆ</td>
                  <td>8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="sec-box">
          <div className="title">
            <h3>โครงการ</h3>
            <b>525</b>
          </div>
          <div className="graph">
            <div className="t-scores">
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
            </div>
            <table className="detail">
              <tbody>
                <tr>
                  <td>รอบ 1</td>
                  <td>80</td>
                </tr>
                <tr>
                  <td>รอบ 2</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>รอบ 3</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>รอบ 4</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>ผู้พิการ</td>
                  <td>8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="sec-box">
          <div className="title">
            <h3>นักเรียน</h3>
            <b>300K</b>
            <h3>โรงเรียน</h3>
            <b>6.9K</b>
          </div>
          <div className="graph">
            <div className="t-scores">
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
              <b style={{ width: 20 + '%' }}></b>
            </div>
            <table className="detail">
              <tbody>
                <tr>
                  <td>หลักสูตร 1</td>
                  <td>80</td>
                </tr>
                <tr>
                  <td>หลักสูตร 2</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>หลักสูตร 3</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>หลักสูตร 4</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>อื่นๆ</td>
                  <td>8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <h2>สถานะข้อมูลของมหาวิทยาลัย</h2>
      <div className="sec-box">
        <div className="sec-form">
          <div className="col-25">
            <label>มหาวิทยาลัย</label>
            <select>
              <option>ทุกมหาวิทยาลัย</option>
              <option>มหาวิทยาลัยพะเยา</option>
            </select>
          </div>
          <div className="col-50">
            <label>เลือกรอบที่ยังไม่มีข้อมูล</label>
            <div className="group">
              <label>
                <input type="checkbox" /> 1
              </label>
              <label>
                <input type="checkbox" /> 2
              </label>
              <label>
                <input type="checkbox" /> 3
              </label>
              <label>
                <input type="checkbox" /> 4
              </label>
              <label>
                <input type="checkbox" /> ผูู้พิการ
              </label>
            </div>
          </div>
          <div className="col-25 col-action">
            <a className="btn-main">ค้นหาข้อมูล</a>
          </div>
        </div>
      </div>

      <table className="t-table -ustatus">
        <thead>
          <tr>
            <th></th>
            <th>ชื่อ</th>
            <th>หลักสูตร</th>
            <th>เงื่อนไขการรับ</th>
            <th>1. Porfolio</th>
            <th>2. Quota</th>
            <th>3. Admission</th>
            <th>4. Direct Admission</th>
            <th>ผู้พิการ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <i className="i-no"></i>
            </td>
            <td>มหาวิทยาลัยศิลปากร</td>
            <td>14</td>
            <td>32</td>
            <td>
              <i className="i-book-no"></i>
              <i className="i-calendar-no"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
              <i className="i-course-no"></i>
              <i className="i-condition-no"></i>
              <i className="i-cal-no"></i>
            </td>
            <td>
              <i className="i-book-none"></i>
              <i className="i-calendar-none"></i>
            </td>
            <td>
              <i className="i-book-none"></i>
            </td>
          </tr>
          <tr>
            <td>
              <i className="i-yes"></i>
            </td>
            <td>มหาวิทยาลัยแม่ฟ้าหลวง</td>
            <td>10</td>
            <td>25</td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
              <i className="i-course-yes"></i>
              <i className="i-condition-yes"></i>
              <i className="i-cal-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
              <i className="i-calendar-yes"></i>
            </td>
            <td>
              <i className="i-book-yes"></i>
            </td>
          </tr>
        </tbody>
      </table>

      {/* 01.dashboard.js ============================================================= */}
      <hr className="hr-full" />
      <h1>ภาพรวม</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            หน้าหลักสำหรับเจ้าหน้าที่มหาวิทยาลัย <br />
            อธิบายเมนูต่างๆ สั้นๆ
            <br />
            คำแนะนำต่างๆ
            <br />
            แนะนำให้ใส่ 4-5 บรรทัด
          </p>
        </div>
        <div className="help">
          <a href="#">
            <i className="i-video"></i>
            วีดีโอวิธีการใช้งาน
          </a>
          <a href="#">
            <i className="i-manual"></i>
            คู่มือการใช้งาน
          </a>
        </div>
      </div>

      <div className="sec-announce">
        <h2>ประกาศ</h2>
        ข้อความประกาศ รวมถึงคำเตือนกรณียังส่งข้อมูลไม่ครบ
      </div>

      <div className="sec-university-info">
        <div className="u-1">
          <h2>
            <b>1</b>Portfolio
          </h2>
          <ul>
            <li>
              <i className="i-yes"></i> เอกสารเผยแพร่
            </li>
            <li>
              <i className="i-yes"></i> กำหนดการ
            </li>
          </ul>
        </div>
        <div className="u-2">
          <h2>
            <b>2</b>Quota
          </h2>
          <ul>
            <li>
              <i className="i-yes"></i> เอกสารเผยแพร่
            </li>
            <li>
              <i className="i-yes"></i> กำหนดการ
            </li>
          </ul>
        </div>
        <div className="u-3">
          <h2>
            <b>3</b>Admission
          </h2>
          <ul>
            <li>
              <i className="i-yes"></i> เอกสารเผยแพร่
            </li>
            <li>
              <i className="i-yes"></i> กำหนดการ
            </li>
            <li>
              <i className="i-no"></i> เนื้อหาหลักสูตร
            </li>
            <li>
              <i className="i-no"></i> เงื่อนไขการรับ
            </li>
            <li>
              <i className="i-no"></i> การคำนวณคะแนน
            </li>
          </ul>
        </div>
        <div className="u-4">
          <h2>
            <b>4</b>Direct Admission
          </h2>
          <ul>
            <li>
              <i className="i-none"></i> เอกสารเผยแพร่
            </li>
            <li>
              <i className="i-none"></i> กำหนดการ
            </li>
          </ul>
        </div>
        <div className="u-5">
          <h2>
            <b>
              <i className="i-disabled"></i>
            </b>
            ผู้พิการ
          </h2>
          <ul>
            <li>
              <i className="i-none"></i> เอกสารเผยแพร่
            </li>
          </ul>
        </div>
      </div>

      {/* 02.admissions ============================================================= */}
      <hr className="hr-full" />
      <h1>การรับสมัคร</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            ทางมหาวิทยาลัยสามารถเพิ่มข้อมูลทั้งชื่อกำหนดการ, วันที่ และไฟล์ข้อมูลแต่ละรอบได้
            <br />
            กำหนดการต่างๆ จะนำไปแจ้งเตือนผู้สมัครที่ติดตามหลักสูตรของมหาวิทยาลัย
            <br />
            ส่วนไฟล์ข้อมูลแต่ละรอบ จะนำไปแสดงในหน้าข้อมูลของมหาวิทยาลัย
          </p>
        </div>
        <div className="help">
          <a href="#">
            <i className="i-video"></i>
            วีดีโอวิธีการใช้งาน
          </a>
          <a href="#">
            <i className="i-manual"></i>
            คู่มือการใช้งาน
          </a>
        </div>
      </div>

      <ul className="sec-admissions">
        <li className="round-1">
          <h2>
            <b className="num">1</b>
            <span>Portfolio</span>
          </h2>
          <table className="event">
            <tbody>
              <tr>
                <td>รับสมัคร</td>
                <td>1 พ.ย. - 20 ธ.ค. 64</td>
              </tr>
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน 28 ม.ค. 65</td>
              </tr>
              <tr>
                <td>ยืนยันสิทธิ TCAS</td>
                <td>7 - 8 ก.พ. 65</td>
              </tr>
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>11 ก.พ. 65</td>
              </tr>
            </tbody>
          </table>
          <div className="file">
            <a href="#">
              <i className="i-book"></i>
              <b>Portfolio-v1.pdf</b>
              <small>11 พ.ย. 64 12:02 น.</small>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              แก้ไขข้อมูล
            </a>
          </div>
        </li>
        <li className="round-2">
          <h2>
            <b className="num">2</b>
            <span>Quota</span>
          </h2>
          <table className="event">
            <tbody>
              <tr>
                <td>รับสมัคร</td>
                <td>1 พ.ย. - 20 ธ.ค. 64</td>
              </tr>
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน 28 ม.ค. 65</td>
              </tr>
              <tr>
                <td>ยืนยันสิทธิ TCAS</td>
                <td>7 - 8 ก.พ. 65</td>
              </tr>
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>11 ก.พ. 65</td>
              </tr>
            </tbody>
          </table>
          <div className="file">
            <a href="#">
              <i className="i-book"></i>
              <b>Quota-v1.pdf</b>
              <small>11 พ.ย. 64 12:02 น.</small>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              แก้ไขข้อมูล
            </a>
          </div>
        </li>
        <li className="round-3">
          <h2>
            <b className="num">3</b>
            <span>Admission</span>
          </h2>
          <table className="event">
            <tbody>
              <tr>
                <td>รับสมัคร</td>
                <td>1 พ.ย. - 20 ธ.ค. 64</td>
              </tr>
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน 28 ม.ค. 65</td>
              </tr>
              <tr>
                <td>ยืนยันสิทธิ TCAS</td>
                <td>7 - 8 ก.พ. 65</td>
              </tr>
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>11 ก.พ. 65</td>
              </tr>
            </tbody>
          </table>
          <div className="file">
            <a href="#">
              <i className="i-book"></i>
              <b>Admission-v1.pdf</b>
              <small>11 พ.ย. 64 12:02 น.</small>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              แก้ไขข้อมูล
            </a>
          </div>
        </li>
        <li className="round-4">
          <h2>
            <b className="num">4</b>
            <span>Direct Admission</span>
          </h2>
          <table className="event">
            <tbody>
              <tr>
                <td>รับสมัคร</td>
                <td>1 พ.ย. - 20 ธ.ค. 64</td>
              </tr>
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน 28 ม.ค. 65</td>
              </tr>
              <tr>
                <td>ยืนยันสิทธิ TCAS</td>
                <td>7 - 8 ก.พ. 65</td>
              </tr>
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>11 ก.พ. 65</td>
              </tr>
            </tbody>
          </table>
          <div className="file">
            <a href="#">
              <i className="i-book"></i>
              <b>Direct-Admission-v1.pdf</b>
              <small>11 พ.ย. 64 12:02 น.</small>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              แก้ไขข้อมูล
            </a>
          </div>
        </li>
        <li className="round-5">
          <h2>
            <b className="num">
              <i className="i-disabled"></i>
            </b>
            <span>ผู้พิการ</span>
          </h2>
          <table className="event">
            <tbody>
              <tr>
                <td>รับสมัคร</td>
                <td>1 พ.ย. - 20 ธ.ค. 64</td>
              </tr>
              <tr>
                <td>สอบสัมภาษณ์</td>
                <td>ภายใน 28 ม.ค. 65</td>
              </tr>
              <tr>
                <td>ยืนยันสิทธิ TCAS</td>
                <td>7 - 8 ก.พ. 65</td>
              </tr>
              <tr>
                <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
                <td>11 ก.พ. 65</td>
              </tr>
            </tbody>
          </table>
          <div className="file -none">
            <a href="#">
              <i className="i-book"></i>
              <b>ยังไม่มีข้อมูล</b>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              แก้ไขข้อมูล
            </a>
          </div>
        </li>
      </ul>

      {/* 02.admissions - Modal ============================================================= */}
      <Modal visible={isVisible} width={720} footer={[]}>
        <h2>1. Portfolio</h2>
        <table className="event">
          <tbody>
            <tr>
              <td>
                <i className="i-del"></i>
              </td>
              <td>รับสมัคร</td>
              <td>1 พ.ย. - 20 ธ.ค. 64</td>
            </tr>
            <tr>
              <td>
                <i className="i-del"></i>
              </td>
              <td>สอบสัมภาษณ์</td>
              <td>ภายใน 28 ม.ค. 65</td>
            </tr>
            <tr>
              <td>
                <i className="i-del"></i>
              </td>
              <td>ยืนยันสิทธิ TCAS</td>
              <td>7 - 8 ก.พ. 65</td>
            </tr>
            <tr>
              <td>
                <i className="i-del"></i>
              </td>
              <td>ประกาศผลผู้มีสิทธิเข้าศึกษา</td>
              <td>11 ก.พ. 65</td>
            </tr>
          </tbody>
        </table>
        <h3>เพิ่มกำหนดการ</h3>
        <div className="sec-form">
          <div className="col-25">
            <label>ชื่อกำหนดการ</label>
            <select>
              <option>รับสมัคร</option>
              <option>ยืนยันสิทธิ</option>
            </select>
            {/* <input type="text" /> */}
          </div>
          <div className="col-25">
            <label>วันที่เริ่มต้น</label>
            <input type="date" />
          </div>
          <div className="col-25">
            <label>วันที่เริ่มต้น</label>
            <input type="date" />
          </div>
          <div className="col-25 col-action">
            <a className="btn-main">เพิ่มรายการ</a>
          </div>
        </div>
        <hr className="hr-full" />
        <h3>ไฟล์สำหรับเผยแพร่</h3>
        <p>หากเพิ่มไฟล์ใหม่ ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์ใหม่ไปแทนที่</p>
        <small>(แสดงข้อความดังนี้)</small>
        <br />
        <small>(1. กรณียังไม่มีไฟล์)</small>
        <p>เลือกไฟล์ที่ต้องการเผยแพร่ แล้วคลิกปุ่มนำเข้าข้อมูล</p>
        <small>(2. กรณีมีไฟล์แล้ว)</small>
        <p>เลือกไฟล์ที่ต้องการเผยแพร่ ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์นี้ไปแทนที่ไฟล์เก่า</p>
        <p className="s-flex">
          <i className="i-book"></i> portfolio-v1.pdf
        </p>
        <div className="sec-form">
          <div className="col-75">
            <input type="file" />
            <div className={isProgress ? 't-progress active' : 't-progress'}>
              <b></b>
            </div>
          </div>
          <div className="col-25">
            <a className="btn-main" onClick={() => setIsProgress(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </div>
        <small>(ตัวอย่างอีก Modal)</small>
        <h2>2. เงื่อนไขการรับ</h2>
        <small>(แสดงข้อความดังนี้)</small>
        <br />
        <small>(1. กรณียังไม่มีไฟล์)</small>
        <p>เลือกไฟล์ที่ต้องการนำเข้า แล้วคลิกปุ่มนำเข้าข้อมูล</p>
        <small>(2. กรณีมีไฟล์แล้ว เป็นไฟล์อันดับท้ายสุด)</small>
        <p>เลือกไฟล์ที่ต้องการนำเข้า ระบบจะลบไฟล์เดิมทิ้ง และนำไฟล์นี้ไปแทนที่ไฟล์เก่า</p>
        <small>
          (3. กรณีมีไฟล์แล้ว แต่ไม่ใช่ไฟล์อันดับท้ายสุด แบบนี้ให้ alert ว่า - ข้อมูลเก่าทั้งหมดจะถูกลบ กรุณาคลิกยืนยัน)
        </small>
        <p className="s-flex">
          <i className="i-course"></i> Course-v1.pdf
        </p>
        <div className="sec-form">
          <div className="col-75">
            <input type="file" />
          </div>
          <div className="col-25">
            <a className="btn-main">
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </div>
        <small>(ตัวอย่างอีก Modal)</small>
        <h2>แก้ไขข้อมูลส่วนตัว</h2>
        <div className="sec-form">
          <div className="col-50">
            <label>ชื่อฟีลด์</label>
            <input type="text" />
          </div>
          <div className="col-50">
            <label>ชื่อฟีลด์</label>
            <input type="text" />
          </div>
          <div className="col-50">
            <label>ชื่อฟีลด์</label>
            <input type="text" />
          </div>
          <div className="col-50">
            <label>ชื่อฟีลด์</label>
            <input type="text" />
          </div>
        </div>

        <a className="btn-main">บันทึกข้อมูล</a>
      </Modal>

      {/* 03.program-import ============================================================= */}
      <hr className="hr-full" />
      <h1>นำข้อมูลเข้าระบบ</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            ข้อมูลเนื้อหาหลักสูตร จะนำไปใช้แสดงผลให้ผู้สมัครใน หน้าหลักสูตร
            <br />
            ส่วนข้อมูลเงื่อนไขการรับและการคำนวณคะแนน จะนำไปใช้ในรอบที่ 3<br />
            (Admission) การนำเข้าข้อมูล ต้องเรียงลำดับ 1-3 เท่านั้น
          </p>
        </div>
        <div className="help">
          <a href="#">
            <i className="i-video"></i>
            วีดีโอวิธีการใช้งาน
          </a>
          <a href="#">
            <i className="i-manual"></i>
            คู่มือการใช้งาน
          </a>
        </div>
      </div>

      <div className="sec-error">
        <h2>ไม่สามารถอัปโหลดได้</h2>
        <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ ทั้งหมด 120 แถว แสดง 10 แถวแรกดังนี้</p>
        <ul>
          <li>
            แถวที่ <b className="index">13</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">19</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">22</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">32</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">39</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">49</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">50</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">53</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">58</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
          <li>
            แถวที่ <b className="index">70</b> <b className="msg">type ไม่ถูกต้อง</b> type 31_2564 ต้องไม่ระบุ
            receive_add_limit
          </li>
        </ul>
      </div>

      <ul className="sec-import">
        <li className="round-1">
          <h2>
            <b className="num">1</b>
            <span>เนื้อหาหลักสูตร</span>
          </h2>
          <a className="example" href="#">
            ดาวน์โหลดไฟล์ตัวอย่าง
          </a>
          <div className="file">
            <a href="#">
              <i className="i-course"></i>
              <b>course-v1.pdf</b>
              <small>11 พ.ย. 64 12:02 น.</small>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </li>
        <li className="round-2">
          <h2>
            <b className="num">2</b>
            <span>เงื่อนไขการรับ</span>
          </h2>
          <a className="example" href="#">
            ดาวน์โหลดไฟล์ตัวอย่าง
          </a>
          <div className="file -none">
            <a href="#">
              <i className="i-course"></i>
              <b>ยังไม่มีข้อมูล</b>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </li>
        <li className="round-3">
          <h2>
            <b className="num">3</b>
            <span>การคำนวณคะแนน</span>
          </h2>
          <a className="example" href="#">
            ดาวน์โหลดไฟล์ตัวอย่าง
          </a>
          <div className="file -none">
            <a href="#">
              <i className="i-course"></i>
              <b>ยังไม่มีข้อมูล</b>
            </a>
          </div>
          <div className="action">
            <a className="btn-main" onClick={() => setIsVisible(true)}>
              <i className="i-upload"></i> นำเข้าข้อมูล
            </a>
          </div>
        </li>
      </ul>

      {/* 04.program-list ============================================================= */}
      <hr className="hr-full" />
      <h1 className="t-title">
        <span>ข้อมูลหลักสูตร</span>
        <span className="action">
          <a className="btn-mini">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
              <polyline points="13 2 13 9 20 9"></polyline>
            </svg>
            ไฟล์ตัวอย่าง
          </a>
          <a className="btn-mini">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            นำเข้าเงื่อนไขการคัดเลือก
          </a>
          <a className="btn-mini">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
            </svg>
            ดาวน์โหลดข้อมูลหลักสูตร
          </a>
        </span>
      </h1>
      <div className="sec-box">
        <h2>ค้นหาหลักสูตร</h2>
        <div className="sec-form">
          <div className="col-25">
            <label>มหาวิทยาลัย</label>
            <select>
              <option>มหาวิทยาลัยแม่ฟ้าหลวง</option>
              <option>มหาวิทยาลัยพะเยา</option>
            </select>
          </div>
          <div className="col-25">
            <label>ชื่อคณะ</label>
            <select>
              <option>คณะวิศวกรรมศาสตร์</option>
              <option>คณะอักษรศาสตร์</option>
            </select>
          </div>
          <div className="col-25">
            <label>ชื่อหลักสูตร</label>
            <select>
              <option>10310104100101A: แพทย์ศาสตร์บัณฑิต</option>
              <option>10310104100101A: แพทย์ศาสตร์บัณฑิต</option>
            </select>
          </div>
          <div className="col-25 col-action">
            <a className="btn-main">ค้นหาข้อมูล</a>
          </div>
        </div>
      </div>
      <table className="t-table -programs">
        <thead>
          <tr>
            <th>รอบ</th>
            <th>รหัสหลักสูตร</th>
            <th>ชื่อหลักสูตร</th>
            <th>เงื่อนไขการรับ</th>
            <th>การคำนวณคะแนน</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>3</b>
              <br />
              รับ
              <br />
              <b>50</b>
            </td>
            <td>
              <b>PRG</b> 10310101100101A
              <br />
              <b>MJR</b> 1234
              <br />
              <b>PRJ</b> P2102
              <br />
              <b>JID</b> 2231
              <br />
            </td>
            <td>
              หลักสูตรวิศวกรรมศาสตร์บัณฑิต สาขาวิศวกรรมคอมพิวเตอร์
              <br />
              วิศวกรรมศาสตร์ <br />
              โครงการโอลิมปิกวิชาการเข้าศึกษาในสาขาวิศวกรรมคอมพิวเตอร์
            </td>
            <td>
              <i className="i-yes"></i>แกนกลาง
              <i className="i-yes"></i>นานาชาติ
              <i className="i-yes"></i>กศน.
              <br /> GPAX <b>2.5+</b>
              <br />
              กลุ่มสาระคณิตฯ <b>12</b>
              <br />
              กลุ่มสาระวิทย์ฯ <b>22</b>
            </td>
            <td>
              <div className="t-scores">
                <b className="gat" style={{ width: 20 + '%' }}></b>
                <b className="pat1" style={{ width: 20 + '%' }}></b>
                <b className="pat3" style={{ width: 60 + '%' }}></b>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>GAT</td>
                    <td>20%</td>
                  </tr>
                  <tr>
                    <td>PAT1</td>
                    <td>20%</td>
                  </tr>
                  <tr>
                    <td>PAT3</td>
                    <td>60%</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {/* 05.program-single ============================================================= */}
      <hr className="hr-full" />
      <h1 className="t-title">
        <span>หลักสูตรวิศวกรรมศาสตร์บัณฑิต สาขาวิศวกรรมคอมพิวเตอร์</span>
        <small className="action">
          รับ <b>50 คน</b>
        </small>
      </h1>
      <table className="t-table">
        <thead>
          <tr>
            <th>รหัสหลักสูตร</th>
            <th>ชื่อหลักสูตร</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <th>Program</th>
                    <td>10010121300001A</td>
                  </tr>
                  <tr>
                    <th>Project</th>
                    <td>P2102</td>
                  </tr>
                  <tr>
                    <th>Major</th>
                    <td>1234</td>
                  </tr>
                  <tr>
                    <th>Round</th>
                    <td>3_2565</td>
                  </tr>
                  <tr>
                    <th>Join ID</th>
                    <td>2231</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    <th>หลักสูตร</th>
                    <td>หลักสูตรวิศวกรรศาสตร์บัณฑิต สาขาวิศวกรรมคอมพิวเตอร์</td>
                  </tr>
                  <tr>
                    <th>โครงการ</th>
                    <td>โครงการโอลิมปิกวิชาการเข้าศึกษาในสาขาวิศวกรรมคอมพิวเตอร์</td>
                  </tr>
                  <tr>
                    <th>เมเจอร์</th>
                    <td>วิศวกรรมศาสตร์</td>
                  </tr>
                  <tr>
                    <th>รอบที่</th>
                    <td>3</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr className="heading">
            <th>เงื่อนไขผู้สมัคร</th>
            <th>เงื่อนไขการรับ</th>
          </tr>
          <tr>
            <td>
              รับผู้สมัครที่จบจาก
              <div>
                <i className="i-yes"></i> หลักสูตรแกนกลาง
              </div>
              <div>
                <i className="i-yes"></i> หลักสูตรนานาชาติ
              </div>
              <div>
                <i className="i-yes"></i> หลักสูตรกศน.
              </div>
            </td>
            <td>
              <div className="s-flex">
                <div className="col-50">
                  <div>
                    <i className="i-yes"></i> GPAX <b>2.5+</b>
                  </div>
                  <div>
                    <i className="i-yes"></i> กลุ่มสาระคณิตฯ <b>12</b>
                  </div>
                  <div>
                    <i className="i-yes"></i> กลุ่มสาระวิทย์ฯ <b>22</b>
                  </div>
                </div>
                <div className="col-50">
                  <div className="t-scores">
                    <b className="gat" style={{ width: 20 + '%' }}></b>
                    <b className="pat1" style={{ width: 20 + '%' }}></b>
                    <b className="pat3" style={{ width: 60 + '%' }}></b>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>GAT</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>PAT1</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>PAT3</td>
                        <td>60%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr className="heading">
            <th colSpan="2">รายละเอียดหลักสูตร</th>
          </tr>
          <tr>
            <td colSpan="2">
              <h2>ข้อมูลรายละเอียด</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed mattis velit. Donec vehicula,
                lectus sit amet finibus scelerisque, leo mauris scelerisque ligula, eu luctus neque justo pulvinar sem.
                Morbi faucibus lobortis quam, sed egestas tellus imperdiet sed.{' '}
              </p>
              <h2>ข้อมูลเงื่อนไขอื่นๆ</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed mattis velit. Donec vehicula,
                lectus sit amet finibus scelerisque, leo mauris scelerisque ligula, eu luctus neque justo pulvinar sem.
                Morbi faucibus lobortis quam, sed egestas tellus imperdiet sed.{' '}
              </p>
              <h2>ข้อมูลการสัมภาษณ์</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sed mattis velit. Donec vehicula,
                lectus sit amet finibus scelerisque, leo mauris scelerisque ligula, eu luctus neque justo pulvinar sem.
                Morbi faucibus lobortis quam, sed egestas tellus imperdiet sed.{' '}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
