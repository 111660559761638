import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import cn from 'classnames';
import { get } from 'lodash';

export default function ({ scores, subjectDictionary }) {
  // const [phone, setPhone] = useState('+66909607070');
  // const [confirmResult, setConfirmResult] = useState(null);
  useEffect(() => {}, []);

  if (!scores) {
    return <span></span>;
  }
  if (!(scores.cal_score_sum && scores.cal_subject_name && scores.cal_type)) {
    return <span></span>;
  }

  const prefixText = 'ผลรวมของ<br/>';
  let displayText = '';

  const orCal = scores.cal_subject_name.trim().split('|');
  for (let i = 0; i < orCal.length; i++) {
    const sumCal = orCal[i].trim().split(' ');
    let phase = prefixText;

    sumCal.forEach((subjectKey) => {
      phase += `${get(subjectDictionary, `${subjectKey}.short_label`)} <br/>`;
    });
    displayText += phase;
    if (i + 1 < orCal.length) {
      displayText += '<br/>หรือ';
    }
  }
  return (
    <tr key={'complex'}>
      <td>
        <span dangerouslySetInnerHTML={{ __html: displayText }}></span>
      </td>
      <td>{scores.cal_score_sum}%</td>
    </tr>
  );
}
