import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Components

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { map, get, result, find } from 'lodash';
import EditModal from './EditModal';

const programCodeName = {
  1: 'หลักสูตรแกนกลาง (สามัญ)',
  2: 'หลักสูตรนานาชาติ',
  3: 'หลักสูตรอาชีวะ',
  4: 'หลักสูตรตามอัธยาศัย (กศน.)',
  5: 'หลักสูตรนานาชาติจบจากต่างประเทศ',
  6: 'สอบเทียบ GED',
};

const SENIOR_EDIT_FIELD = [
  { label: 'เบอร์โทรศัพท์', key: 'telephone' },
  { label: 'อีเมล', key: 'email', required: true },
  { label: 'gpax5', key: 'gpax5_score', type: 'number', required: true },
  { label: 'gpax6', key: 'gpax6_score', type: 'number' },
];

const PROFILE_FIELDS = [
  { label: 'คำนำหน้าชื่อ', key: 'title', noShow: true },
  { label: 'ชื่อ', key: 'first_name', noShow: true },
  { label: 'นามสกุล', key: 'last_name', noShow: true },
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'ชื่อ(EN)', key: 'first_name_additional' },
  { label: 'นามสกุล(EN)', key: 'last_name_additional' },

  { label: 'หมายเลขประจำตัว', key: 'citizen_id', required: true },
  { label: 'เบอร์โทรศัพท์', key: 'telephone' },
  { label: 'อีเมล', key: 'email' },
  { label: 'น้ำหนัก', key: 'weight' },
  { label: 'ส่วนสูง', key: 'height' },

  { label: 'วันเกิด', key: 'birth_date' },

  { label: 'ชื่อ - นามสกุลผู้ปกครอง', key: 'parent_name' },
  { label: 'เบอร์โทรศัพท์ผู้ปกครอง', key: 'parent_telephone' },
  { label: 'อีเมลผู้ปกครอง', key: 'parent_email' },

  { label: 'ชื่อบิดา', key: 'father_name' },
  { label: 'เบอร์โทรศัพท์บิดา', key: 'father_telephone' },
  { label: 'ชื่อมารดา', key: 'mother_name' },
  { label: 'เบอร์โทรศัพท์มารดา', key: 'mother_telephone' },
];

const ADDRESS_FIELDS = [
  { label: 'รหัสจังหวัด', key: 'province_id', noShow: true },
  { label: 'รหัสอำเภอ', key: 'amph_id', noShow: true },
  { label: 'รหัสตำบล', key: 'tmbl_id', noShow: true },
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'ที่อยู่', key: 'address' },
  { label: 'ตำบล', key: 'tmbl_name' },
  { label: 'อำเภอ', key: 'amph_name' },
  { label: 'จังหวัด', key: 'province_name' },

  { label: 'รหัสไปรษณีย์', key: 'zipcode' },
];

const SCHOOL_FIELDS = [
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'ปีการศึกษา', key: 'school_year' },
  { label: 'รหัสโรงเรียน', key: 'school_code' },
  { label: 'ชื่อโรงเรียน', key: 'school_name' },
  { label: 'โปรแกรมที่เรียน', key: 'program_code' },
  { label: 'จังหวัดโรงเรียน', key: 'school_province_name' },
  { label: 'ที่อยู่โรงเรียน(ต่างประเทศ)', key: 'school_address', isForeign: true },
  { label: 'เมือง', key: 'school_country_state', isForeign: true },
  { label: 'ประเทศ', key: 'school_country_name', isForeign: true },
  { label: 'รหัสไปรษณีย์', key: 'school_zipcode', isForeign: true },
];

const GPA_FIELDS = [
  // {label: 'ชื่อ - นามสกุล', key: ''}
  { label: 'gpax5', key: 'gpax5_score', required: true },
  { label: 'gpax6', key: 'gpax6_score' },
  { label: 'gpa21', key: 'gpa21' },
  { label: 'gpa22', key: 'gpa22' },
  { label: 'gpa23', key: 'gpa23' },
  { label: 'gpa24', key: 'gpa24' },
  { label: 'gpa25', key: 'gpa25' },
  { label: 'gpa26', key: 'gpa26' },
  { label: 'gpa27', key: 'gpa27' },
  { label: 'gpa28', key: 'gpa28' },
  { label: 'gpa29', key: 'gpa29' },
  { label: 'credit gpa21', key: 'credit_gpa21' },
  { label: 'credit gpa22', key: 'credit_gpa22' },
  { label: 'credit gpa23', key: 'credit_gpa23' },
  { label: 'credit gpa24', key: 'credit_gpa24' },
  { label: 'credit gpa25', key: 'credit_gpa25' },
  { label: 'credit gpa26', key: 'credit_gpa26' },
  { label: 'credit gpa27', key: 'credit_gpa27' },
  { label: 'credit gpa28', key: 'credit_gpa28' },
  { label: 'credit gpa29', key: 'credit_gpa29' },
];

const SUB9 = [
  { label: 'วิชาสามัญ ภาษาไทย', key: '9sub_09' },
  { label: 'วิชาสามัญ สังคมศึกษา', key: '9sub_19' },
  { label: 'วิชาสามัญ ภาษาอังกฤษ', key: '9sub_29' },
  { label: 'วิชาสามัญ คณิตศาสตร์', key: '9sub_39' },
  { label: 'วิชาสามัญ ฟิสิกส์', key: '9sub_49' },
  { label: 'วิชาสามัญ เคมี', key: '9sub_59' },
  { label: 'วิชาสามัญ ชีววิทยา', key: '9sub_69' },
  { label: 'วิชาสามัญ คณิตศาสตร์', key: '9sub_89' },
  { label: 'วิชาสามัญ วิทยาศาสตร์ทั่วไป', key: '9sub_99' },

  { label: 'วิชาสามัญ ภาษาไทย T-Score', key: '9sub_09_tscore' },
  { label: 'วิชาสามัญ สังคมศึกษา T-Score', key: '9sub_19_tscore' },
  { label: 'วิชาสามัญ ภาษาอังกฤษ T-Score', key: '9sub_29_tscore' },
  { label: 'วิชาสามัญ คณิตศาสตร์ T-Score', key: '9sub_39_tscore' },
  { label: 'วิชาสามัญ ฟิสิกส์ T-Score', key: '9sub_49_tscore' },
  { label: 'วิชาสามัญ เคมี T-Score', key: '9sub_59_tscore' },
  { label: 'วิชาสามัญ ชีววิทยา T-Score', key: '9sub_69_tscore' },
  { label: 'วิชาสามัญ คณิตศาสตร์ T-Score', key: '9sub_89_tscore' },
  { label: 'วิชาสามัญ วิทยาศาสตร์ทั่วไป T-Score', key: '9sub_99_tscore' },
];

const GATPAT = [
  { label: 'GAT1 ความสามารถในการอ่าน/การเขียน/การคิดเชิงวิเคราะห์/และการแก้โจทย์ปัญหา', key: 'gat1' },
  { label: 'GAT2 ความสามารถในการสื่อสารด้วยภาษาอังกฤษ', key: 'gat2' },
  { label: 'PAT1 ความถนัดทางคณิตศาสตร์ ', key: 'pat1' },
  { label: 'PAT2 ความถนัดทางวิทยาศาสตร์', key: 'pat2' },
  { label: 'PAT3 ความถนัดทางวิศวกรรมศาสตร์', key: 'pat3' },
  { label: 'PAT4 ความถนัดทางสถาปัตยกรรมศาสตร์', key: 'pat4' },
  { label: 'PAT5 ความถนัดทางวิชาชีพครู', key: 'pat5' },
  { label: 'PAT6 ความถนัดทางศิลปกรรมศาสตร์', key: 'pat6' },
  //   { label: 'PAT7 ความถนัดทางภาษา', key: 'pat7' },
  { label: 'PAT7-1 ความถนัดทางภาษาฝรั่งเศส', key: 'pat7_1' },
  { label: 'PAT7-2 ความถนัดทางภาษาเยอรมัน', key: 'pat7_2' },
  { label: 'PAT7-3 ความถนัดทางภาษาญี่ปุ่น', key: 'pat7_3' },
  { label: 'PAT7-4 ความถนัดทางภาษาจีน', key: 'pat7_4' },
  { label: 'PAT7-5 ความถนัดทางภาษาอาหรับ', key: 'pat7_5' },
  { label: 'PAT7-6 ความถนัดทางภาษาบาลี', key: 'pat7_6' },
  { label: 'PAT7-7 ความถนัดทางภาษาเกาหลี', key: 'pat7_7' },

  { label: 'GAT T-Score', key: 'gat_tscore' },
  { label: 'GAT1 T-Score', key: 'gat1_tscore' },
  { label: 'GAT2 T-Score', key: 'gat2_tscore' },
  { label: 'PAT1 T-Score', key: 'pat1_tscore' },
  { label: 'PAT2 T-Score', key: 'pat2_tscore' },
  { label: 'PAT3 T-Score', key: 'pat3_tscore' },
  { label: 'PAT4 T-Score', key: 'pat4_tscore' },
  { label: 'PAT5 T-Score', key: 'pat5_tscore' },
  { label: 'PAT6 T-Score', key: 'pat6_tscore' },
  //   { label: 'PAT7 T-Score', key: 'pat7_tscore' },
  { label: 'PAT7-1 T-Score', key: 'pat7_1_tscore' },
  { label: 'PAT7-2 T-Score', key: 'pat7_2_tscore' },
  { label: 'PAT7-3 T-Score', key: 'pat7_3_tscore' },
  { label: 'PAT7-4 T-Score', key: 'pat7_4_tscore' },
  { label: 'PAT7-5 T-Score', key: 'pat7_5_tscore' },
  { label: 'PAT7-6 T-Score', key: 'pat7_6_tscore' },
  { label: 'PAT7-7 T-Score', key: 'pat7_7_tscore' },
];

const GATPATPAST = [
  { label: 'GAT1 ความสามารถในการอ่าน/การเขียน/การคิดเชิงวิเคราะห์/และการแก้โจทย์ปัญหา', key: 'gat1_past' },
  { label: 'GAT2 ความสามารถในการสื่อสารด้วยภาษาอังกฤษ', key: 'gat2_past' },
  { label: 'PAT1 ความถนัดทางคณิตศาสตร์ ', key: 'pat1_past' },
  { label: 'PAT2 ความถนัดทางวิทยาศาสตร์', key: 'pat2_past' },
  { label: 'PAT3 ความถนัดทางวิศวกรรมศาสตร์', key: 'pat3_past' },
  { label: 'PAT4 ความถนัดทางสถาปัตยกรรมศาสตร์', key: 'pat4_past' },
  { label: 'PAT5 ความถนัดทางวิชาชีพครู', key: 'pat5_past' },
  { label: 'PAT6 ความถนัดทางศิลปกรรมศาสตร์', key: 'pat6_past' },
  //   { label: 'PAT7 ความถนัดทางภาษา', key: 'pat7_past' },
  { label: 'PAT7-1 ความถนัดทางภาษาฝรั่งเศส', key: 'pat7_1_past' },
  { label: 'PAT7-2 ความถนัดทางภาษาเยอรมัน', key: 'pat7_2_past' },
  { label: 'PAT7-3 ความถนัดทางภาษาญี่ปุ่น', key: 'pat7_3_past' },
  { label: 'PAT7-4 ความถนัดทางภาษาจีน', key: 'pat7_4_past' },
  { label: 'PAT7-5 ความถนัดทางภาษาอาหรับ', key: 'pat7_5_past' },
  { label: 'PAT7-6 ความถนัดทางภาษาบาลี', key: 'pat7_6_past' },
  { label: 'PAT7-7 ความถนัดทางภาษาเกาหลี', key: 'pat7_7_past' },

  { label: 'GAT T-Score', key: 'gat_past_tscore' },
  { label: 'GAT1 T-Score', key: 'gat1_past_tscore' },
  { label: 'GAT2 T-Score', key: 'gat2_past_tscore' },
  { label: 'PAT1 T-Score', key: 'pat1_past_tscore' },
  { label: 'PAT2 T-Score', key: 'pat2_past_tscore' },
  { label: 'PAT3 T-Score', key: 'pat3_past_tscore' },
  { label: 'PAT4 T-Score', key: 'pat4_past_tscore' },
  { label: 'PAT5 T-Score', key: 'pat5_past_tscore' },
  { label: 'PAT6 T-Score', key: 'pat6_past_tscore' },
  //   { label: 'PAT7 T-Score', key: 'pat7_past_tscore' },
  { label: 'PAT7-1 T-Score', key: 'pat7_1_past_tscore' },
  { label: 'PAT7-2 T-Score', key: 'pat7_2_past_tscore' },
  { label: 'PAT7-3 T-Score', key: 'pat7_3_past_tscore' },
  { label: 'PAT7-4 T-Score', key: 'pat7_4_past_tscore' },
  { label: 'PAT7-5 T-Score', key: 'pat7_5_past_tscore' },
  { label: 'PAT7-6 T-Score', key: 'pat7_6_past_tscore' },
  { label: 'PAT7-7 T-Score', key: 'pat7_7_past_tscore' },
];

const IGNORES = [
  { key: 'pat1_pat2' },
  { key: '9sub_sum' },
  { key: 'gpax' },
  { key: 'gpa22_23' },
  { key: 'gpa22_23_28' },
  { key: 'gat' },
  { key: 'pat7' },
  { key: 'priority_score' },
];

const StudentDetail = ({ fetchStudent, student }) => {
  const dispatch = useDispatch();

  const [OTHERS_SCORE, setOTHERS_SCORE] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [updateFields, setUpdateFields] = useState([]);
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);
  let { studentId } = useParams();

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  useEffect(() => {
    const otherSubjects = subjects.reduce((results, subject) => {
      if (
        !find(GPA_FIELDS, { key: subject.key }) &&
        !find(SUB9, { key: subject.key }) &&
        !find(GATPAT, { key: subject.key }) &&
        !find(IGNORES, { key: subject.key })
      ) {
        results.push({ key: subject.key, label: subject.short_label });
      }

      return results;
    }, []);

    setOTHERS_SCORE(otherSubjects);
  }, [subjects]);

  useEffect(() => {
    fetchSubjects();
  }, []);

  const formatAgeDate = (date) => {
    return moment(date).add(543, 'years').format('DD/MM/YYYY');
  };
  if (!student) {
    return <div></div>;
  }

  const openEditModal = (_updateFields) => {
    setEditModalVisible(true);
    console.log(_updateFields);
    setUpdateFields(_updateFields);
  };

  const renderFields = (fields, showNull = false) => {
    let isRender = false;
    const html = fields.map((field) => {
      if (field.noShow) return null;
      if (!student[field.key] && !showNull) return null;
      isRender = true;

      let renderVerifiedIcon = false;
      if (field.key === 'telephone') {
        if (student.telephone_is_verified) {
          renderVerifiedIcon = true;
        }
      }
      if (field.key === 'email') {
        if (student.email_is_verified) {
          renderVerifiedIcon = true;
        }
      }

      let value = student[field.key];
      if (field.key === 'birth_date') {
        value = moment(value, 'YYYY-MM-DD').add(543, 'year').format('DD MMMM YYYY');
      }
      if (field.key === 'program_code') {
        value = programCodeName[value];
      }

      return (
        <tr>
          <th>{field.label}</th>
          <td>
            {value || '-'}
            &nbsp;{renderVerifiedIcon && <i className={'i-yes'}></i>}
          </td>
        </tr>
      );
    });
    if (!isRender) {
      return (
        <tr>
          <th>ยังไม่มีข้อมูล</th>
        </tr>
      );
    }
    return html;
  };

  const showEditEverySection = me.role === 'super';
  const showEditGpaPhoneMail = me.role === 'senior';

  return (
    <div>
      <div className="text-center">
        <img src={student.picture} style={{ width: 250 }} />
      </div>
      <h1 className="t-title">
        {student.title}
        {student.first_name} {student.last_name}
      </h1>

      <table className="t-table -profile">
        <thead>
          <tr>
            <th>ข้อมูลส่วนบุคคล </th>
            <th className="info">
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal([...PROFILE_FIELDS])}>
                  แก้ไขข้อมูลส่วนบุคคล
                </a>
              )}
              {showEditGpaPhoneMail && (
                <a className="btn-ghost" onClick={() => openEditModal([...SENIOR_EDIT_FIELD])}>
                  แก้ไขข้อมูล
                </a>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>ชื่อ-นามสกุล</th>
            <td>
              {student.title} {student.first_name} {student.last_name}
            </td>
          </tr>
          {renderFields(PROFILE_FIELDS, true)}
          <tr className="heading">
            <th>ที่อยู่ </th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(ADDRESS_FIELDS)}>
                  แก้ไขข้อมูลที่อยู่
                </a>
              )}
            </th>
          </tr>
          {renderFields(ADDRESS_FIELDS, false)}
          <tr className="heading">
            <th>ข้อมูลสถานศึกษา </th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(SCHOOL_FIELDS)}>
                  แก้ไขข้อมูลการศึกษา
                </a>
              )}
            </th>
          </tr>
          {renderFields(SCHOOL_FIELDS, false)}
          <tr className="heading">
            <th>ข้อมูลผลการศึกษา </th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(GPA_FIELDS)}>
                  แก้ไขข้อมูลผลการศึกษา
                </a>
              )}
            </th>
          </tr>
          {renderFields(GPA_FIELDS, false)}

          <tr className="heading">
            <th>คะแนน GAT / PAT</th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(GATPAT)}>
                  แก้ไขข้อมูลคะแนน GAT / PAT
                </a>
              )}
            </th>
          </tr>
          {renderFields(GATPAT, false)}

          <tr className="heading">
            <th>คะแนน GAT / PAT ปีก่อนหน้า</th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(GATPAT)}>
                  แก้ไขข้อมูลคะแนน GAT / PAT
                </a>
              )}
            </th>
          </tr>
          {renderFields(GATPATPAST, false)}

          <tr className="heading">
            <th>ข้อมูล 9 วิชาสามัญ</th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(SUB9)}>
                  แก้ไขข้อมูล 9 วิชาสามัญ
                </a>
              )}
            </th>
          </tr>
          {renderFields(SUB9, false)}

          <tr className="heading">
            <th>คะแนนวิชาอื่นๆ</th>
            <th>
              {showEditEverySection && (
                <a className="btn-ghost" onClick={() => openEditModal(OTHERS_SCORE)}>
                  แก้ไขข้อมูลคะแนนวิชาอื่นๆ
                </a>
              )}
            </th>
          </tr>
          {renderFields(OTHERS_SCORE, false)}
        </tbody>
      </table>

      <EditModal
        isVisible={editModalVisible}
        setIsVisible={setEditModalVisible}
        student={student}
        updateFields={updateFields}
        onClose={() => {
          setUpdateFields([]);
          fetchStudent();
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default StudentDetail;
