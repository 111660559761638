import { get, omitBy, isNil, sumBy } from 'lodash';
import numeral from 'numeral';
// import URL from 'url';

export const getTheme =
  ([path]) =>
  (props) =>
    get(props, `theme.${path}`);

export const formatComma = (number = '') => numeral(number).format('0,0');

export const formatCommaDecimal = (number = '') => numeral(number).format('0,0.00');

export const formatThaiDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const hourString = hours ? `${hours} ชั่วโมง ` : '';
  const minutes = Math.floor(duration / 60) % 60;
  const minuteString = hours || minutes ? `${minutes} นาที ` : '';
  const seconds = duration % 60;
  return `${hourString}${minuteString}${seconds} วินาที`;
};

export const uploadToS3 = ({ url, file, contentType, contentEncoding }) => {
  let xhr = new XMLHttpRequest(); // eslint-disable-line
  xhr.open('PUT', url, true);
  if (contentType) {
    xhr.setRequestHeader('Content-Type', contentType);
  }
  xhr.send(file);
  return new Promise((resolve, reject) => {
    xhr.onload = (e) => {
      console.log(e, xhr);
      if (xhr.status === 200) {
        resolve(url.split('?')[0]);
      } else {
        reject(xhr.status);
      }
    };
  });
};

export const viewportWidth = () => {
  if (typeof document === 'undefined' || typeof window === 'undefined') return 0;
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

export const viewportHeight = () => {
  if (typeof document === 'undefined' || typeof window === 'undefined') return 0;
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};

export const tableWidth = (columns) => sumBy(columns, 'width');

export const toBase64 = (_file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader(); // eslint-disable-line
    reader.readAsDataURL(_file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const filterLabelAndValue = (input, option) => {
  return (
    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
};

export const downloadFromUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
