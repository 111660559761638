import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import { Modal } from 'antd';
import cn from 'classnames';

export default function ({ vimeoId, isVisible, setIsVisible }) {
  // const [phone, setPhone] = useState('+66909607070');
  // const [confirmResult, setConfirmResult] = useState(null);

  return (
    isVisible && (
      <Modal
        title={``}
        visible={isVisible}
        width={800}
        footer={null}
        className="video-modal"
        closeIcon={<span></span>}
        onCancel={() => setIsVisible(false)}
      >
        <iframe
          title={vimeoId}
          src={`https://player.vimeo.com/video/${vimeoId}`}
          width={750}
          height={(750 * 9) / 16}
          frameBorder={0}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Modal>
    )
  );
}
