import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import EXAM_SUBJECTS from 'constants/exam-subjects';
import EXAM_SITES from 'constants/exam-sites';

const { Panel } = Collapse;

const Exam = ({ student }) => {
  // let { studentId } = useParams();
  const [examApplications, setExamApplications] = useState([]);

  const fetchApplication = async () => {
    const result = await callApi({
      url: `/students/${student.citizen_id}/exam-applications`,
    });
    setExamApplications(result);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchApplication();
    }
  }, [student]);

  if (!examApplications) {
    return <div></div>;
  }

  const appsGroupByDay = _.groupBy(examApplications, 'day');
  // const repliesByRound = _.groupBy(replies, 'type');

  // console.log(repliesByRound);

  return (
    <div>
      <h1 className="t-title">
        {student.citizen_id} {student.title}
        {student.first_name} {student.last_name}
      </h1>
      {_.map(appsGroupByDay, (apps, day) => {
        return (
          <div>
            <div>
              <h2>วันที่ {day}</h2>
              {apps.map((app, i) => {
                const site = EXAM_SITES[app.selected_place];
                return (
                  <Collapse>
                    <Panel
                      header={
                        <div>
                          <div style={{ float: 'right' }}>สถานที่สอบ {site.site_name}</div>
                          <div>{EXAM_SUBJECTS.labels[app.subject_name]}</div>
                        </div>
                      }
                      key={i}
                    >
                      <ul className="r-history">
                        <li>
                          <div key={app._id} className="xm-box">
                            <ul>
                              <li>
                                เลขที่นั่งสอบ <b>{app.app_id}</b>
                              </li>

                              <li>
                                สนามสอบ{' '}
                                <b>
                                  {site.site_name} ({site.site_code})
                                </b>
                              </li>
                              <li>
                                อาคาร <b>{app.building_name}</b> &nbsp;&nbsp; ชั้น <b>{app.floor}</b> ห้อง{' '}
                                <b>{app.room_name}</b>
                              </li>
                              <li>
                                ตำแหน่งที่นั่ง{' '}
                                <b>
                                  แถว <small>(Row)</small> {app.row} • ตอน <small>(Column)</small> {app.col}
                                </b>
                              </li>
                              <li>
                                จังหวัด <b>{site.province}</b>
                              </li>
                              <li>
                                ความต้องการพิเศษ <b>{app.special_request}</b>
                              </li>
                            </ul>
                          </div>
                          <br></br>

                          <h3>สถานที่สอบที่เลือก</h3>
                          <div key={app._id} className="xm-box">
                            <ul>
                              {_.times(5, _.constant(null)).map((a, index) => {
                                const place = _.get(EXAM_SITES, `${app[`place_${index + 1}`]}.site_name`);

                                return (
                                  <li>
                                    อันดับ {index + 1}: {place}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Exam;
