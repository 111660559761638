import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import cn from 'classnames';
import TH from 'constants/th';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';
import TICKET_CATEGORIES from 'constants/ticket-types.json';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
  result[category.value] = category.label;
  return result;
}, {});

const programCodeName = {
  1: 'หลักสูตรแกนกลาง (สามัญ)',
  2: 'หลักสูตรนานาชาติ',
  3: 'หลักสูตรอาชีวะ',
  4: 'หลักสูตรตามอัธยาศัย (กศน.)',
  5: 'หลักสูตรนานาชาติจบจากต่างประเทศ',
  6: 'สอบเทียบ GED',
};

const schoolHiddenFields = [
  'school_code',
  'school_zipcode',
  'school_province_id',
  'school_province_name',
  'school_amph_id',
  'school_amph_name',
  'school_tmbl_id',
  'school_tmbl_name',
];

export default ({ ticket, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [sourceData, setSourceData] = useState();

  const subjects = useSelector((state) => state.subjects);

  const subjectLabels = subjects.reduce((results, subject) => {
    results[subject.key] = subject.label; //.replace('เกรดเฉลี่ยกลุ่มสาระฯ', 'เกรด ').replace('และเทคโนโลยี', '');

    return results;
  }, {});

  const [updatedFields, setUpdatedFields] = useState({});
  const [reason, setReason] = useState('');

  const [fullTicket, setFullTicket] = useState({ files: [] });

  // img zoom
  const [isZoom, setZoom] = useState(false);
  const handleZoom = () => {
    setZoom(!isZoom);
  };

  const autoRejectSameData = (_ticket, _updateFields) => {
    const __updateFields = _updateFields || updatedFields;
    const newUpdateFields = { ...__updateFields };
    if (_ticket.status !== 'pending') {
      return;
    }
    map(_ticket.requested_data, (singleData, key) => {
      const getOldData = () => {
        if (!get(ticket, `student.${key}`)) {
          return '';
        }
        return get(ticket, `student.${key}`);
      };
      const isSameData = getOldData() === get(singleData, 'value');

      if (isSameData) {
        newUpdateFields[key] = false;
      }
    });

    setUpdatedFields(newUpdateFields);
  };
  const getApproveEveryFields = (_ticket) => {
    const returnUpdateField = {};
    map(_ticket.requested_data, (singleData, key) => {
      returnUpdateField[key] = true;
    });
    return returnUpdateField;
  };

  useEffect(() => {
    const fetchFullTicket = async () => {
      try {
        setLoading(true);

        const _ticket = await callApi({ url: `/tickets/${ticket._id}` });
        if (get(ticket, 'requested_data.is_tcas.value') === 'true') {
          const studentSourceData = await callApi({ url: `/raws/source-data/${ticket.citizen_id}` });
          setSourceData(studentSourceData);
        }

        const _updateFields = getApproveEveryFields(_ticket);
        autoRejectSameData(_ticket, _updateFields);
        setFullTicket(_ticket);
        setLoading(false);
        // fetchOrders(currentPage);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));
        setFullTicket(ticket);
        setLoading(false);
      }
    };
    if (ticket && ticket._id) {
      fetchFullTicket();
    }
  }, [ticket]);

  const updateTicket = async () => {
    try {
      setLoading(true);

      const requestBody = { updatedFields, reason };

      const reqParams = {
        url: `/tickets/${ticket._id}/process`,
        method: 'post',
        body: requestBody,
      };

      await callApi(reqParams);

      setLoading(false);
      setIsVisible(false);
      onClose();
      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      // message.error(get(error, 'message') || 'Unable to reset password, please try again later.');
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  const rejectTicket = async () => {
    try {
      setLoading(true);
      const requestBody = { reason };

      const reqParams = {
        url: `/tickets/${ticket._id}/reject`,
        method: 'post',
        body: requestBody,
      };

      await callApi(reqParams);

      setLoading(false);
      setIsVisible(false);
      onClose();
      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  if (!ticket) {
    return null;
  }
  let title = `${ticket.ticket_id} ${get(ticket, `citizen_id`)} ${get(ticket, `first_name`)} ${get(
    ticket,
    `last_name`
  )} `;
  let submitButtonTxt = 'ยืนยัน';
  let cancelButtonTxt = 'ปฏิเสธคำขอ';
  if (ticket.ticket_type === 'create_student') {
    submitButtonTxt = 'อนุมัติ';
    cancelButtonTxt = 'ไม่อนุมัติ';
    if (ticket.category === 'create_found_student') {
      title += ` คำขอสร้างนักเรียนใหม่จากฐานข้อมูล ${get(sourceData, 'source') || ''}`;
    } else {
      title += ` คำขอสร้างนักเรียนใหม่`;
    }
  }
  if (ticket.ticket_type === 'edit_profile') {
    title += ` คำขอแก้ไขข้อมูลนักเรียน: ${get(ticket, `student.citizen_id`)} ${get(ticket, `student.first_name`)} ${get(
      ticket,
      `student.last_name`
    )} `;
  }
  if (ticket.ticket_type === 'create_school') {
    title += ` คำขอสร้างโรงเรียนใหม่`;
    submitButtonTxt = 'อนุมัติ';
    cancelButtonTxt = 'ไม่อนุมัติ';
  }

  const onFieldStatusChange = (key, value) => {
    const newUpdateFields = { ...updatedFields, [key]: value };
    if (key === 'school_name' && (ticket.category === 'update_school' || ticket.category === 'update_gpax')) {
      newUpdateFields.school_code = value;
      newUpdateFields.school_zipcode = value;
      newUpdateFields.school_province_id = value;
      newUpdateFields.school_province_name = value;
      newUpdateFields.school_amph_id = value;
      newUpdateFields.school_amph_name = value;
      newUpdateFields.school_tmbl_id = value;
      newUpdateFields.school_tmbl_name = value;
    }
    setUpdatedFields(newUpdateFields);
  };

  return (
    <Modal
      title={title}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={1200}
      footer={
        ticket.status === 'processed' || ticket.status === 'rejected'
          ? [<div>คำร้องได้รับการดำเนินการแล้ว</div>]
          : [
              <Button key="back" onClick={() => rejectTicket()} style={{ marginRight: 20 }}>
                {cancelButtonTxt}
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
                {submitButtonTxt}
              </Button>,
            ]
      }
      onCancel={() => setIsVisible(false)}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={updateTicket}>
        <Row gutter={[24, 16]}>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
          <Col
            span={14}
            style={{
              maxHeight: 'calc(100vh - 216px)',
              overflowY: 'scroll',
            }}
          >
            <h3>หลักฐาน</h3>
            <div className={isZoom ? 'is-zoom' : null}>
              {fullTicket.files &&
                fullTicket.files.map((file) => {
                  if (file.indexOf('https://') !== -1) {
                    return <img src={file} onClick={handleZoom} />;
                  }
                  return <img src={`data:image/png;base64, ${file}`} onClick={handleZoom} />;
                })}
            </div>
          </Col>
          <Col
            span={10}
            style={{
              maxHeight: 'calc(100vh - 216px)',
              overflowY: 'scroll',
            }}
          >
            <h3>หัวข้อ {TICKET_LABELS[ticket.category] || TICKET_LABELS[ticket.ticket_type]}</h3>
            <h3 className="s-flex">
              <span>การแก้ไข</span>
            </h3>
            <table className="t-table -pticket">
              <tbody>
                {map(ticket.requested_data, (singleData, key) => {
                  const approvedStatus =
                    ticket.status === 'processed' || ticket.status === 'rejected'
                      ? get(singleData, `status`) === 'approved'
                      : updatedFields[key];

                  if (['is_tcas', ...schoolHiddenFields].indexOf(key) > -1) {
                    return <tr></tr>;
                  }

                  const getOldData = () => {
                    if (sourceData) {
                      console.log(sourceData[key]);
                      return get(sourceData, `${key}`);
                    }

                    if (!get(ticket, `student.${key}`)) {
                      return '';
                    }
                    return get(ticket, `student.${key}`);
                  };
                  const renderOldData = () => {
                    if (sourceData) {
                      return <span>{get(sourceData, `${key}`) || '-'}</span>;
                    }

                    if (key === 'program_code') {
                      {
                        return (
                          <span>
                            {!isEmpty(ticket.old_data_before_updated)
                              ? programCodeName[get(ticket, `old_data_before_updated.${key}`)] || '-'
                              : programCodeName[get(ticket, `student.${key}`)] || '-'}
                          </span>
                        );
                      }
                    }
                    return (
                      <span>
                        {!isEmpty(ticket.old_data_before_updated)
                          ? get(ticket, `old_data_before_updated.${key}`) || '-'
                          : get(ticket, `student.${key}`) || '-'}
                      </span>
                    );
                  };

                  const isSameDataAtPending = getOldData() === get(singleData, 'value');
                  if (isSameDataAtPending && ticket.status === 'pending') {
                    return <tr key={key}></tr>;
                  }

                  const renderData = (value) => {
                    if (key === 'program_code') {
                      return programCodeName[value];
                    } else if (['ged_date'].indexOf(key) > -1) {
                      return moment(value, 'YYYY-MM-DD').add(543, 'year').format('DD MMMM YYYY');
                    } else {
                      return value;
                    }
                  };

                  return (
                    <tr key={key} className={key}>
                      <td>
                        <div>
                          <b>{TH[key] || subjectLabels[key] || subjectLabels[key.replace('_past', '')]}</b>
                        </div>
                        <div>
                          จาก <b>{renderOldData()}</b> เป็น{' '}
                          <b className="score-badge">{renderData(get(singleData, 'value'))}</b>
                        </div>
                      </td>

                      <td width={60}>
                        {ticket.ticket_type !== 'create_student' && ticket.ticket_type !== 'create_school' && (
                          <div className="s-flex">
                            <i
                              className={cn({
                                'i-no': approvedStatus === false,
                                'i-no-gray': approvedStatus !== false,
                              })}
                              onClick={() => onFieldStatusChange(key, false)}
                              style={{ width: 24, height: 24, cursor: 'pointer' }}
                            ></i>
                            &nbsp;&nbsp;
                            <i
                              className={cn({
                                'i-yes': approvedStatus === true,
                                'i-yes-gray': approvedStatus !== true,
                              })}
                              onClick={() => onFieldStatusChange(key, true)}
                              style={{ width: 24, height: 24, cursor: 'pointer' }}
                            ></i>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div>เหตุผล</div>
            <div>
              {ticket.status === 'processed' || ticket.status === 'rejected' ? (
                <div>{ticket.reason || '-'}</div>
              ) : (
                <Input.TextArea
                  placeholder={'เหตุผลในการปฏิเสธ'}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              )}
            </div>
          </Col>
        </Row>

        <div></div>
      </Form>
    </Modal>
  );
};
