import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Checkbox,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  Popconfirm,
  Radio,
  InputNumber,
} from 'antd';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ selectedSubject, setIsVisible, onClose, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const [popupConfirmDeleteVisible, setPopupConfirmDeleteVisible] =
    useState(false);
  const [form] = Form.useForm();
  const createSubject = async (values) => {
    try {
      setLoading(true);

      if (selectedSubject && selectedSubject._id) {
        await callApi({
          url: `/subjects/${selectedSubject._id}`,
          method: 'put',
          body: {
            key: values.key,
            label: values.label,
            maxValue: values.maxValue,
            isStandardScore: values.isStandardScore,
          },
        });
      } else {
        await callApi({
          url: `/subjects`,
          method: 'post',
          body: {
            key: values.key,
            label: values.label,
            maxValue: values.maxValue,
            isStandardScore: values.isStandardScore,
          },
        });
      }

      setLoading(false);
      setIsVisible(false);
      onClose();
      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  const deleteSubject = async () => {
    setPopupConfirmDeleteVisible(false);
    setLoading(true);
    try {
      await callApi({
        url: `/subjects/${selectedSubject._id}`,
        method: 'delete',
      });
      setLoading(false);
      setIsVisible(false);
      onClose();
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`เพิ่มข้อมูลรายวิชาสอบ`}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      onCancel={() => setIsVisible(false)}
      width={800}
      footer={[
        <>
          <Popconfirm
            title="ยืนยันการลบ"
            visible={popupConfirmDeleteVisible}
            onConfirm={deleteSubject}
            onCancel={() => setPopupConfirmDeleteVisible(false)}
            okText="ลบ"
            cancelText="ยกเลิก"
          >
            <Button
              key="submit"
              type="danger"
              loading={loading}
              onClick={() => setPopupConfirmDeleteVisible(true)}
              style={{
                display:
                  selectedSubject && selectedSubject._id ? 'block' : 'none',
                float: 'left',
              }}
            >
              ลบ
            </Button>
          </Popconfirm>
        </>,
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          {selectedSubject && selectedSubject._id
            ? 'แก้ไขข้อมูล'
            : 'เพิ่มข้อมูล'}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={createSubject}
      >
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>key</label>
        </Col> */}
          <Col span={16}>
            <Form.Item
              label="รหัสวิชาสอบ"
              name="key"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ รหัสวิชาสอบ' }]}
              initialValue={selectedSubject && selectedSubject.key}
            >
              <Input placeholder="onet_01" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="คำอธิบายวิชา"
              name="label"
              initialValue={selectedSubject && selectedSubject.label}
              rules={[
                { required: true, message: 'จำเป็นต้องใส่ คำอธิบายวิชา' },
              ]}
            >
              <Input placeholder="วิชาภาษาไทย(ONET)" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="คะแนนเต็ม"
              name="maxValue"
              initialValue={selectedSubject && selectedSubject.max_value}
              rules={[{ required: true, message: 'จำเป็นต้องใส่ คะแนนเต็ม' }]}
            >
              <InputNumber placeholder="100" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label=" "
              name="isStandardScore"
              valuePropName="checked"
              initialValue={selectedSubject && selectedSubject.isStandardScore}
              rules={[{ required: false }]}
            >
              <Checkbox>คะแนนการสอบจาก สพฐ.</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <div></div>
      </Form>
    </Modal>
  );
};
