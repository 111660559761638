import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

// Helpers
import callApi from 'helpers/callapi';
import { getTheme } from 'helpers/helpers';

// Components
import styled from 'styled-components';
import { Modal, message } from 'antd';
import { get } from 'lodash';

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [resetting, setResetting] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  useEffect(() => {
    const verify = async () => {
      try {
        const me = await callApi({ url: '/users/me' });
        dispatch({ type: 'UPDATE_USER', data: me });

        history.replace('/dashboard');
      } catch (error) {
        console.log(error);
        Cookies.remove('accessToken');
        dispatch({ type: 'UPDATE_USER', data: null });
      }
    };

    verify();
  }, []);

  const onResetPassword = async () => {
    if (resetting) return;
    const _emailError = !email && 'กรุณากรอกข้อมูลชื่อบัญชีผู้ใช้';
    setEmailError(_emailError);

    if (![_emailError].some((_error) => _error)) {
      try {
        setResetting(true);
        await callApi({
          method: 'POST',
          url: '/users/forgot-password',
          body: { email },
        });
        setEmail('');
        setResetting(false);
        Modal.success({
          content: 'ระบบได้ส่งวิธีการสำหรับการรีเซ็ตรหัสผ่านไปทางอีเมลเรียบร้อยแล้ว',
        });
      } catch (error) {
        message.error(get(error, 'message') || 'Unable to reset password, please try again later.');

        setResetting(false);
      }
    }
  };

  const _onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onResetPassword();
    }
  };

  return (
    <div>
      <div className="t-body">
        <div className="t-login">
          <div className="t-form">
            <div className="text-center">
              <a href="/">
                <img src="/img/tcas-logo.png" alt="Logo" width="194" height="45" />
              </a>
              <h1>ตั้งค่ารหัสผ่านใหม่</h1>
            </div>
            <div className="_space">
              <label>
                Email
                <input
                  onKeyPress={_onKeyPress}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="กรอกอีเมล"
                  type="text"
                />
                {emailError && <ErrorText>{emailError}</ErrorText>}
              </label>
            </div>
            <div>
              <button onClick={onResetPassword}>ขอลิงก์เพื่อตั้งค่ารหัสผ่านใหม่</button>
            </div>
            <div className="forgot text-center">
              <Link to="/credential">
                <label>
                  <a>เข้าสู่ระบบ</a>
                </label>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

const ErrorText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${getTheme`colors.red`};
`;
