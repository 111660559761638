import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';

// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const adminConfig = useSelector((state) => state.configs || {});

  const [currentRound, setCurrentRound] = useState(adminConfig.current_round);

  const updateConfig = async () => {
    const configs = await callApi({ url: '/configs/admin' });
    dispatch({ type: 'UPDATE_CONFIGS', data: configs });
  };
  useEffect(() => {}, []);

  // {['1', '2', '4'].map((roundNumber) => {
  //   const round = find(ROUNDS, { value: `${roundNumber}_2565` });

  //   if (adminConfig.current_round !== roundNumber) {
  //     return <></>;
  //   }

  // }

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            ตั้งค่าระบบ
            <div style={{ float: 'right' }}>
              {/* <Button
                type="primary"
                onClick={() => {
                  
                }}
              >
                เพิ่มการรับสมัครใหม่
              </Button> */}
            </div>
          </h1>

          <div>
            <h3>การคัดเลือกนักเรียนตรง</h3>

            <table className="t-table -students">
              <thead>
                <tr>
                  <th>หัวข้อ</th>
                  <th>การตั้งค่า</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>รอบการคัดเลือกปัจจุบัน</td>
                  <td>
                    <Select value={`${adminConfig.current_round}_2565`}>
                      {ROUNDS.map((round, i) => {
                        return (
                          <Option key={i} value={round.value}>
                            รอบที่ {round.value.split('_')[0]}: {round.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td>เปิดให้ทำการนำข้อมูลผู้สมัครเข้าระบบ</td>
                  <td>
                    <Select>
                      <Option key={1} value={true}>
                        เปิดระบบ
                      </Option>
                      <Option key={2} value={false}>
                        ปิดระบบ
                      </Option>
                    </Select>
                  </td>
                </tr>

                <tr>
                  <td>เปิดให้ดาวน์โหลดข้อมูลผลการศึกษาของผู้สมัคร</td>
                  <td>
                    <Select>
                      <Option key={1} value={true}>
                        เปิดระบบ
                      </Option>
                      <Option key={2} value={false}>
                        ปิดระบบ
                      </Option>
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td>เปิดให้นำเข้าข้อมูลผลการรับสมัคร</td>
                  <td>
                    <Select>
                      <Option key={1} value={true}>
                        เปิดระบบ
                      </Option>
                      <Option key={2} value={false}>
                        ปิดระบบ
                      </Option>
                    </Select>
                  </td>
                </tr>

                <tr>
                  <td>เปิดให้ดาวน์โหลดผลการเลือกเข้าศึกษาต่อของนักเรียน</td>
                  <td>
                    <Select>
                      <Option key={1} value={true}>
                        เปิดระบบ
                      </Option>
                      <Option key={2} value={false}>
                        ปิดระบบ
                      </Option>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Spin>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
