import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, result } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import csv from 'csv-parser';
// Components
import CONSTANTS from 'constants/constants';
import ExportModal from './ExampleFileModal';
import ImportModal from './ImportModal';

import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const universities = useSelector((state) => {
    return state.universities || [];
  });
  console.log(me.role);

  const subjects = useSelector((state) => state.subjects || []);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  // const loaded = useSelector((state) => state.coursesLoaded);
  const [uploadFile, setUploadFile] = useState();
  const [importModalVisible, setImportModalVisible] = useState(false);
  // const [importScore, setImportScore] = useState([]);

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [rounds, setRounds] = useState([]);

  const [filterQuery, setFilterQuery] = useState({
    university_id: 'all',
    subject: 'all',
  });

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });

      // setStudents(students || []);
    } catch (error) {
      console.log('errror', error);
      // setStudents([]);
    }
  };

  const fetchUniversities = async () => {
    if (universities.length > 0) {
      return;
    }
    try {
      const result = await callApi({
        url: '/configs/universities',
      });
      dispatch({ type: 'UPDATE_UNIVERSITIES', data: result });
    } catch (error) {
      console.log('errror', error);
      // setProgramProjects([]);
    }
  };

  const fetchUploadFiles = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: files } = await callApi({
        url: '/students/scores/files',
        query,
      });
      setFiles(files || []);
      setFileCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setFiles([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUploadFiles(1);
  }, [filterQuery]);

  useEffect(() => {
    if (me) {
      setFilterQuery({ ...filterQuery, university_id: me.university_id });
      fetchSubjects();
      fetchUniversities();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchUploadFiles(_page);
  };

  // if (me) {
  //   currentCourses = courses[me.university_id];
  // }

  const importScore = async (file) => {
    setLoading(true);
    try {
      await callApi({
        url: `/students/scores/${file._id}/import`,
        method: 'post',
      });
      fetchUploadFiles();
      setLoading(false);
    } catch (error) {
      console.log('errror', error);
      setLoading(false);
      // setStudents([]);
    }
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            รายละเอียดการนำเข้าคะแนนที่จัดสอบเอง
            <div style={{ float: 'right' }}>
              {/* <Upload {...props} customRequest={dummyRequest}> */}
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                onClick={() => setImportModalVisible(true)}
              >
                อัปโหลดข้อมูลคะแนน
              </Button>
              {/* </Upload> */}

              <Button type="primary" style={{ marginRight: 10 }} onClick={() => setExportModalVisible(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>{' '}
                ไฟล์ตัวอย่าง
              </Button>
            </div>
          </h1>

          <div className="sec-box">
            <h2>ค้นหาข้อมูลหลักสูตร</h2>
            <div className="sec-form">
              <div className="col-20">
                <label>
                  มหาวิทยาลัย
                  <Select
                    showSearch
                    disabled={get(me, 'role') === 'staff'}
                    style={{ width: '100%' }}
                    value={get(filterQuery, 'university_id')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={async (value) => {
                      setFilterQuery({
                        ...filterQuery,
                        university_id: value,
                      });
                    }}
                    // value={get(filterQuery, 'university')}
                    filterOption={filterLabelAndValue}
                  >
                    <Option key="all" value="all" label="มหาวิทยาลัยทั้งหมด">
                      มหาวิทยาลัยทั้งหมด
                    </Option>
                    {universities.map((university) => {
                      return (
                        <Option
                          key={university.university_id}
                          value={university.university_id}
                          label={university.university_name}
                        >
                          {university.university_id}: {university.university_name}
                        </Option>
                      );
                    })}
                  </Select>
                </label>
              </div>

              <div className="col-20">
                <label>
                  วิชา
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    value={get(filterQuery, 'subject')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={async (value) => {
                      setFilterQuery({
                        ...filterQuery,
                        subject: value,
                      });
                    }}
                    // value={get(filterQuery, 'university')}
                    filterOption={filterLabelAndValue}
                  >
                    <Option key="all" value="all" label="ทั้งหมด">
                      ทั้งหมด
                    </Option>
                    {subjects
                      .filter((subject) => subject.subject_type === 'university_score')
                      .map((subject) => {
                        return (
                          <Option key={subject.key} value={subject.key} label={subject.label}>
                            {subject.key}: {subject.label}
                          </Option>
                        );
                      })}
                  </Select>
                </label>
              </div>

              <div className="col-20 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchPrograms(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {files.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบไฟล์ข้อมูลคะแนน</th>
                </tr>
              </tbody>
            </table>
          )}
          {files.length > 0 && (
            <>
              <table>
                <thead>
                  <tr>
                    <th>เวลา</th>
                    <th>คะแนนวิชา</th>
                    <th>จำนวน(คน)</th>
                    <th>มหาวิทยาลัย</th>
                    <th>ผู้อัปโหลดข้อมูล</th>
                    <th>ผู้นำเข้าข้อมูล</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, i) => {
                    let university;
                    if (file.university_id) {
                      university = _.find(universities, { university_id: file.university_id });
                    }

                    return (
                      <tr key={i}>
                        <td>{moment(file.created_at).format('DD/MM/YY HH:mm:ss')}</td>

                        <td>
                          <ul>
                            {file.scores.map((header) => {
                              const foundSubject = find(subjects, {
                                key: header,
                              });
                              if (foundSubject && !foundSubject.isStandardScore) {
                                return <li>{foundSubject.key}</li>;
                              } else {
                                return <span></span>;
                              }
                            })}
                          </ul>
                        </td>
                        <td>{file.row_count}</td>
                        <td>{get(university, `university_name`)}</td>
                        <td>{get(file, 'user.name') || get(file, 'user.email')}</td>
                        <td>
                          <div>{get(file, 'imported_by.name') || get(file, 'imported_by.email')}</div>
                          <div>
                            {get(file, 'imported_at')
                              ? moment(get(file, 'imported_at')).format('DD/MM/YY HH:mm:ss')
                              : ''}
                          </div>
                        </td>

                        <td>
                          {file.file && (
                            <a className="btn-alt" style={{ cursor: 'pointer' }} href={file.file}>
                              ดาวน์โหลด
                            </a>
                          )}{' '}
                          {file.file && get(me, 'role') === 'super' && !file.is_imported && (
                            <a
                              className="btn-alt"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                importScore(file);
                              }}
                            >
                              นำเข้าข้อมูลจากไฟล์
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={fileCount}
                showSizeChanger={false}
              />
            </>
          )}

          {/* <ul className="t-paging">
          <li>
            <a href="#">←</a>
          </li>

          <li className="active">
            <a href="#">1</a>
          </li>
          <li className="">
            <a href="#">1</a>
          </li>

          <li>
            <a href="#">→</a>
          </li>
        </ul> */}
        </div>

        {exportModalVisible && (
          <ExportModal
            setIsVisible={setExportModalVisible}
            isVisible={exportModalVisible}
            onClose={(result) => {
              fetchUploadFiles(currentPage);
            }}
            width={800}
          ></ExportModal>
        )}
        {importModalVisible && (
          <ImportModal
            setIsVisible={setImportModalVisible}
            isVisible={importModalVisible}
            subjects={subjects}
            onClose={() => {
              setImportModalVisible(false);
              fetchUploadFiles();
            }}
          ></ImportModal>
        )}
      </Spin>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
