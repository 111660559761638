import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import cn from 'classnames';

// Components
import ImportModal from './ImportModal';
import VideoModal from 'components/VideoModal';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import useImportInfo from './useImportInfo';

const Programs = () => {
  const history = useHistory();
  const { loading, university, setUniversity } = useMyUniversity();
  const [currentIndex, setCurrentIndex] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [importErrors, setImportErrors] = useState([]);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const adminConfig = useSelector((state) => state.configs || {});

  if (!university) return null;

  if (adminConfig.is_closed_for_import) {
    history.push('/programs/update');
  }
  return (
    <div>
      <h1>นำข้อมูลเข้าระบบ</h1>

      <div className="sec-intro">
        <div className="info">
          <p>
            ข้อมูลเนื้อหาหลักสูตร เงื่อนไขการรับ และการคำนวณคะแนน จะนำไปแสดง
            <br />
            ที่หน้าเว็บ{' '}
            <u>
              <a href="https://www.mytcas.com" target="_blank">
                myTCAS.com
              </a>
            </u>{' '}
            เพื่อให้ผู้สมัครค้นหาและดูรายละเอียด
          </p>
          <p>
            โดยระบบจะนำไฟล์เงื่อนไขการรับและการคำนวณคะแนนของรอบที่ 3 <br />
            ไปคำนวณให้อัตโนมัติ ส่วนรอบอื่นๆ แสดงเป็นข้อมูลอ้างอิงเท่านั้น
          </p>
        </div>
        <div className="help">
          <a onClick={() => setIsVideoModalVisible(!isVideoModalVisible)}>
            <i className="i-video"></i>
            วีดีโอวิธีการใช้งาน
          </a>
          <a href="https://assets.mytcas.com/d/T65-Uadmin-v5-19apr22.pdf" target="_blank">
            <i className="i-manual"></i>
            คู่มือการใช้งาน (v5)
          </a>
        </div>
      </div>

      <div className="sec-announce">
        การนำข้อมูลเข้าต้องเรียงลำดับการนำเข้าไฟล์ 1-2-3 เท่านั้น หากสถาบันมีการแก้ไขไฟล์หลักสูตร
        ท่านต้องอัปโหลดไฟล์เงื่อนไขการรับและไฟล์การคำนวณคะแนนใหม่ด้วย กรณีที่สถาบันต้องการแก้ไขไฟล์เดิม
        ให้อัปโหลดไฟล์ใหม่ขึ้นไปแทนที่ได้เลย
      </div>

      {Boolean(importErrors.length) && (
        <div className="sec-error">
          <h2>ไม่สามารถอัปโหลด{errorTitle}ได้</h2>
          <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ ทั้งหมด {importErrors.length} แถว</p>
          <ul>
            {importErrors.map(({ index, message }, _idx) => (
              <li key={_idx}>
                แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
              </li>
            ))}
          </ul>
        </div>
      )}

      <ul className="sec-import">
        {Array(3)
          .fill()
          .map((_, index) => {
            const { title, exampleFilePath, filePath, updatedAt, uploadable, count } = useImportInfo(
              university,
              index + 1
            );
            console.log('count', count);

            return (
              <li key={index} className={`round-${index + 1}`}>
                <h2>
                  <b className="num">{index + 1}</b>
                  <span>{title}</span>
                </h2>
                <a className="example" href={exampleFilePath}>
                  ดาวน์โหลดไฟล์ตัวอย่าง
                </a>
                {filePath ? (
                  <div className="file">
                    <a href={filePath}>
                      <i className="i-course"></i>
                      <b>{filePath.split('/').pop()}</b>
                      <small>
                        {moment(updatedAt).add(543, 'years').format('D MMM YY HH:mm น.')}{' '}
                        {count ? `(${count} รายการ)` : ''}
                      </small>
                    </a>
                  </div>
                ) : (
                  <div className="file -none">
                    <a href="#">
                      <i className="i-course"></i>
                      <b>ยังไม่มีข้อมูล</b>
                    </a>
                  </div>
                )}
                <div className="action">
                  <a
                    className={cn('btn-main', { 'btn-disabled': !uploadable })}
                    style={uploadable ? null : { cursor: 'not-allowed' }}
                    onClick={uploadable ? () => setCurrentIndex(index + 1) : () => {}}
                  >
                    <i className="i-upload"></i> นำเข้าข้อมูล
                  </a>
                </div>
              </li>
            );
          })}
      </ul>
      <ImportModal
        index={currentIndex}
        onCancel={() => setCurrentIndex()}
        university={university}
        onUniversityUpdate={(_university) => setUniversity(_university)}
        onErrorsChange={(errors) => {
          setErrorTitle({ 1: 'เนื้อหาหลักสูตร', 2: 'เงื่อนไขการรับ', 3: 'การคำนวณคะแนน' }[currentIndex]);
          setCurrentIndex();
          setImportErrors(errors);
        }}
      />

      <VideoModal isVisible={isVideoModalVisible} vimeoId={'642471244'} setIsVisible={setIsVideoModalVisible} />
    </div>
  );
};

export default Programs;
