import React from 'react';
import _ from 'lodash';

const map = {
  1: {
    title: 'เนื้อหาหลักสูตร',
    exampleFilePath: 'https://tcas65.sgp1.digitaloceanspaces.com/example/program.csv',
    uploadURL: '/programs/import',
  },
  2: {
    title: 'เงื่อนไขการรับ',
    exampleFilePath: 'https://tcas65.sgp1.digitaloceanspaces.com/example/condition.csv',
    uploadURL: '/program-projects/condition/import',
  },
  3: {
    title: 'การคำนวณคะแนน',
    exampleFilePath: 'https://tcas65.sgp1.digitaloceanspaces.com/example/scoring.csv',
    uploadURL: '/program-projects/scoring/import',
  },
};

const map_update = {
  1: {
    title: 'เนื้อหาหลักสูตร',

    uploadURL: '/programs/update/import',
    logFileURL: '/logs/programs/import_update_programs',
  },
  2: {
    title: 'เงื่อนไขการรับ',

    uploadURL: '/program-projects/condition/update/import',
    logFileURL: '/logs/programs/import_update_program_condition',
  },
  3: {
    title: 'การคำนวณคะแนน',

    uploadURL: '/program-projects/scoring/import',
    logFileURL: '/logs/programs/import_update_program_score',
  },
};

const getFileType = (university, index) => {
  switch (index) {
    case 1:
      return 'program_file';
    case 2:
      return 'condition_file';
    case 3:
      return 'scoring_file';
    default:
      return '';
  }
};

const getUpdatedAtField = (university, index) => {
  switch (index) {
    case 1:
      return 'program_updated_at';
    case 2:
      return 'program_condition_updated_at';
    case 3:
      return 'program_scoring_updated_at';
    default:
      return '';
  }
};

const getCountField = (index) => {
  switch (index) {
    case 1:
      return 'program_count';
    case 2:
      return 'program_project_count';
    case 3:
      return '';
    default:
      return '';
  }
};

const useImportInfo = (university, index, config = { type: 'import' }) => {
  const fileType = getFileType(university, index);
  const updatedAtField = getUpdatedAtField(university, index);
  const countField = getCountField(index);

  const uploadable =
    index === 2 ? Boolean(university.program_file) : index === 3 ? Boolean(university.condition_file) : true;

  const _map = config.type === 'update' ? map_update : map;

  return {
    ..._map[index],
    fileType,
    updatedAtField,
    filePath: fileType ? university[fileType] : null,
    lastUpdateFilePath: fileType ? university[`last_update_${fileType}`] : null,
    updatedAt: updatedAtField ? university[updatedAtField] : null,
    uploadable,
    uploadCount: _.compact([university.program_file, university.condition_file, university.scoring_file]).length,
    count: countField ? university[countField] : null,
  };
};

export default useImportInfo;
