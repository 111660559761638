import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, reduce, uniq, uniqBy, map, result, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';

import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Upload, Pagination, Modal, Row, Col, Select, message, Input, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ImportModal from './ImportModal';

import FIELDS from 'constants/field_labels.json';
import ScoreCalculationBar from 'components/ScoreCalculationBar';

import { filterLabelAndValue, downloadFromUrl } from 'helpers/helpers';
import ComplexSubjectInfo from 'containers/Programs/ComplexSubjectInfo';
import ReceiveCondition from 'containers/Programs/ReceiveCondition';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

const ROUNDS = [
  {
    value: '1_2565',
    label: 'Portfolio',
  },
  {
    value: '2_2565',
    label: 'Quota',
  },
  {
    value: '3_2565',
    label: 'Admission',
  },
  {
    value: '4_2565',
    label: 'Direct Admission',
  },
];

moment.locale('th');

export default () => {
  const dispatch = useDispatch();

  const [importModalVisible, setImportModalVisible] = useState(false);
  const me = useSelector((state) => state.user);
  const universities = useSelector((state) => state.universities || []);
  const [university_id, setUniverysityId] = useState('');

  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);

  const [loading, setLoading] = useState(false);
  const [programProjects, setProgramProjects] = useState([]);
  const [importPrograms, setImportPrograms] = useState([]);
  const [programCount, setProgramCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [exportUniversity, setExportUniverysity] = useState();

  const [filterQuery, setFilterQuery] = useState({
    program: 'all',
    type: 'all',
  });

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });

      // setProgramProjects(programProjects || []);
    } catch (error) {
      console.log('errror', error);
      // setProgramProjects([]);
    }
  };

  const fetchUniversities = async () => {
    if (universities.length > 0) {
      return;
    }
    try {
      const result = await callApi({
        url: '/configs/universities',
      });
      dispatch({ type: 'UPDATE_UNIVERSITIES', data: result });

      // setProgramProjects(programProjects || []);
    } catch (error) {
      console.log('errror', error);
      // setProgramProjects([]);
    }
  };

  const fetchCourses = async (university_id) => {
    // if (universities.length > 0) {
    //   return;
    // }
    try {
      const userUniversityId = me.role === 'staff' ? me.university_id : 'all';
      const query = {
        university: university_id || userUniversityId,
      };
      const result = await callApi({
        url: '/configs/programs',
        query,
      });
      dispatch({
        type: 'UPDATE_COURSES',
        data: result,
        university: university_id || userUniversityId,
      });

      // setProgramProjects(programProjects || []);
    } catch (error) {
      console.log('errror', error);
      // setProgramProjects([]);
    }
  };

  // const [creditCount, setCreditCount] = useState()
  const fetchPrograms = async (page) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery };

      const { count, data: programProjects } = await callApi({
        url: '/program-projects',
        query,
      });
      setProgramProjects(programProjects || []);
      setProgramCount(count);
      if (programProjects.length > 0) {
        setUniverysityId(filterQuery.university || get(me, 'university_id'));
      }

      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setProgramProjects([]);
      setLoading(false);
    }
  };

  const exportPrograms = async (_exportUniversity) => {
    try {
      setLoading(true);
      const query = {};

      if (_exportUniversity !== 'all') {
        query.university_id = _exportUniversity;
      }
      const result = await callApi({
        url: '/program-projects/exports',
        method: 'POST',
        body: query,
      });

      downloadFromUrl(result.link);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('errror', error);
    }
  };

  const onExportProgramButtonClick = () => {
    console.log(exportUniversity, filterQuery);
    let _exportUniversity = exportUniversity;
    Modal.confirm({
      title: 'ต้องการนำออกหลักสูตรทั้งหมด',
      content: (
        <div>
          <p>โปรดเลือกมหาวิทยาลัยที่ต้องการนำข้อมูลหลักสูตรออก</p>
          <div>
            <label>
              <b>มหาวิทยาลัย</b>
              <Select
                showSearch
                disabled={get(me, 'role') === 'staff'}
                style={{ width: '100%' }}
                defaultValue={exportUniversity}
                size={'large'}
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  setExportUniverysity(value);
                  _exportUniversity = value;
                  console.log(value);
                }}
                filterOption={filterLabelAndValue}
              >
                <Option key="all" value="all" label="มหาวิทยาลัยทั้งหมด">
                  มหาวิทยาลัยทั้งหมด
                </Option>
                );
                {universities.map((university) => {
                  return (
                    <Option
                      key={university.university_id}
                      value={university.university_id}
                      label={university.university_name}
                    >
                      {university.university_name}
                    </Option>
                  );
                })}
              </Select>
            </label>
          </div>
        </div>
      ),
      onOk: () => {
        // console.log(_exportUniversity);
        exportPrograms(_exportUniversity);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  useEffect(() => {
    if (filterQuery.university) {
      fetchPrograms();
    }
  }, [filterQuery]);

  useEffect(() => {
    if (me) {
      const universityId = me.role === 'staff' ? me.university_id : '001';
      setFilterQuery({ ...filterQuery, university: universityId });
      setExportUniverysity(universityId);
      fetchUniversities(universityId);
      fetchCourses();
      fetchSubjects();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchPrograms(_page);
  };

  const currentCourses = get(courses, filterQuery.university || get(me, 'university_id'));

  const faculties = orderBy(uniqBy(currentCourses, 'faculty_id'), 'faculty_id');
  const uniqueCourses = uniqBy(currentCourses, 'program_id');

  const dispalyId = (id) => (id === '0' || !id ? '-' : id);
  const formatAgeDate = (date) => {
    return moment(date).add(543, 'years').format('DD/MM/YYYY');
  };

  const exportFile = (_programProjects) => {
    let headerRow = ['program_id', 'program_name_th', 'major_th', 'project_id', 'major_id', 'cal_type'];
    const headerScoreRow = subjects.map((subject) => subject.key);

    const programRows = _programProjects
      ? _programProjects.map((programProject) => {
          const _currentCourses = courses[university_id];
          const currentCourse = find(_currentCourses, {
            program_id: programProject.program_id,
          });

          const programRow = [
            programProject.program_id,
            currentCourse ? currentCourse.program_name_th : '',
            currentCourse ? currentCourse.majorNameTH : '',
            programProject.project_id,
            programProject.major_id,
            programProject.cal_type,
          ];
          const scoreRow = subjects.map((subject) => {
            if (programProject.cal_type === 'sum') {
              if (programProject.score[subject.key]) {
                return 1;
              }
            }

            if (programProject.score[subject.key]) {
              return programProject.score[subject.key];
            } else return '';
          });
          return programRow.concat(scoreRow);
        })
      : [
          ['10010121300001A', 'คณะวิศวกรรมศาสตร์ สาขาวิศวกรรมศาสตร์', '', 'P2104', 'R5'].concat(
            subjects.map((subject) => {
              if (subject.key === 'gat_sum') return 20;
              else if (subject.key === 'pat1') return 20;
              else if (subject.key === 'pat3') return 60;
              else return '';
            })
          ),
        ];

    const rows = [
      headerRow.concat(headerScoreRow),
      ...programRows,
      // programRow.concat(scoreRow),
    ];

    let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'tcas_condition_example2.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const executeAll = async () => {
    try {
      setLoading(true);
      const query = {};
      await callApi({
        url: `/programProjects/execute-all`,
        // query: { university: filterQuery.university },
        method: 'post',
      });
      await fetchPrograms(currentPage);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const subjectsObject = subjects.reduce((result, subject) => {
    result[subject.key] = subject;
    return result;
  }, {});

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1 className="">
            <span>ข้อมูลหลักสูตร</span>

            <div style={{ float: 'right' }}>
              {['senior', 'super'].indexOf(get(me, 'role')) > -1 && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ marginRight: 10 }}
                  onClick={() => onExportProgramButtonClick()}
                >
                  นำออกข้อมูลหลักสูตร
                </Button>
              )}
            </div>
          </h1>

          <div className="sec-box">
            <h2>ค้นหาข้อมูลหลักสูตร</h2>
            <div className="sec-form">
              <div className="col-20">
                <label>
                  มหาวิทยาลัย
                  <Select
                    showSearch
                    disabled={get(me, 'role') === 'staff'}
                    style={{ width: '100%' }}
                    value={get(filterQuery, 'university')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={async (value) => {
                      setLoading(true);
                      setFilterQuery({
                        ...filterQuery,
                        university: value,
                        program: 'all',
                        faculty: 'all',
                      });
                      setExportUniverysity(value);

                      await fetchCourses(value);
                      setLoading(false);
                    }}
                    // value={get(filterQuery, 'university')}
                    filterOption={filterLabelAndValue}
                  >
                    <Option key="all" value="all" label="มหาวิทยาลัยทั้งหมด">
                      มหาวิทยาลัยทั้งหมด
                    </Option>
                    {universities.map((university) => {
                      return (
                        <Option
                          key={university.university_id}
                          value={university.university_id}
                          label={university.university_name}
                        >
                          {university.university_id}: {university.university_name}
                        </Option>
                      );
                    })}
                  </Select>
                </label>
              </div>

              <div className="col-20">
                <label>
                  ชื่อคณะ
                  <div>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) =>
                        setFilterQuery({
                          ...filterQuery,
                          faculty: value,
                          program: 'all',
                        })
                      }
                      value={get(filterQuery, 'faculty')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value="all" label="คณะทั้งหมด">
                        คณะทั้งหมด
                      </Option>
                      {(faculties || []).map((faculty, i) => {
                        return (
                          <Option key={i} value={faculty.faculty_id} label={faculty.faculty_name_th}>
                            {faculty.faculty_id}: {faculty.faculty_name_th}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>
              <div className="col-20">
                <label>
                  ชื่อหลักสูตร
                  <div>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => setFilterQuery({ ...filterQuery, program: value })}
                      value={get(filterQuery, 'program')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value="all" label="หลักสูตรทั้งหมด">
                        หลักสูตรทั้งหมด
                      </Option>
                      {(uniqueCourses || []).map((course, i) => {
                        if (i > 100) {
                          return null;
                        }

                        return (
                          <Option key={i} value={course.program_id} label={course.program_name_th}>
                            {course.program_id}: {course.program_name_th}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>

              <div className="col-20">
                <label>
                  รอบ
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => setFilterQuery({ ...filterQuery, type: value })}
                      value={get(filterQuery, 'type')}
                    >
                      <Option key={'all'} value="all" label="รอบทั้งหมด">
                        รอบทั้งหมด
                      </Option>
                      {(ROUNDS || []).map((round, i) => {
                        return (
                          <Option key={i} value={round.value} label={round.label}>
                            {round.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* <input type="text" /> */}
                </label>
              </div>

              <div className="col-20 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchPrograms(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {programProjects.length === 0 && (
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลเงื่อนไขการรับสมัครในระบบ</th>
                </tr>
              </thead>
            </table>
          )}
          {programProjects.length > 0 && (
            <>
              <div style={{ textAlign: 'right', marginBottom: 15 }}>
                {get(me, 'role') === 'admin' && (
                  <button type="primary" icon={<PlusOutlined />} style={{}} onClick={() => executeAll()}>
                    ทำ Ranking ทั้งหมด
                  </button>
                )}
              </div>
              <table className="t-table -programs">
                <thead>
                  <tr>
                    <th>รอบ</th>
                    <th>รหัสหลักสูตร</th>
                    <th>ชื่อหลักสูตร</th>
                    <th>เงื่อนไขการรับ</th>
                    <th>การคำนวณคะแนน</th>
                  </tr>
                </thead>
                <tbody>
                  {programProjects.map((programProject, i) => {
                    const _currentCourses = courses[university_id];
                    const currentCourse = find(_currentCourses, {
                      program_id: programProject.program_id,
                      major_id: programProject.major_id,
                    });

                    return (
                      <tr key={i}>
                        <td>
                          <div>รอบ</div>
                          <b>{programProject.type.split('_')[0]}</b>
                          <div>รับ</div>
                          <b>{programProject.receive_student_number}</b>
                        </td>
                        <td>
                          <div>
                            <b>PRG</b> {programProject.program_id}
                          </div>
                          <div>
                            <b>MJR</b> {dispalyId(programProject.major_id)}
                          </div>
                          <div>
                            <b>PRJ</b> {dispalyId(programProject.project_id)}
                          </div>
                          <div>
                            <b>JID</b> {dispalyId(programProject.join_id)}
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link to={`/programs/${programProject._id}`}>
                              {currentCourse && currentCourse.program_name_th}
                            </Link>
                          </div>
                          <div>{currentCourse && dispalyId(currentCourse.major_name_th)}</div>
                          <div>{dispalyId(programProject.project_name_th)}</div>
                          {filterQuery.university === '031' && (
                            <div>{currentCourse && currentCourse.faculty_name_th}</div>
                          )}
                        </td>
                        <td>
                          <ul>
                            {programProject.only_formal === 1 ? (
                              <li>
                                <i className="i-yes"></i> แกนกลาง
                              </li>
                            ) : (
                              ''
                            )}
                            {programProject.only_international === 1 ? (
                              <li>
                                <i className="i-yes"></i> นานาชาติ
                              </li>
                            ) : (
                              ''
                            )}
                            {programProject.only_vocational === 1 ? (
                              <li>
                                <i className="i-yes"></i> อาชีวะ
                              </li>
                            ) : (
                              ''
                            )}
                            {programProject.only_non_formal === 1 ? (
                              <li>
                                <i className="i-yes"></i> กศน.
                              </li>
                            ) : (
                              ''
                            )}
                            {programProject.only_ged === 1 ? (
                              <div>
                                <i className="i-yes"></i> GED
                              </div>
                            ) : (
                              ''
                            )}
                          </ul>

                          <ReceiveCondition programProject={programProject} subjects={subjects} />
                        </td>

                        <td>
                          {programProject.scores && <ScoreCalculationBar scores={programProject.scores} />}

                          <table>
                            <tbody>
                              {programProject.scores &&
                                map(programProject.scores, (value, key) => {
                                  if (key === 'cal_score_sum' || key === 'cal_subject_name' || key === 'cal_type') {
                                    return <span />;
                                  }
                                  return (
                                    <tr key={key}>
                                      <td>{get(subjectsObject, `${key}.short_label`)}</td>
                                      <td>{value}%</td>
                                    </tr>
                                  );
                                })}
                              {programProject.scores && (
                                <ComplexSubjectInfo subjectDictionary={subjectsObject} scores={programProject.scores} />
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={40}
                total={programCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>

        {importModalVisible && (
          <ImportModal
            setIsVisible={setImportModalVisible}
            isVisible={importModalVisible}
            onClose={(result) => {
              fetchPrograms(currentPage);
              Modal.info({
                title: 'รายละเอียดการนำเข้า',
                content: (
                  <div>
                    <p>เพิ่ม {get(result, 'newAddedCount')} รายการ</p>
                    <p>แก้ไข {get(result, 'updateCount')} รายการ</p>
                    <p>ไม่สำเร็จ {get(result, 'notFoundRound.length')} รายการ</p>
                  </div>
                ),
                onOk() {},
              });
            }}
            programProjects={importPrograms}
            width={1000}
          ></ImportModal>
        )}
      </Spin>
    </div>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
