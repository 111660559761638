import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getTheme } from 'helpers/helpers';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import callApi from 'helpers/callapi';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

// Components
import { Horizontal, Vertical } from 'components/Centered';
import { Checkbox, message, Spin, Modal, Button } from 'antd';
import { get } from 'lodash';
import VideoModal from 'components/VideoModal';

// import { UserOutlined, LockOutlined } from '@ant-design/icons';

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loggingIn, setLoggingIn] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [isResetPasswordState, setIsResetPasswordState] = useState(false);

  const [isCheckedConsent, setIsCheckedConsent] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  useEffect(() => {
    const verify = async () => {
      try {
        const me = await callApi({ url: '/users/me' });
        if (me.role === 'center') {
          window.location.href = 'https://center.t66internal.com/credential';
          return;
        }
        dispatch({ type: 'UPDATE_USER', data: me });

        history.replace('/dashboard');
      } catch (error) {
        console.log(error);
        Cookies.remove('accessToken');
        dispatch({ type: 'UPDATE_USER', data: null });

        // history.replace('/login');
      }
    };

    verify();
  }, []);

  const onLogin = async () => {
    if (!isCheckedConsent) return;
    if (loggingIn) return;
    const _emailError = !email && 'กรุณากรอกข้อมูลชื่อบัญชีผู้ใช้';
    const _passwordError = !password && 'กรุณากรอกข้อมูลรหัสผ่าน';

    setEmailError(_emailError);
    setPasswordError(_passwordError);

    if (![_emailError, _passwordError].some((_error) => _error)) {
      setLoggingIn(true);
      try {
        const { accessToken } = await callApi({
          method: 'POST',
          url: '/users/login',
          body: { email, password },
        });

        Cookies.set('accessToken', accessToken, { expires: 1 / (24 * 6) });
        // history.replace('/students');
        setTimeout(() => {
          // setLoggingIn(false);
          window.location.href = '/dashboard';
        }, 1000);
      } catch (error) {
        console.error('Error while trying to login.', error);
        setLoggingIn(false);
        message.error(get(error, 'reason.message') || 'Unable to login, please try again later.');
      }
    }
  };

  const onResetPassword = async () => {
    const _emailError = !email && 'กรุณากรอกข้อมูลชื่อบัญชีผู้ใช้';
    setEmailError(_emailError);

    if (![_emailError].some((_error) => _error)) {
      try {
        await callApi({
          method: 'POST',
          url: '/users/forgot-password',
          body: { email },
        });
        Modal.success({
          content: 'ระบบได้ส่งวิธีการสำหรับการรีเซ็ตรหัสผ่านไปทางอีเมลเรียบร้อยแล้ว',
        });
      } catch (error) {
        setLoggingIn(false);

        message.error(get(error, 'reason.message') || 'Unable to reset password, please try again later.');
      }
    }
  };

  const _onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (isResetPasswordState) {
        onResetPassword();
      } else {
        onLogin();
      }
    }
  };

  const onShowModal = (e) => {
    e.preventDefault();

    Modal.info({
      title: '',
      icon: <span></span>,
      width: 800,
      content: (
        <div className="login-consent">
          <div>ข้อกำหนดและเงื่อนไขการใช้งานระบบ Curriculum , Requirements & Scoring สำหรับสถาบันร่วมในระบบ TCAS</div>
          <ol>
            <li>
              สถาบันต้องตรวจสอบข้อมูลหลักสูตร เงื่อนไขการรับ องค์ประกอบ สัดส่วน และ การคำนวณคะแนนของแต่ละหลักสูตร
              แต่ละเงื่อนไขการรับด้วยความรอบคอบและระมัดระวังไม่ให้เกิดข้อผิดพลาดใดๆ{' '}
            </li>
            <li>สถาบันต้องรับผิดชอบต่อความผิดพลาดที่เกิดขึ้นจากนำข้อมูลเข้าของสถาบันเอง</li>
            <li>
              ระบบจะแสดงข้อมูลที่สถาบันนำเข้าทั้งข้อมูลหลักสูตร เงื่อนไขการรับ และการคำนวณคะแนนในหน้าเวปไซต์ myTCAS.com{' '}
            </li>
            <li>การนำข้อมูลเข้าต้องเรียงลำดับการนำเข้าไฟล์หลักสูตร-เงื่อนไขการรับ-การคำนวณคะแนน </li>
            <li>ถ้าสถาบันมีการแก้ไขไฟล์หลักสูตร ท่านต้องนำไฟล์เงื่อนไขการรับ และ ไฟล์การคำนวณคะแนนเข้าด้วย</li>
            <li>ถ้าสถาบันมีต้องการแก้ไขไฟล์ใดไฟล์หนึ่ง สามารถนำไฟล์ใหม่เข้าระบบ โดยระบบจะใช้หลักการ replacement</li>
            <li>
              ไฟล์เงื่อนไขการรับ และ การคำนวณคะแนนของรอบที่ 3 ระบบจะนำไปกรองและคำนวณให้อัตโนมัติ ส่วนรอบอื่น
              จะแสดงให้ผู้สมัครเห็นเป็น information ในหน้าเวปไซต์เท่านั้น
            </li>
          </ol>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <div>
      <Spin spinning={loggingIn}>
        <div className="t-body">
          <div className="t-login">
            <div className="t-form">
              <div className="text-center">
                <a href="/">
                  <img src="/img/tcas-logo.png" alt="Logo" width="194" height="45" />
                </a>
                <h1>{!isResetPasswordState ? 'เข้าสู่ระบบ' : 'ตั้งค่ารหัสผ่านใหม่'}</h1>
              </div>
              <div className="_space">
                <label>
                  Email
                  <input
                    onKeyPress={_onKeyPress}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    type="text"
                  />
                  {emailError && <ErrorText>{emailError}</ErrorText>}
                </label>
              </div>
              {!isResetPasswordState && (
                <>
                  <div>
                    <label>
                      Password
                      <input
                        onKeyPress={_onKeyPress}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        placeholder="Password"
                      />
                      {passwordError && <ErrorText>{passwordError}</ErrorText>}
                    </label>
                  </div>
                  <div>
                    <Checkbox checked={isCheckedConsent} onChange={() => setIsCheckedConsent(!isCheckedConsent)}>
                      ข้าพเจ้ายอมรับ
                      <a onClick={(e) => onShowModal(e)}>ข้อกำหนดและเงื่อนไขในการใช้บริการ</a>
                    </Checkbox>
                  </div>
                </>
              )}
              <div>
                <button
                  onClick={() => (isResetPasswordState ? onResetPassword() : onLogin())}
                  className={cn({
                    'btn-alt': !isCheckedConsent && !isResetPasswordState,
                  })}
                >
                  {' '}
                  {!isResetPasswordState ? 'เข้าสู่ระบบ' : 'ลืมรหัสผ่าน'}
                </button>
              </div>
            </div>
          </div>
        </div>

        <VideoModal isVisible={isVideoModalVisible} vimeoId={'642471244'} setIsVisible={setIsVideoModalVisible} />
      </Spin>
    </div>
  );
}

const ErrorText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${getTheme`colors.red`};
`;
