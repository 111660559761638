const menus = [
  {
    key: 'dashboard',
    to: '/dashboard',
    title: 'ภาพรวม',
    roles: ['super', 'junior', 'senior'],
  },
  {
    key: 'university',
    to: '/dashboard',
    title: 'ภาพรวม',
    roles: ['staff'],
  },

  {
    key: 'enrollment',
    title: 'การรับสมัคร',
    to: '/enrollments/import',
    roles: ['staff', 'super'],
    submenus: [
      {
        key: 'import-enrollment',
        to: '/enrollments/import',
        title: 'นำเข้าการรับสมัคร',
        roles: ['staff'],
      },
      {
        key: 'disqualify-list',
        to: '/enrollments/disqualify',

        title: 'ตัดสิทธิ์',
        roles: ['staff', 'senior', 'super'],
      },
      {
        key: 'enrollment-list',
        to: '/enrollments',

        title: 'รายชื่อผู้สมัคร',
        roles: ['staff', 'super'],
      },
      {
        key: 'enrollment-download',
        to: '/enrollments/download',

        title: 'ดาวน์โหลดข้อมูลผู้สมัคร',
        roles: ['staff'],
      },
    ],
  },

  {
    key: 'programs',
    title: 'หลักสูตร',
    to: '/programs',
    submenus: [
      // {
      //   key: 'import-programs',
      //   to: '/programs/import',
      //   title: 'นำเข้าหลักสูตร',
      //   roles: ['staff'],
      //   configVisibleField: '!is_closed_for_import',
      // },
      {
        key: 'update-programs',
        to: '/programs/update',
        title: 'ปรับปรุงข้อมูลหลักสูตร',
        roles: ['staff'],
        configVisibleField: 'program_project_allow_update_rounds',
      },

      {
        key: 'conditions',
        to: '/programs',

        title: 'ข้อมูลหลักสูตร',
      },

      {
        key: 'subjects',
        to: '/subjects',

        title: 'ข้อมูลรายวิชาสอบ',
      },
    ],
  },

  {
    key: 'tickets',
    to: '/tickets',

    title: 'คำร้อง',
    submenus: [
      {
        key: 'ticket-list',
        to: '/tickets',
        title: 'คำร้องขอแก้ข้อมูล',
      },
      // {
      //   key: 'scores',
      //   to: '/scores',

      //   title: 'นำเข้าคะแนนที่จัดสอบเอง',
      // },
    ],
    roles: ['super', 'junior', 'senior'],
  },
  {
    key: 'exams',
    to: '/exams/enquiries',

    title: 'การสอบ',
    roles: ['super', 'junior', 'senior'],
    submenus: [
      {
        key: 'exam-enquiry-list',
        to: '/exams/enquiries',
        title: 'คำร้องเรียนการสอบ',
        roles: ['super', 'junior', 'senior'],
      },
      {
        key: 'exam-enquiry-report',
        to: '/exams/report',
        title: 'รายงานการสอบ',
        roles: ['super', 'junior', 'senior'],
      },
      {
        key: 'exam-enquiry-all',
        to: '/exams/all',
        title: 'ข้อมูลการสอบทั้งหมด',
        roles: ['super', 'junior', 'senior'],
      },
    ],
  },
  {
    key: 'orders',
    to: '/orders',

    title: 'การจ่ายเงิน',
    roles: ['super', 'junior', 'senior'],
  },
  {
    key: 'covid',
    to: '/covid',

    title: 'โควิด',
    roles: ['super', 'junior', 'senior'],
  },
  {
    key: 'students',
    to: '/students',

    title: 'นักเรียน',
    roles: ['super', 'junior', 'senior'],
    submenus: [
      {
        key: 'student-list',
        to: '/students',
        title: 'ข้อมูลนักเรียน',
      },
      {
        key: 'obec64-list',
        to: '/obec64',
        title: 'ฐาน OBEC64',
      },
      {
        key: 'obec-past',
        to: '/obec-past',
        title: 'ฐาน OBEC ปีก่อนหน้า',
      },
      {
        key: 'tcas-past',
        to: '/tcas-past',
        title: 'ฐาน TCAS ปีก่อนหน้า',
      },
      {
        key: 'school-list',
        to: '/schools',
        title: 'ข้อมูลโรงเรียน',
      },
      {
        key: 'school-list',
        to: '/school-enrollments',
        title: 'สถิติแยกโรงเรียน',
        roles: ['super', 'senior'],
      },
      // {
      //   key: 'scores',
      //   to: '/scores',

      //   title: 'นำเข้าคะแนนที่จัดสอบเอง',
      // },
    ],
  },
  {
    key: 'check-status',
    to: '/check-status',
    title: 'ตรวจสอบสถานะผู้สมัคร',
  },

  {
    key: 'user',
    to: '/users',

    title: 'ผู้ดูแลระบบ',
    roles: ['super', 'senior'],
  },

  {
    key: 'edit-user',
    to: '/users/edit',

    title: 'แก้ไขข้อมูลผู้ใช้',
  },

  {
    key: 'scores',
    to: '/scores',

    title: 'นำเข้าคะแนนที่จัดสอบเอง',
    roles: ['staff', 'super'],
  },

  {
    key: 'log',
    to: '/logs',

    title: 'ข้อมูลระบบ',
    submenus: [
      {
        key: 'log2',
        to: '/logs',

        title: 'ประวัติการใช้งาน',
      },
      {
        key: 'config',
        to: '/config',
        title: 'ตั้งค่า',
        roles: ['super'],
      },
    ],
  },
  {
    key: 'contact',
    to: '/contact',
    title: 'ติดต่อผู้ดูแลระบบ',
  },

  // {
  //   key: 'logs',
  //   to: '/logs',

  //   title: 'Logs',
  //   permission: 'log',
  // },
];
module.exports = menus;
