import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, orderBy, chunk, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

// Components
import CONSTANTS from 'constants/constants';

import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  Checkbox,
  message,
  Input,
  Tooltip,
  Radio,
} from 'antd';

const Option = Select.Option;

moment.locale('th');

export default ({ programs, setIsVisible, isVisible, onClose }) => {
  const [loading, setLoading] = useState(false);

  const me = useSelector((state) => state.user);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const subjects = useSelector((state) => state.subjects || []);

  const exportFile = () => {
    let headerRow = ['citizen_id'];
    const headerScoreRow = selectedSubjects;

    const programRows = [
      ['1922598022793'].concat(
        subjects.reduce((results, subject) => {
          if (selectedSubjects.indexOf(subject.key) > -1) {
            results.push(subject.max_value * 0.9);
          }
          return results;
        }, [])
      ),
      ['2142228691203'].concat(
        subjects.reduce((results, subject) => {
          if (selectedSubjects.indexOf(subject.key) > -1) {
            results.push(subject.max_value * 0.85);
          }
          return results;
        }, [])
      ),
    ];

    const rows = [
      headerRow.concat(headerScoreRow),
      ...programRows,
      // programRow.concat(scoreRow),
    ];

    let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'tcas_scores_example.csv');
    document.body.appendChild(link); // Required for FF

    link.click();

    setIsVisible(false);
  };

  const standardSubjects = subjects.reduce((results, subject) => {
    if (subject.subject_type === 'university_score') {
      results.push({
        ...subject,
        value: subject.key,
      });
    }
    return results;
  }, []);

  const onChange = (checkedValues) => {
    setSelectedSubjects(sortBy(checkedValues));
    console.log('checked = ', checkedValues);
  };

  return (
    <Modal
      title={`เลือกวิชาที่ต้องการนำเข้า`}
      visible={isVisible}
      width={1000}
      // okButtonProps={{ loading: loading }}

      onCancel={() => setIsVisible(false)}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => exportFile()}>
          ดาวน์โหลดตัวอย่าง
        </Button>,
      ]}
    >
      <div>
        <Checkbox.Group defaultValue={[]} onChange={onChange}>
          {standardSubjects.map((subject) => {
            return (
              <div>
                <Checkbox value={subject.value} style={{ fontSize: 16, marginBottom: 10 }}>
                  {subject.key}: {subject.label}
                </Checkbox>
              </div>
            );
          })}
        </Checkbox.Group>
      </div>
    </Modal>
  );
};
