import React from 'react';
import CheckList from './CheckList';

const RoundStatus = ({ round, university }) => {
  const filePathName = `file_path_${
    round.index === 5 ? 'handicap' : round.index
  }`;
  const addedAgenda = Boolean(
    _.get(university, `round_${round.index}.register_start_date`)
  );

  return (
    <div key={round.index} className={`u-${round.index}`}>
      <h2>
        <b>
          {round.index === 5 ? <i className="i-disabled"></i> : round.index}
        </b>
        {round.name}
      </h2>
      <ul>
        <CheckList
          label={'เอกสารเผยแพร่'}
          isChecked={university[filePathName]}
        />

        {round.index !== 5 && (
          <CheckList label={'กำหนดการ'} isChecked={addedAgenda}></CheckList>
        )}

        {round.index === 3 && (
          <div>
            <CheckList
              label={'เนื้อหาหลักสูตร'}
              isChecked={_.get(university, 'is_uploaded_program')}
              useNo={true}
            ></CheckList>
            <CheckList
              label={'เงื่อนไขการรับ'}
              isChecked={_.get(university, 'is_uploaded_program_condition')}
              useNo={true}
            ></CheckList>
            <CheckList
              label={'การคำนวณคะแนน'}
              isChecked={_.get(university, 'is_uploaded_program_scoring')}
              useNo={true}
            ></CheckList>
          </div>
        )}
      </ul>
    </div>
  );
};

export default RoundStatus;
